import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
// import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { makeStyles } from '@material-ui/core/styles';
import { logOut } from '../applications/reducers/AuthSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ModalComponent from '../components/modalComponent/index.jsx';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import io_client from "socket.io-client";
import LayoutsService from "./service.js";
import moment from "moment";

const socket = io_client(process.env.REACT_APP_BASE_URL || "https://sirh.livenexx.net/");

const styles = {
  actionBarContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  appBarContent: {
    flexDirection: 'row !important',
    height: '60px',
    paddingLeft: '20px',
  },
  notifView: {
    opacity: 0.5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '& span' : {
      fontSize: '12px'
    },
    '& p' : {
      margin: '10px 0px 10px',
      maxWidth: '300px',
      whiteSpace: 'break-spaces',
    }
  },
  notifNotView: {
    opacity: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '& span' : {
      fontSize: '12px'
    },
    '& p' : {
      margin: '10px 0px 10px',
      maxWidth: '300px',
      whiteSpace: 'break-spaces',
    }
  },
  notificationLength: {
      background: '#EA4740',
      position: 'absolute',
      top: '12px',
      fontWeight: 'bold',
      fontSize: '10px',
      color: '#ffff',
      borderRadius: '50%',
      padding: '2px 7px 2px 5px',
      left: '20px',
  },
  notifContent: {
    padding: '20px',
    maxHeight: '500px',
    overflowY: 'scroll',
    marginTop: '0px',
    '&::-webkit-scrollbar': {
      width: '1px',
      height: '5px'
    },
    '&::-webkit-scrollbar-track': {
        background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#888', 
        borderRadius: '10px',
    }
  }
}
const useStyles = makeStyles(styles);

export default function Header(props) {
  const { drawerWidth, handleDrawerOpen, open } = props
  const classes = useStyles();
  const [openProfile, setOpenProfile] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notif, setNotif] = useState([]);
  const [notifLength, setNotifLength] = useState(0);

  const getNotificationList = async () => {
    const response = await LayoutsService.getListNotification();
      if(response?.status?.code === 200) {
        setNotif(response.data);
        const dataNotLu = response.data.filter((e) => e.status === 0);
        setNotifLength(dataNotLu.length);
      }
  }

  useEffect(() => {
    getNotificationList();
  }, [])

  
  useEffect(() => {
    socket.on("data_notif", async (data) => {
      setNotif([...notif, data]);
    });
    // eslint-disable-next-line
  }, [])

  const handleClickProfile = (event) => {
    setOpenConfirm(true);
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const disconnect = () => {
    dispatch(logOut());
    sessionStorage.removeItem('token');
    navigate('/login');
    setOpenConfirm(false)
  }

  const goNotif = async (data) => {
    if(data.status === 0) {
      await LayoutsService.updateNotification(data).then(async(res) => {
        if (res?.status?.code === 200) {
          setNotif(
            notif.map((row) => {
              const updateStatus = {
                ...row,
                status: res.data.status,
              }
              return row.id === res.data.id ? updateStatus : row;
            })
          );
        }
      });
    }
  }

  useEffect(() => {
    const dataNotLu = notif.filter((e) => e.status === 0);
    setNotifLength(dataNotLu.length);
  }, [notif]);
  
  return (
      <>
          {/* <CssBaseline /> */}
          <AppBar sx={{...(open ? { justifyContent: 'flex-end' } : { justifyContent: 'space-between'} )}} className={classes.appBarContent} position="fixed" open={open}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{...(open && { display: 'none' })}}
              >
                <MenuIcon />
              </IconButton>
              <div className={classes.actionBarContent}>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <IconButton variant="contained" {...bindTrigger(popupState)}>
                        <NotificationsIcon sx={{color: "#FFFF"}}/>
                        {notifLength > 0 && <span className={classes.notificationLength}>{notifLength}</span>}
                      </IconButton>
                      <Menu sx={{top : '-10px' }} {...bindMenu(popupState)}>
                        <div className={classes.notifContent}>
                          {notif.sort((a, b) => b.dateNotif.localeCompare(a.dateNotif)).map((el) => {
                            return (
                                <MenuItem component="div" key={el.id}>
                                  <div
                                    onClick={() => goNotif(el)}
                                    className={el.status === 0 ? classes.notifNotView : classes.notifView}
                                  >
                                    <p>{el.description}</p>
                                      <span className={classes.date}>
                                        {moment(el.dateNotif).format('DD MMMM YYYY HH:mm')}
                                      </span>
                                  </div>
                                  <Divider light />
                                </MenuItem>
                            )
                          })}
                        </div>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <IconButton variant="contained" {...bindTrigger(popupState)}>
                        <PersonIcon sx={{color: "#FFFF"}} />
                      </IconButton>
                      <Menu sx={{top : '-10px' }} {...bindMenu(popupState)}>
                        <MenuItem onClick={handleClickProfile}>Déconnecter</MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
                {/* <Menu
                  id="fade-menu"
                  MenuListProps={{
                    'aria-labelledby': 'fade-button',
                  }}
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={handleClickProfile}>Profile</MenuItem>
                </Menu> */}
              </div>
          </AppBar>
          <ModalComponent
            openModal={openConfirm}
            close={() => setOpenConfirm(false)}
            title={'Déconnexion'}
            submit={() => disconnect()}
            actionTitle={'Confirmer'}
            modalContentBody={'Êtes-vous sûr de vouloir vous déconnecter?'}
          />
      </>
  )
}
