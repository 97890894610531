import { makeStyles } from '@material-ui/core/styles';

const styles = {
  headContent: {
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h4': {
      marginLeft: '20px',
      margin: '0px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '1.25rem',
      lineHeight: '1.6',
      letterSpacing: '0.0075em',
    }
  },
  iconTitle: {
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
    borderRadius: "5px",
    padding: "20px",
  },
  progress: {
    width: '16px !important',
    height: '16px !important',
    position: 'absolute',
    left: '-5px'
  },
  actionSelect: {
    position: 'relative',
    width: '50%',
    display: 'inline-flex',
    padding: 0,
    flexDirection: 'column',
    verticalAlign: 'top',
    height: '32px',
    '& .MuiFormControl-root': {
      width: '100%',
      height: '100%',
      position: 'absolute',
    },
    '& .MuiInputBase-formControl': {
      background: '#FFFF',
      fontSize: '14px !important',
      height: '32px',
    },
    '& .MuiInputLabel-shrink': {
      top: '4px !important',
      left: '0px !important',
    },
    '& .MuiFormLabel-root': {
      fontSize: '10px',
      top: '-6px',
      left: '-8px',
    },
    '& .MuiSelect-select': {
      padding: '0 8px !important'
    }
  }
}

const useStyles = makeStyles(styles);

export default useStyles;