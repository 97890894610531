import React from 'react'
import { DialogTitle, DialogContent, Typography, Drawer as MuiDrawer } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';

const useStyles = makeStyles((theme) => ({
    drawerWrapper: {
        marginTop: 165
    },
    dialogTitle: {
        paddingRight: '0px',
        backgroundColor: '#1976d2'
    }
}))

export default function DrawerCompenent(props) {
    const { title, children, openDrawer, toggleDrawer } = props
    const classes = useStyles()

    return (
        <MuiDrawer
            sx={{
                '& .MuiDrawer-paper': { width: '81.8%', marginRight: '0px' }
            }}
            anchor="right"
            open={openDrawer}
            onClose={() => toggleDrawer()}
        >
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" color="white" component="div" style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                        <StorageOutlinedIcon sx={{ marginRight: "20px"}} /> {title}
                    </Typography>
                    <CloseIcon onClick={() => toggleDrawer()} style={{ color: 'white' }} />
                </div>
            </DialogTitle>
            <DialogContent dividers>{children}</DialogContent>
        </MuiDrawer>
    )
}
