import React, { useEffect, useContext } from 'react';
import { Grid } from '@mui/material';
import Controls from '../../../components/Entry/Controls.jsx';
import { AddEmployeeContext } from './index.jsx';
import { Button } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import avatar from '../../../assets/img/ava3.png';
import { MdAttachFile } from "react-icons/md";

const styles = {
    photoContent: {
        background: '#1976d2',
        width: '150px',
        height: '150px',
        borderRadius: '50%',
        position: 'relative',
        // right: 0,
        '& label': {
            transition: 'all 0.2s ease-in-out',
            opacity: '0',
            position: 'absolute',
            fontWeight: 'bold',
            fontSize: '16px',
            color: '#FFFF',
            textTransform: 'capitalize',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '200px'
        },
        '&:hover': {
            '& label': {
                backgroundColor: 'transparent',
                cursor: 'pointer',
                opacity: '1',
            }
        },
        '& img': {
            width: '150px',
            height: '150px',
            objectFit: 'cover',
            borderRadius: '50%',
        }
    },
    pj: {
        position: 'absolute !important',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        fontSize: '25px !important  ',
        minWidth: '0 !important',
        padding: '0 !important',
        '&:hover': {
            opacity: 0.5,
        }
    },
    fileName: {
        position: 'absolute',
        top: '-20px',
        right: 0,
        overflow: 'hidden',
        width: '140px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textAlign: 'end'
    }
}

const useStyles = makeStyles(styles);

const sexeItems = [
    { id: 'homme', title: 'Homme' },
    { id: 'femme', title: 'Femme' }
]

const civilityItems = [
    { id: 'mr', title: 'M.' },
    { id: 'mme', title: 'Mme' },
    { id: 'mlle', title: 'Mlle' }
]

const nationalityItems = [
    { id: 'MG', title: 'Malagasy' },
    { id: 'OTHER', title: 'Autre' }
]

const maritalStatusItems = [
    { id: 'celibataire', title: 'Célibataire' },
    { id: 'marie', title: 'Marié(e)' },
    { id: 'divorce', title: 'Divorcé(e)' },
    { id: 'veuf', title: 'veuf(ve)' }
]

export default function Employee(props) {
    const { errors, setErrors, valuesProfil, setValuesProfil, setFile, setFileCin, fileCin, setFilePass, filePass, previewPhoto, setPreviewPhoto, idEmployee } = useContext(AddEmployeeContext)
    const classes = useStyles();

    const validate = (fieldValues = valuesProfil) => {
        let temp = { ...errors }
        if ('civility' in fieldValues) temp.civility = fieldValues.civility ? '' : 'Champ obligatoire.'
        // if ('matricule' in fieldValues) temp.matricule = fieldValues.matricule ? '' : 'Champ obligatoire.'
        if ('firstName' in fieldValues) temp.firstName = fieldValues.firstName ? '' : 'Champ obligatoire.'
        if ('lastName' in fieldValues) temp.lastName = fieldValues.lastName ? '' : 'Champ obligatoire.'
        if ('firstName' in fieldValues) temp.firstName = fieldValues.firstName ? '' : 'Champ obligatoire.'
        if ('fatherName' in fieldValues) temp.fatherName = fieldValues.fatherName ? '' : 'Champ obligatoire.'
        if ('motherName' in fieldValues) temp.motherName = fieldValues.motherName ? '' : 'Champ obligatoire.'
        if ('dateOfBirth' in fieldValues) temp.dateOfBirth = fieldValues.dateOfBirth ? '' : 'Champ obligatoire.'
        if ('placeOfBirth' in fieldValues) temp.placeOfBirth = fieldValues.placeOfBirth ? '' : 'Champ obligatoire.'
        if ('phoneNumber' in fieldValues) temp.phoneNumber = fieldValues.phoneNumber ? '' : 'Champ obligatoire.'
        if ('phoneNumber' in fieldValues && fieldValues.phoneNumber) temp.phoneNumber = /^(\+261|032|033|034|038)/.test(fieldValues.phoneNumber) ? '' : '+261/032/033/034/038'
        if ('phoneNumber' in fieldValues && /^(\+261)/.test(fieldValues.phoneNumber)) temp.phoneNumber = fieldValues.phoneNumber.length !== 13 ? 'Numéro incorrect' : ''
        if ('phoneNumber' in fieldValues && /^(032|033|034|038)/.test(fieldValues.phoneNumber)) temp.phoneNumber = fieldValues.phoneNumber.length !== 10 ? 'Numéro incorrect' : ''
        if ('sexe' in fieldValues) temp.sexe = fieldValues.sexe ? '' : 'Champ obligatoire.'
        if ('nationality' in fieldValues) temp.nationality = fieldValues.nationality ? '' : 'Champ obligatoire.'
        if (fieldValues.nationality === 'MG') {
            if ('cin' in fieldValues) temp.cin = fieldValues.cin ? '' : 'Champ obligatoire.'
            if ('cin' in fieldValues && fieldValues.cin) temp.cin = fieldValues.cin.length === 12 ? '' : 'Le CIN doit avoir 12 caractères'
            if ('cinDate' in fieldValues) temp.cinDate = fieldValues.cinDate ? '' : 'Champ obligatoire.'
            if ('cinPlace' in fieldValues) temp.cinPlace = fieldValues.cinPlace ? '' : 'Champ obligatoire.'
            delete temp.passeport
            delete temp.passeportDate
        }
        if (fieldValues.nationality === 'OTHER') {
            if ('passeport' in fieldValues) temp.passeport = fieldValues.passeport ? '' : 'Champ obligatoire.'
            if ('passeport' in fieldValues && fieldValues.passeport) temp.passeport = /^\d{2}[a-zA-Z]{2}\d{5}$/.test(fieldValues.passeport) ? '' : '2 chiffres, 2 lettres, 5 chiffres.'
            if ('passeportDate' in fieldValues)
                temp.passeportDate = fieldValues.passeportDate ? '' : 'Champ obligatoire.'
            delete temp.cin
            delete temp.cinDate
            delete temp.cinPlace
        }
        if (fieldValues.nationality === '') {
            delete temp.passeport
            delete temp.passeportDate
            delete temp.cin
            delete temp.cinDate
            delete temp.cinPlace
        }
        if ('maritalStatus' in fieldValues) temp.maritalStatus = fieldValues.maritalStatus ? '' : 'Champ obligatoire.'
        if (fieldValues.maritalStatus === 'marie') {
            if ('conjointName' in fieldValues) temp.conjointName = fieldValues.conjointName ? '' : 'Champ obligatoire.'
            if ('conjointDob' in fieldValues) temp.conjointDob = fieldValues.conjointDob ? '' : 'Champ obligatoire.'
        } else {
            delete temp.conjointName
            delete temp.conjointDob
        }
        if (fieldValues.urgencyContact === true) {
            if ('urgencePhoneNumber' in fieldValues)
                temp.urgencePhoneNumber = fieldValues.urgencePhoneNumber ? '' : 'Champ obligatoire.'
            if ('urgencePhoneNumber' in fieldValues && fieldValues.urgencePhoneNumber)
                temp.urgencePhoneNumber = /^(\+261|032|033|034|038)/.test(fieldValues.urgencePhoneNumber) ? '' : '+261/032/033/034/038'
            if ('urgencePhoneNumber' in fieldValues && /^(\+261)/.test(fieldValues.urgencePhoneNumber))
                temp.urgencePhoneNumber = fieldValues.urgencePhoneNumber.length !== 13 ? 'Numéro incorrect' : ''
            if ('urgencePhoneNumber' in fieldValues && /^(032|033|034|038)/.test(fieldValues.urgencePhoneNumber))
                temp.urgencePhoneNumber = fieldValues.urgencePhoneNumber.length !== 10 ? 'Numéro incorrect' : ''
            if ('urgencePersonName' in fieldValues)
                temp.urgencePersonName = fieldValues.urgencePersonName ? '' : 'Champ obligatoire.'
            if ('relation' in fieldValues)
                temp.relation = fieldValues.relation ? '' : 'Champ obligatoire.'
        } else {
            delete temp.urgencePhoneNumber
            delete temp.urgencePersonName
            delete temp.relation
        }

        for (const key in temp) {
            if (temp[key] === '') {
                delete temp[key]
            }
        }

        setErrors({
            ...temp
        })

        return Object.keys(temp).length === 0
    }

    const onChangeFile = async (e) => {
        const files = e.target.files[0];
        setFile(files);
        setPreviewPhoto(URL.createObjectURL(files));
    };

    const onChangeFileCin = async (e) => {
        const files = e.target.files[0];
        setFileCin(files);
    };

    const onChangeFilePass = async (e) => {
        const files = e.target.files[0];
        setFilePass(files);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target
        if (
            name === 'lastName' ||
            name ===  'firstName' ||
            name === 'conjointName' ||
            name === 'motherName' ||
            name === 'fatherName' ||
            name ===  'urgencePersonName'
        ) {
            const newValue = value.replace(/[^a-zA-Zàáâäèéêëìíîïòóôöùúûüç\s]/g, '');
            setValuesProfil({ ...valuesProfil, [name]: newValue })
        } else if(name === 'passeport') {
            const newValue = value.replace(/[^a-zA-Z0-9]/g, '');
            setValuesProfil({ ...valuesProfil, [name]: newValue })
        } else if(name === 'phoneNumber' || name === 'urgencePhoneNumber') {
            let newValue = value.replace(/[^\d+]/g, '');
            const limitedValue = newValue.slice(0, 13);
            if (limitedValue.length === 10 || limitedValue.length === 13) {
            setValuesProfil({ ...valuesProfil, [name]: limitedValue })
            }
            setValuesProfil({ ...valuesProfil, [name]: limitedValue })
        } else if(name === 'civility') {
            if(value === 'mr') {
                setValuesProfil({...valuesProfil, civility: value, sexe : 'homme'})
            } else {
                setValuesProfil({...valuesProfil, civility: value, sexe : 'femme'})
            }
        } else if(name === 'sexe') {
            if(value === 'homme') {
                setValuesProfil({...valuesProfil, civility: 'mr', sexe : value})
            } else {
                setValuesProfil({...valuesProfil, civility: 'mme', sexe : value})
            }
        } else {
            setValuesProfil({
                ...valuesProfil,
                [name]: value
            })
        }
    }

    //handle load
    // useEffect(() => {
    //     !addEmployeeData.profil
    //         ? setAddEmployeeData({
    //                 ...addEmployeeData,
    //                 profil: valuesProfil
    //             })
    //         : setValuesProfil(addEmployeeData.profil)
    // }, [])

    useEffect(() => {
        validate()
    })

    //handle click to next
    // useEffect(() => {
    //     setAddEmployeeData({
    //         ...addEmployeeData,
    //         profil: values
    //     })
    // }, [clickBack, clickNext, values])

    return (
        <form>
            <Grid container sx={{ position: 'relative'}}>
                <Grid container>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <Grid className={classes.photoContent}>
                        <img src={previewPhoto ? previewPhoto : avatar} alt="user" />
                        <Button component="label">
                            Photo
                            <input hidden type="file" accept=".jpg,.png" onChange={onChangeFile} />
                        </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '30px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            { idEmployee && (
                                <>
                                    <h4 style={{ fontWeight: 'bold', fontSize: '16px', color: 'black'}}>Matricule:</h4>
                                    <h4>&nbsp;{valuesProfil.matricule}</h4>
                                </>
                            )
                            }
                            {/* <Controls.Input
                                name="matricule"
                                label="Matricule"
                                value={valuesProfil.matricule}
                                onChange={handleInputChange}
                                error={errors.matricule}
                            /> */}
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.RadioGroup
                                name="civility"
                                label="Civilité"
                                value={valuesProfil.civility}
                                onChange={handleInputChange}
                                items={civilityItems}
                                error={errors.civility}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.RadioGroup
                                name="sexe"
                                label="Genre"
                                value={valuesProfil.sexe}
                                onChange={handleInputChange}
                                items={sexeItems}
                                error={errors.sexe}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.Input
                                label="Nom"
                                name="lastName"
                                value={valuesProfil.lastName}
                                onChange={handleInputChange}
                                error={errors.lastName}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.Input
                                label="Prénom"
                                name="firstName"
                                value={valuesProfil.firstName}
                                onChange={handleInputChange}
                                error={errors.firstName}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px', marginTop: '-8px' }}>
                            <Controls.DatePicker
                                name="dateOfBirth"
                                label="Date de naissance"
                                value={valuesProfil.dateOfBirth}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.Input
                                label="Lieu de naissance"
                                name="placeOfBirth"
                                value={valuesProfil.placeOfBirth}
                                onChange={handleInputChange}
                                error={errors.placeOfBirth}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.Select
                                name="nationality"
                                label="Nationalité"
                                value={valuesProfil.nationality}
                                onChange={(e) => {
                                    handleInputChange(e)
                                }}
                                options={nationalityItems}
                                error={errors.nationality}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        {valuesProfil.nationality === 'MG' && ( 
                            <FormControl style={{ width: '50%', marginBottom: '40px', position: 'relative' }}>
                                <Controls.Input
                                    label="Numéro CIN"
                                    name="cin"
                                    value={valuesProfil.cin}
                                    onChange={handleInputChange}
                                    error={errors.cin}
                                />
                                <Button component="label" className={classes.pj}>
                                    <MdAttachFile />
                                    <input hidden type="file" accept=".pdf,.jpg,.png" onChange={onChangeFileCin}/>
                                </Button>
                                <span className={classes.fileName}>{fileCin?.name}</span>
                            </FormControl>
                        )}
                    </Grid>
                </Grid>
                {valuesProfil.nationality === 'MG' && (
                    <Grid container>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                            <FormControl style={{ width: '50%', marginBottom: '40px', marginTop: '-8px' }}>
                                <Controls.DatePicker
                                    name="cinDate"
                                    label="Date de délivrance"
                                    value={valuesProfil.cinDate}
                                    onChange={handleInputChange}
                                />  
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                            <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                                <Controls.Input
                                    label="Lieu de délivrance"
                                    name="cinPlace"
                                    value={valuesProfil.cinPlace}
                                    onChange={handleInputChange}
                                    error={errors.cinPlace}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                )}
                {valuesProfil.nationality === 'OTHER' && (
                    <Grid container>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                            <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                                <Controls.Input
                                    label="Numéro du Passeport"
                                    name="passeport"
                                    value={valuesProfil.passeport}
                                    onChange={handleInputChange}
                                    error={errors.passeport}
                                />
                                <Button component="label" className={classes.pj}>
                                    <MdAttachFile />
                                    <input hidden type="file" accept=".pdf,.jpg,.png" onChange={onChangeFilePass}/>
                                </Button>
                                <span className={classes.fileName}>{filePass?.name}</span>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                            <FormControl style={{ width: '50%', marginBottom: '40px', marginTop: '-8px' }}>
                                <Controls.DatePicker
                                    name="passeportDate"
                                    label="Date du Passeport"
                                    value={valuesProfil.passeportDate}
                                    onChange={handleInputChange}
                                    error={errors.passeportDate}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                )}
                <Grid container>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.Input
                                label="Téléphone"
                                name="phoneNumber"
                                value={valuesProfil.phoneNumber}
                                onChange={handleInputChange}
                                error={errors.phoneNumber}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}></Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.Input
                                label="Nom du père"
                                name="fatherName"
                                value={valuesProfil.fatherName}
                                onChange={handleInputChange}
                                error={errors.fatherName}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.Input
                                label="Nom de la mère"
                                name="motherName"
                                value={valuesProfil.motherName}
                                onChange={handleInputChange}
                                error={errors.motherName}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.Select
                                name="maritalStatus"
                                label="Situation matrimonale"
                                value={valuesProfil.maritalStatus}
                                onChange={(e) => {
                                    handleInputChange(e)
                                }}
                                options={maritalStatusItems}
                                error={errors.maritalStatus}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}></Grid>
                </Grid>
                {valuesProfil.maritalStatus === 'marie' && (
                    <Grid container>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                            <FormControl style={{ width: '50%', marginBottom: '40px' }}>                                
                                <Controls.Input
                                    label="Nom/Prénon du conjoint(e)"
                                    name="conjointName"
                                    value={valuesProfil.conjointName}
                                    onChange={handleInputChange}
                                    error={errors.conjointName}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                            <FormControl style={{ width: '50%', marginBottom: '40px', marginTop: '-8px' }}>
                                <Controls.DatePicker
                                    name="conjointDob"
                                    label="Date de naissance du conjoint(e)"
                                    value={valuesProfil.conjointDob}
                                    onChange={handleInputChange}
                                    error={errors.conjointDob}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                )}
                <Grid container>
                    <Grid container>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                            <FormControl style={{ width: '50%', marginBottom: '40px' }}>    
                                <Controls.Checkbox
                                    name="urgencyContact"
                                    label="Avoir un contact d'urgence"
                                    value={valuesProfil.urgencyContact}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                            {valuesProfil.urgencyContact && (
                                <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                                    <Controls.Input
                                        label="Relation"
                                        name="relation"
                                        value={valuesProfil.relation}
                                        onChange={handleInputChange}
                                        error={errors.relation}
                                    />
                                </FormControl>
                            )}
                        </Grid>
                    </Grid>
                    {valuesProfil.urgencyContact && (
                        <Grid container>
                            <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                                <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                                    <Controls.Input
                                        label="Nom à contacter"
                                        name="urgencePersonName"
                                        value={valuesProfil.urgencePersonName}
                                        onChange={handleInputChange}
                                        error={errors.urgencePersonName}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                                <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                                    <Controls.Input
                                        label="Numéro à contacter"
                                        name="urgencePhoneNumber"
                                        value={valuesProfil.urgencePhoneNumber}
                                        onChange={handleInputChange}
                                        error={errors.urgencePhoneNumber}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </form>
    )
}
