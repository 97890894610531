import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import DashboardService from './service/DashboardService.jsx';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  // padding: theme.spacing(1),
  position: 'relative',
  margin: '20px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function countOccurrences(array) {
  return array.reduce((acc, value) => {
    acc[value] = (acc[value] || 0) + 1;
    return acc;
  }, {});
}

function convertToArray(occurrences) {
  return Object.entries(occurrences);
}

function convertToString(occurrences) {
  const entries = convertToArray(occurrences);
  return entries.map(([key, value]) => `${key}: ${value}`).join(', ');
}

function calculateAgeListChild (birthDate) {
  if (!birthDate) return;
  const currentDate = new Date();
  const diffTime = currentDate - new Date(birthDate);
  const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  if (Math.floor(totalDays / 365.25) > 0) {
    return Math.floor(totalDays / 365.25);
  }
};

export default function Dashboard() {
  const [ageAllEmploye, setAgeAllEmploye] = useState([]);
  const [ageAllChildNotCharge, setAgeAllChildNotCharge] = useState([]);
  const [ageAllChildCharge, setAgeAllChildCharge] = useState([]);
  const [age, setAge] = useState([]);
  const [ageNumber, setAgeNumber] = useState([]);
  const [celibat, setCelibat] = useState([]);
  const [maried, setMaried] = useState([]);
  const [veuf, setVeuf] = useState([]);
  const [divorce, setDivorce] = useState([]);
  const [homme, setHomme] = useState([]);
  const [femme, setFemme] = useState([]);

  useEffect(() => {
    async function getCalculAgeAllEmploye() {
      let ageEmployeArray = [];
      const response = await DashboardService.getAllEmployee();
      if(response?.status?.code === 200) {
        const data = response?.data
        let c = data.filter(m => m.maritalStatus === "celibataire");
        let m = data.filter(m => m.maritalStatus === "marie");
        let v = data.filter(m => m.maritalStatus === "veuf");
        let d = data.filter(m => m.maritalStatus === "divorce");
        let h = data.filter(m => m.sexe === "homme");
        let f = data.filter(m => m.sexe === "femme");
        setCelibat(c);
        setMaried(m);
        setVeuf(v);
        setDivorce(d);
        setHomme(h);
        setFemme(f);
        response?.data?.forEach((el) => {
          ageEmployeArray.push(calculateAgeListChild(el.dateOfBirth))
        });
        setAgeAllEmploye(ageEmployeArray);
      }
    }

    async function getCalculAgeAllChild() {
      let ageChildArrayCharge = [];
      let ageChildArrayNotCharge = [];
      const response = await DashboardService.getAllChild();
      if(response?.status?.code === 200) {
        response?.data?.forEach((el) => {
          if (calculateAgeListChild(el.childrenDob) < 18) {
            ageChildArrayCharge.push(calculateAgeListChild(el.childrenDob))
          } else {
            ageChildArrayNotCharge.push(calculateAgeListChild(el.childrenDob))
          }
        });
        setAgeAllChildCharge(ageChildArrayCharge);
        setAgeAllChildNotCharge(ageChildArrayNotCharge);
      }
    }
    getCalculAgeAllEmploye();
    getCalculAgeAllChild();
  }, [])

  useEffect(() => {
    const occurrences = countOccurrences(ageAllEmploye);
    const occurrencesString = convertToString(occurrences);
    const splitComma = occurrencesString.split(',');
    let ageArray = [];
    let ageNumberArray = [];
    splitComma.forEach((el) => {
      ageArray.push(el.split(':')[0]);
      ageNumberArray.push(el.split(':')[1]);
    })
    setAge(ageArray);
    setAgeNumber(ageNumberArray);
  }, [ageAllEmploye]);

	let optionGenre = {
    color: ['#cccc', 'rgb(25, 118, 210)'],
		title: {
			text: 'Effectifs par genre',
			left: 'center',
      top: 15,
      textStyle: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
        lineHeight: 1.6,
        letterSpacing: '0.0075em',
        fontSize: 14,
      }
		},
    tooltip: {
      trigger: 'item',
			formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
		legend: {
      orient: 'vertical',
      right: 'right',
      bottom: '10px',
      textStyle: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 8,
      },
    },
    series: [
      {
        name: 'Gerne',
        type: 'pie',
        radius: '50%',
				label: {
          show: false,
          position: 'center'
        },
        data: [
          { value: homme.length, name: 'Homme' },
          { value: femme.length, name: 'Femme' },
        ],
        emphasis: {
          label: {
            show: true,
            fontSize: 14,
            fontWeight: 'bold'
          }
        },
      },
    ],
  };

  let optionMatrimonial = {
    color: ['#cccc', 'rgb(25, 118, 210)', 'red', 'black'],
		title: {
			text: 'Effectifs par situation matrimoniale',
			left: 'center',
      top: 15,
      textStyle: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
        lineHeight: 1.6,
        letterSpacing: '0.0075em',
        fontSize: 14,
      }
		},
    tooltip: {
      trigger: 'item',
			formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
		legend: {
      orient: 'vertical',
      right: 'right',
      bottom: '10px',
      textStyle: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 8,
      },
    },
    series: [
      {
        name: 'Situation matrimoniale',
        type: 'pie',
        radius: '50%',
				label: {
          show: false,
          position: 'center'
        },
        data: [
          { value: celibat.length, name: 'Célibataire' },
          { value: maried.length, name: 'Marié(e)' },
          { value: veuf.length, name: 'Veuf(ve)' },
          { value: divorce.length, name: 'Divorcé(ve)' },
        ],
        emphasis: {
          label: {
            show: true,
            fontSize: 14,
            fontWeight: 'bold'
          }
        },
      },
    ],
  };

  let optionChild = {
    color: ['#cccc', 'rgb(25, 118, 210)'],
		title: {
			text: "Effectifs par nombre d'enfant",
			left: 'center',
      top: 15,
      textStyle: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
        lineHeight: 1.6,
        letterSpacing: '0.0075em',
        fontSize: 14,
      }
		},
    tooltip: {
      trigger: 'item',
			formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
		legend: {
      orient: 'vertical',
      right: 'right',
      bottom: '10px',
      textStyle: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 8,
      },
    },
    series: [
      {
        name: 'Nombre d\'enfant',
        type: 'pie',
        radius: '50%',
				label: {
          show: false,
          position: 'center'
        },
        data: [
          { value: ageAllChildCharge.length, name: 'En charge' },
          { value: ageAllChildNotCharge.length, name: 'Dépendant' },
        ],
        emphasis: {
          label: {
            show: true,
            fontSize: 14,
            fontWeight: 'bold'
          }
        },
      },
    ],
  };

  let optionAge = {
    title: {
      text: 'Effectif des Employés par Âge',
      left: 'center',
      top: 15,
      textStyle: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
        lineHeight: 1.6,
        letterSpacing: '0.0075em',
        fontSize: 14,
      },
    },
    tooltip: {
      trigger: 'axis',
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { title: "Enregistrer l'image", show: true }
      }
    },
    xAxis: {
      type: 'category',
      data: age,
      name: 'Âge',
    },
    yAxis: {
      type: 'value',
      name: 'Nombre d\'Employés',
    },
    series: [
      {
        name: 'Nombre d\'employés: ',
        data: ageNumber,
        type: 'bar',
        itemStyle: {
          color: 'rgb(25, 118, 210)',
        },
        barWidth: '5%',
      },
    ],
  };

	return (
		<>
      <Grid container>
        <Grid style={{ position: 'relative' }} item xs={4}>
          <Item>
            <div style={{ background: '#cccc', height: '1px', position: 'absolute', top: '40px', left: '0px', width: '100%' }}></div>
            <ReactEcharts
              style={{height: 'calc(50vh - 100px)'}}
              className='echart-content'
              option={optionGenre}
            />
          </Item>
        </Grid>
        <Grid style={{ position: 'relative' }} item xs={4}>
          <Item>
            <div style={{ background: '#cccc', height: '1px', position: 'absolute', top: '40px', left: '0px', width: '100%' }}></div>
            <ReactEcharts
              style={{height: 'calc(50vh - 100px)'}}
              className='echart-content'
              option={optionMatrimonial}
            />
          </Item>
        </Grid>
        <Grid style={{ position: 'relative' }} item xs={4}>
          <Item>
            <div style={{ background: '#cccc', height: '1px', position: 'absolute', top: '40px', left: '0px', width: '100%' }}></div>
            <ReactEcharts
              style={{height: 'calc(50vh - 100px)'}}
              className='echart-content'
              option={optionChild}
            />
          </Item>
        </Grid>
      </Grid>
      <Grid container>
        <Grid style={{ position: 'relative' }} item xs={12}>
          <Item>
            <ReactEcharts
              style={{height: 'calc(50vh - 40px)'}}
              className='echart-content'
              option={optionAge}
            />
          </Item>
        </Grid>
      </Grid>
		</>
	)
}
