import { makeStyles } from '@material-ui/core/styles';
import fond from '../../assets/img/fond.jpg';

const styles = {
  login: {
    backgroundImage: `url(${fond})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  login_content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#FFFF',
    borderWidth: '4px',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    border: '4px solid rgba(0,94,216,255)',
    borderRadius: '.75rem',
    padding: '40px',
    '& h4': {
      textAlign: 'center',
      margin: '10px 0 40px',
    },
    '& form': {
      width: '250px',
      height: '160px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }
  },
  headContent: {
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h4': {
      marginLeft: '20px',
      margin: '0px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '1.25rem',
      lineHeight: '1.6',
      letterSpacing: '0.0075em',
    }
  },
  iconTitle: {
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
    borderRadius: "5px",
    padding: "20px",
  },
  progress: {
    width: '16px !important',
    height: '16px !important',
    position: 'absolute',
    left: '-5px'
  },
}

const useStyles = makeStyles(styles);

export default useStyles;