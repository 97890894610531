import React, { useEffect, useState } from 'react';
import { useAuth } from '../../applications/hooks/UseAuth';
import EmployeeService from './services/Employee.jsx';
import Table from '../../components/table/index.jsx';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { Chip } from '@mui/material';
import Tooltips from '../../components/tooltips/index.jsx';
import Button from '@mui/material/Button';
import { LiaEyeSolid } from 'react-icons/lia';
import { MdDeleteOutline } from 'react-icons/md';
import { FaRegEdit } from 'react-icons/fa';
// import { FaFileInvoiceDollar } from 'react-icons/fa';
import Zoom from '@material-ui/core/Zoom';
import ModalComponent from '../../components/modalComponent/index.jsx';
import { Toast } from "../../components/notificationComponent/index.jsx";
import DrawerCompenent from '../../components/drawer/index.jsx';
import AddEditEmployee from '../employee/addEdit/index.jsx';
import SeeMore from './seeMore/index.jsx';
import FormControl from '@material-ui/core/FormControl';
import { CircularProgress } from '@material-ui/core';
import Controls from '../../components/Entry/Controls.jsx';
import useStyles from './style.jsx';
import ButtonComponent from '../../components/Entry/Button.jsx';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import SvgExcel from '../../components/Entry/svgExcel.jsx';

const CURRENT_PAGE = 'employee';

function ModalContentBody(props) {
    // const classes = useStyles();
    const { valuesStatus, setValuesStatus, valuesMotifDepart, setValuesMotifDepart, isSubmitted } = props;
    const checkMotifDepartEmpty = () => {
        if (isSubmitted && !valuesMotifDepart) {
            return 'Ce champ est requis.'
        }
    }
    return (
        <form>
            <FormControl style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '30px'}} >
                <label style={{ fontWeight: 'bold', fontSize: '14px', color: 'black' }}>Statut</label>
                <select
                    style={{
                        width: '100%',
                        padding: '8px',
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderRight: 'none',
                        borderBottom: '1px solid #ccc'
                    }}
                    value={valuesStatus}
                    onChange={(e) => {
                        setValuesStatus(e.target.value)
                    }}
                >
                    <option value='1'>En Poste</option>
                    <option value="2">Démissionnaire</option>
                    <option value="3">Ex-employé</option>
                    <option value="4">Renvoyer</option>
                </select>
            </FormControl>
            <FormControl style={{ width: '100%', marginTop: '20px' }}>
                <Controls.Textarea
                    sx={{ width: '100%', marginBottom: '10px' }}
                    name="motifDepart"
                    label="Saisissez le motif"
                    value={valuesMotifDepart}
                    onChange={(e) => {
                        setValuesMotifDepart(e.target.value)
                    }}
                    error={checkMotifDepartEmpty()}
                />
            </FormControl>
        </form>
    )
}

function Employees() {
    const { haveAccessTo } = useAuth();
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [deletedId, setDeletedId] = useState('');
    const [openAddEdit, setOpenAddEdit] = useState(false);
    const [idEmployee, setIdEmployee] = useState(null);
    const [openDrawerSeeMore, setOpenDrawerSeeMore] = useState(false);
    const [employeeName, setEmployeeName] = useState(null);
    const [openChangeStatus, setOpenChangeStatus] = useState(false);
    const [valuesStatus, setValuesStatus] = useState(1);
    const [valuesMotifDepart, setValuesMotifDepart] = useState("");
    const [isClickExport, setIsClickExport] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [actions, setActions] = useState('');

    const actionItems = [
        {id : 1, value: 'status', title: 'Status'},
        {id : 2, value: 'advance', title: 'Avance'}
    ]

    const openEditDrawer = (id) => () => {
        if(haveAccessTo(CURRENT_PAGE, 'edit')) {
            setIdEmployee(id);
            setOpenAddEdit(true);
        }
    }

    useEffect(() => {
        switch (actions) {
            case 'status':
                setOpenChangeStatus(true);
                break;
            default:
                break;
        }
    }, [actions])

    const handleSeeMore = (params) => () => {
        if(haveAccessTo(CURRENT_PAGE, 'see')) {
            setIdEmployee(params.id);
            setEmployeeName(`${params.name}`);
            setOpenDrawerSeeMore(true);
        }
    }

    const handleChangeStatus = (params) => () => {
        setIdEmployee(params.id);
        setOpenChangeStatus(true);
        setValuesStatus(params.row.status);
    }

    const columns = [
        {
            field: 'matricule',
            headerName: 'Matricule',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'name',
            headerName: 'Nom et Prénoms',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'contractType',
            headerName: 'Type de contrat',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'jobTitle',
            headerName: 'Fonction',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'status',
            type: 'actions',
            headerName: 'Status',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            getActions: (params) => {
                const status = {
                    1: { title: 'En Poste', color: 'success', icon: <CheckCircleOutlineOutlinedIcon /> },
                    2: { title: 'Démissionnaire', color: 'warning', icon: <ErrorOutlineOutlinedIcon /> },
                    3: { title: "Ex-employé", color: 'info', icon: <AccessTimeOutlinedIcon /> },
                    4: { title: 'Renvoyer', color: 'error', icon: <DoNotDisturbAltOutlinedIcon /> }
                }

                return [
                    <Chip
                        onClick={handleChangeStatus(params)}
                        label={status[params.row.status]?.title}
                        color={status[params.row.status]?.color}
                        icon={status[params.row.status]?.icon}
                        size="small"
                        variant="outlined"
                    />
                ]
            }
        },
        {
            field: 'action',
            type: 'actions',
            headerName: 'Action',
            align: 'center',
            headerAlign: 'center',
            width: 200,
            getActions: (params) => {
                const handleDelete = async (id) => {
                    if(haveAccessTo(CURRENT_PAGE, 'delete')) {
                        setOpenDeleteConfirm(true);
                        setDeletedId(id);
                    }
                }
                return [
                    <>
                        <Tooltips TransitionComponent={Zoom} title="Afficher plus">
                            <Button
                                disabled={!haveAccessTo(CURRENT_PAGE, 'see')}
                                onClick={handleSeeMore(params.row)}
                            >
                                <LiaEyeSolid
                                className='actionIconStyle'

                                />
                            </Button>
                        </Tooltips>
                        <Tooltips TransitionComponent={Zoom} title="Supprimer">
                            <Button
                                disabled={!haveAccessTo(CURRENT_PAGE, 'delete')}
                                onClick={() => {
                                    handleDelete(params.id);
                                }}
                            >
                                <MdDeleteOutline className='actionIconStyle' />
                            </Button>
                        </Tooltips>
                        <Tooltips TransitionComponent={Zoom} title="Modifier">
                            <Button
                                disabled={!haveAccessTo(CURRENT_PAGE, 'edit')}
                                onClick={openEditDrawer(params.id)}
                            >
                                <FaRegEdit className='actionIconStyle'/>
                            </Button>
                        </Tooltips>
                    </>
                ]
            }
        },
    ]

    const getListEmploye = async () => {
        const response = await EmployeeService.getAllEmployee()
        const newRows = []
        if (response?.status?.code === 200) {
            response?.data?.forEach(el => {
                newRows.push({
                  id: el.id,
                  matricule: el.matricule,
                  name: el.name,
                  contractType: el.contractType !== 'cdi' ? (el.contractType === 'cdd' ? 'CDD' : 'Essai') : 'CDI',
                  jobTitle: el.jobTitle,
                  status: el.status,
                });
            });
        }
        setRows(newRows);
    }

    useEffect(() => {
        getListEmploye();
    }, [])

    const confirmDelete = async () => {
        const response = await EmployeeService.deleteEmployee(deletedId);
        if(response?.status?.code === 200) {
            setDeletedId('');
            Toast.success("L'employé a été supprimé avec succès.");
            setRows(rows.filter((el) => el.id !== deletedId));
            setOpenDeleteConfirm(false);
        }
    }

    const changeStatus = async () => {
        setIsSubmitted(true);
        if (valuesStatus && valuesMotifDepart) {
            let payload = {
                status: valuesStatus,
                motifDepart: valuesMotifDepart
            }
            const response = await EmployeeService.updateStatus(selectedRows, idEmployee, payload);
            if(response?.status?.code === 200) {
                setIdEmployee(null);
                setValuesMotifDepart("");
                if(Array.isArray(response.data)) {
                    response.data.forEach(item => {
                        setRows(
                            rows.map((row) => {
                                return row.id === item.id ? {...row, status: item.status} : row;
                            })
                        );
                    });
                } else {
                    setRows(
                        rows.map((row) => {
                            return row.id === response.data.id ? {...row, status: response.data.status} : row;
                        })
                    );
                }
                setOpenChangeStatus(false);
                Toast.success("Statut modifier.");
                setIsSubmitted(false);
            }
        }
    }

    const onExportExcel = async () => {
        if(haveAccessTo(CURRENT_PAGE, 'export')) {
            setIsClickExport(true);
            EmployeeService.generateExcel({}).then((res) => {
                setIsClickExport(false);
                if (res?.status?.code === 200) {
                    let a = document.createElement('a');
                    a.href = 'data:application/octet-stream;base64,' + res.data.content;
                    a.download = `employes.xlsx`;
                    a.click();
                }
            });
        } else {
            Toast.error("L'exportation n'est pas autorisée.");
        }
      };

    return (
        <>
            <div style={{ padding: 20 }}>
                <div className={classes.headContent}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.iconTitle}>
                            <WorkOutlineOutlinedIcon style={{ fontSize: '1.1rem' }} />
                        </div>
                        <div>
                            <h4>Gestion des Employés</h4>
                            <div style={{marginLeft: '20px', display: 'flex', justifyContent: 'space-between' }}>
                                {haveAccessTo(CURRENT_PAGE, 'edit') &&
                                    <ButtonComponent style={{ textTransform: 'none'}} text="Nouveau" variant="contained" onClick={() => setOpenAddEdit(true)}/>
                                }
                                <div className={classes.actionSelect}>
                                    <FormControl>
                                        {selectedRows.length > 0 &&
                                            <Controls.Select
                                                name="choiceAction"
                                                label="Action"
                                                value={actions}
                                                onChange={(e) => {
                                                    setActions(e.target.value);
                                                }}
                                                options={actionItems}
                                            />
                                        }
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Button
                            disabled={isClickExport || !haveAccessTo(CURRENT_PAGE, 'export')}
                            style={{ textTransform: 'capitalize', opacity: isClickExport || !haveAccessTo(CURRENT_PAGE, 'export') ? '0.5' : '1' }}
                            variant="text"
                            size="small"
                            onClick={onExportExcel}
                        >
                            {
                                isClickExport &&
                                <CircularProgress className={classes.progress} color="inherit" />
                            }
                            <SvgExcel />
                        </Button>
                    </div>
                </div>
                <Table columns={columns} rows={rows} isSelection={true} setSelectedRows={setSelectedRows}/>
            </div>
            <ModalComponent
                openModal={openDeleteConfirm}
                close={() => setOpenDeleteConfirm(false)}
                title={'Suppression'}
                submit={() => confirmDelete()}
                actionTitle={'Confirmer'}
                modalContentBody={'Est-ce que vous voulez vraiment supprimer cet employé?'}
            />
            <ModalComponent
                openModal={openChangeStatus}
                close={() => {
                        setOpenChangeStatus(false);
                        setIdEmployee(null);
                        setValuesMotifDepart("");
                        setIsSubmitted(false);
                        setActions("");
                        setValuesStatus(1);
                    }}
                title={'Statut'}
                submit={() => changeStatus()}
                actionTitle={'Confirmer'}
                modalContentBody={<ModalContentBody valuesStatus={valuesStatus} setValuesStatus={setValuesStatus} valuesMotifDepart={valuesMotifDepart} setValuesMotifDepart={setValuesMotifDepart} isSubmitted={isSubmitted}/>}
            />
            <DrawerCompenent
                title={idEmployee === null ? 'Ajout' : 'Mofidication'}
                openDrawer={openAddEdit}
                toggleDrawer={()=> {
                    setOpenAddEdit(false);
                    setIdEmployee(null);
                }}
            >
                <AddEditEmployee
                    setIdEmployee={setIdEmployee}
                    idEmployee={idEmployee}
                    getListEmploye={getListEmploye}
                    toggleDrawer={()=> setOpenAddEdit(false)}
                />
            </DrawerCompenent>
            <DrawerCompenent
                title={employeeName}
                openDrawer={openDrawerSeeMore}
                toggleDrawer={()=> {
                    setOpenDrawerSeeMore(false);
                    setIdEmployee(null);
                }}
            >
                {/* <AddEditEmployee
                    setIdEmployee={setIdEmployee}
                    idEmployee={idEmployee}
                    getListEmploye={getListEmploye}
                    toggleDrawer={()=> setOpenAddEdit(false)}
                /> */}
                <SeeMore idEmployee={idEmployee} />
            </DrawerCompenent>
        </>
    )
}

export default Employees
