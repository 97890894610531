import React, { useContext } from 'react';
import { AddEmployeeContext } from './index.jsx';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import moment from 'moment';
import { BsPersonExclamation } from 'react-icons/bs';
import { AiOutlineFileExclamation } from 'react-icons/ai';
import { LuBaby } from 'react-icons/lu';
import avatar from '../../../assets/img/ava3.png';

const Recap = () => {
    const { addEmployeeData, previewPhoto } = useContext(AddEmployeeContext);

    const addSpacesPhoneNumber = (str) => {
        if (str.slice(0, 1) === '0') {
            return '+261 ' + str.slice(1, 3) + ' '.repeat(1) + str.slice(3, 5) + ' '.repeat(1) + str.slice(5, 8) + ' '.repeat(1) + str.slice(8);
        } else {
            return str.slice(0, 4) + ' '.repeat(1) + str.slice(4, 6) + ' '.repeat(1) + str.slice(6, 8) + ' '.repeat(1) + str.slice(8, 11) + ' '.repeat(1) + str.slice(11);
        }
    }

    const addSpacesCIN = (str) => {
        return str.slice(0, 3) + ' '.repeat(1) + str.slice(3, 6) + ' '.repeat(1) + str.slice(6, 9) + ' '.repeat(1) + str.slice(9, 12);
    }
 
    return (
        <div>
            <Card sx={{marginBottom: "20px"}} className='cardContentChild'>
                <CardHeader
                    sx={{ background: 'rgb(245 245 245)', marginBottom: '10px' }}
                    avatar={<BsPersonExclamation size={15} className='titleHeaderLogo' />}
                    title="Informations personnelles"
                />
                <CardContent style={{ paddingTop: '0' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%' }} className='boxContent'>
                        <Box sx={{ width: '50%' }} className='boxContentChild'>
                            <img style={{ width: '150px', height: '150px', margin: '10px 0' }} src={previewPhoto ? previewPhoto : avatar} alt="user" />
                            {addEmployeeData?.profil?.matricule && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>N° Matricule</u> : {addEmployeeData?.profil?.matricule || '-'}</Box>}
                            {addEmployeeData?.profil?.lastName && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Nom</u> : {addEmployeeData?.profil?.lastName || '-'}</Box>}
                            {addEmployeeData?.profil?.firstName && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Prénom</u> : {addEmployeeData?.profil?.firstName || '-'}</Box>}
                            {addEmployeeData?.profil?.civility && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Civilité</u> : {addEmployeeData?.profil?.civility !== 'mr' ? (addEmployeeData?.profil?.civility === 'mme' ? 'Madame' : 'Mademoiselle') : 'Monsieur' || '-'}</Box>}
                            {addEmployeeData?.profil?.sexe && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Genre</u> : {addEmployeeData?.profil?.sexe === 'homme' ? 'Homme' : 'Femme' || '-'}</Box>}
                            {(addEmployeeData?.profil?.dateOfBirth && addEmployeeData?.profil?.dateOfBirth !== "Invalid date") && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Date de naissance Employé(e)</u> : {moment(addEmployeeData?.profil?.dateOfBirth).format('DD MMMM YYYY') || '-'}</Box>}
                            {addEmployeeData?.profil?.placeOfBirth && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Lieu de naisssance</u> : {addEmployeeData?.profil?.placeOfBirth || '-'}</Box>}
                            {addEmployeeData?.profil?.phoneNumber && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Téléphone</u> : {addSpacesPhoneNumber(addEmployeeData?.profil?.phoneNumber) || '-'}</Box>}
                            {addEmployeeData?.address?.address && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Adresse</u> : {addEmployeeData?.address?.address || '-'}</Box>}
                            {addEmployeeData?.address?.city && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Ville</u> : {addEmployeeData?.address?.city || '-'}</Box>}
                            {addEmployeeData?.address?.country && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Région</u> : {addEmployeeData?.address?.country || '-'}</Box>}
                            {addEmployeeData?.address?.postalCode && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Code postale</u> : {addEmployeeData?.address?.postalCode || '-'}</Box>}
                        </Box>
                        <Box sx={{ width: '50%' }} className='boxContentChild'>
                            {addEmployeeData?.profil?.fatherName && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Nom du père</u> : {addEmployeeData?.profil?.fatherName || '-'}</Box>}
                            {addEmployeeData?.profil?.motherName && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Nom de la mère</u> : {addEmployeeData?.profil?.motherName || '-'}</Box>}
                            {addEmployeeData?.profil?.nationality && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Nationalité</u> : {addEmployeeData?.profil?.nationality === 'MG' ? 'Malagasy' : 'Autre' || '-'}</Box>}
                            {(addEmployeeData?.profil?.nationality === 'MG' && addEmployeeData?.profil?.cin) && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>N° CIN</u> : {addSpacesCIN(addEmployeeData?.profil?.cin) || '-'}</Box>}
                            {(addEmployeeData?.profil?.nationality === 'MG' && addEmployeeData?.profil?.cinDate && addEmployeeData?.profil?.cinDate !== "Invalid date") && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Date de délivrance</u> : {moment(addEmployeeData?.profil?.cinDate).format('DD MMMM YYYY') || '-'}</Box>}
                            {(addEmployeeData?.profil?.nationality === 'MG' && addEmployeeData?.profil?.cinPlace) && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Lieu de délivrance</u> : {addEmployeeData?.profil?.cinPlace || '-'}</Box>}
                            {(addEmployeeData?.profil?.nationality !== 'MG' && addEmployeeData?.profil?.passeport) && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>N° du passeport</u> : {addEmployeeData?.profil?.passeport || '-'}</Box>}
                            {(addEmployeeData?.profil?.nationality !== 'MG' && addEmployeeData?.profil?.passeportDate && addEmployeeData?.profil?.passeportDate !== "Invalid date") && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Date du passeport</u> : {moment(addEmployeeData?.profil?.passeportDate).format('DD MMMM YYYY') || '-'}</Box>}
                            {addEmployeeData?.profil?.maritalStatus && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Situation matrimonale</u> : {addEmployeeData?.profil?.maritalStatus !== "veuf" ? (addEmployeeData?.profil?.maritalStatus !== "divorce" ? (addEmployeeData?.profil?.maritalStatus === "celibataire" ? "Célibataire" : "Marié(e)") : "Divorcé(e)") : "veuf(ve)" || '-'}</Box>}
                            {addEmployeeData?.profil?.maritalStatus === 'marie' && addEmployeeData?.profil?.conjointName && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Nom du conjoint(e)</u> : {addEmployeeData?.profil?.conjointName || '-'}</Box>}
                            {(addEmployeeData?.profil?.maritalStatus === 'marie' && addEmployeeData?.profil?.conjointDob && addEmployeeData?.profil?.conjointDob !== "Invalid date") && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Date du passeport</u> : {moment(addEmployeeData?.profil?.conjointDob).format('DD MMMM YYYY') || '-'}</Box>}
                            {addEmployeeData?.profil?.urgencyContact &&
                                <>
                                    <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Nom d'urgence à contacter</u> : {addEmployeeData?.profil?.urgencePersonName || '-'}</Box>
                                    <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Relation</u> : {addEmployeeData?.profil?.relation || '-'}</Box>
                                    <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>N° d'urgence</u> : {addSpacesPhoneNumber(addEmployeeData?.profil?.urgencePhoneNumber) || '-'}</Box>
                                </>
                            }
                        </Box>
                    </Box>
                </CardContent>
            </Card>
            <Card sx={{marginBottom: "20px"}} className='cardContentChild'>
                <CardHeader
                    sx={{ background: 'rgb(245 245 245)', marginBottom: '10px' }}
                    avatar={<LuBaby size={15} className='titleHeaderLogo' />}
                    title={`Les enfants (${addEmployeeData?.children?.length})`}
                />
                <CardContent style={{ paddingTop: '0' }}>
                    <Box className='boxContent'>
                        <Box className='boxContentChild'>
                        {addEmployeeData?.children?.length > 0 && addEmployeeData?.children?.map((data, index) => (
                            <Box key={index}>
                                <u style={{ fontWeight: 'bold', textDecoration: 'none' }}>({index + 1})</u> {data?.childrenName}, né(e) le {moment(data?.childrenDob).format('DD MMMM YYYY')}
                            </Box>
                        ))
                        }
                        </Box>
                    </Box>
                </CardContent>
            </Card>
            <Card sx={{marginBottom: "20px"}} className='cardContentChild'>
                <CardHeader
                    sx={{ background: 'rgb(245 245 245)', marginBottom: '10px' }}
                    avatar={<AiOutlineFileExclamation size={15} className='titleHeaderLogo' />}
                    title="Contrat personnelle"
                />
                <CardContent style={{ paddingTop: '0' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%' }} className='boxContent'>
                        <Box sx={{ width: '50%' }} className='boxContentChild'>
                            {addEmployeeData?.contract?.jobTitle && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Fonction</u> : {addEmployeeData?.contract?.jobTitle || '-'}</Box>}
                            {addEmployeeData?.contract?.contractType && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Type de contrat</u> : {addEmployeeData?.contract?.contractType !== 'cdi' ? (addEmployeeData?.contract?.contractType === 'cdd' ? 'CDD' : "Essai") : 'CDI' || '-'}</Box>}
                            {(addEmployeeData?.contract?.contractStartDate && addEmployeeData?.contract?.contractStartDate !== "Invalid date") && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Date début du contrat</u> : {moment(addEmployeeData?.contract?.contractStartDate).format('DD MMMM YYYY') || '-'}</Box>}
                            {(addEmployeeData?.contract?.contractEndDate && addEmployeeData?.contract?.contractEndDate !== "Invalid date") && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Date fin du contrat</u> : {moment(addEmployeeData?.contract?.contractEndDate).format('DD MMMM YYYY') || '-'}</Box>}
                            {addEmployeeData?.contract?.department && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Département</u> : {addEmployeeData?.contract?.department || '-'}</Box>}
                            {addEmployeeData?.contract?.service && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Service</u> : {addEmployeeData?.contract?.service || '-'}</Box>}
                            {addEmployeeData?.contract?.category && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Catégorie</u> : {addEmployeeData?.contract?.category || '-'}</Box>}
                            {addEmployeeData?.contract?.group && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Groupe</u> : {addEmployeeData?.contract?.group || '-'}</Box>}
                            {addEmployeeData?.contract?.baseSalary && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Salaire de base (Ar)</u> : {(parseFloat(addEmployeeData?.contract?.baseSalary) || 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</Box>}
                            {addEmployeeData?.contract?.hoursWeek && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Heures par semaine (h)</u> : {addEmployeeData?.contract?.hoursWeek || '-'}</Box>}
                        </Box>
                        <Box sx={{ width: '50%' }} className='boxContentChild'>
                            {addEmployeeData?.other?.cnaps && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>N° CNaPS</u> : {addEmployeeData?.other?.cnaps || '-'}</Box>}
                            {addEmployeeData?.other?.paymentMethod && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Méthode de payement</u> : {addEmployeeData?.other?.paymentMethod !== 1 ? (addEmployeeData?.other?.paymentMethod !== 2 ? (addEmployeeData?.other?.paymentMethod === 3 ? 'Chèque' : 'Mobile money') : 'Virement bancaire') : 'Espèces' || '-'}</Box>}
                            {addEmployeeData?.other?.paymentMethod === 2 && addEmployeeData?.other?.domiciliation && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Domiciliation</u> : {addEmployeeData?.other?.domiciliation || '-'}</Box>}
                            {addEmployeeData?.other?.paymentMethod === 2 && addEmployeeData?.other?.bankCode && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Code banque</u> : {addEmployeeData?.other?.bankCode || '-'}</Box>}
                            {addEmployeeData?.other?.paymentMethod === 2 && addEmployeeData?.other?.agencyCode && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Code guichet</u> : {addEmployeeData?.other?.agencyCode || '-'}</Box>}
                            {addEmployeeData?.other?.paymentMethod === 2 && addEmployeeData?.other?.accountNumber && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>N° compte</u> : {addEmployeeData?.other?.accountNumber || '-'}</Box>}
                            {addEmployeeData?.other?.paymentMethod === 2 && addEmployeeData?.other?.key && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Clé</u> : {addEmployeeData?.other?.key || '-'}</Box>}
                            {addEmployeeData?.other?.paymentMethod === 4 && addEmployeeData?.other?.mobileMoney && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Mobile money</u> : {addEmployeeData?.other?.mobileMoney !== 'mvola' ? (addEmployeeData?.other?.mobileMoney === 'orangeMoney' ? 'Orange Money' : 'Airtel Money') : 'Mvola' || '-'}</Box>}
                            {addEmployeeData?.other?.paymentMethod === 4 && addEmployeeData?.other?.mobileMoneyName && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>Nom</u> : {addEmployeeData?.other?.mobileMoneyName || '-'}</Box>}
                            {addEmployeeData?.other?.paymentMethod === 4 && addEmployeeData?.other?.mobileMoneyNumber && <Box><u style={{ fontWeight: 'bold', textDecoration: 'none' }}>N° mobile money</u> : {addSpacesPhoneNumber(addEmployeeData?.other?.mobileMoneyNumber) || '-'}</Box>}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </div>
    )
}

export default Recap
