import React, {useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { DASHBOARD_SIDEBAR_LINKS } from '../routes/menu';
import { Link, useLocation } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { FaChevronDown } from "react-icons/fa6";
import { useAuth } from '../applications/hooks/UseAuth'
import logo from '../assets/img/logo.png';
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = '290px';

const styles = {
  rotate90: {
    transform: 'rotate(0deg)'
  },
  rotate180: {
    transform: 'rotate(180deg)',
    right: '10px !important'
  },
  show: {
    display: 'block'
  },
  hidden: {
    display: 'none'
  },
  menuList: {
    marginTop: '140px'
  }
}

const useStyles = makeStyles(styles);

const ParentList = styled('div')(() => ({
  '& svg': {
    padding: '0px 10px 0px 0px'
  },
}));

const ChildList = styled('div')(() => ({
  '& svg': {
    padding: '0px 10px 0px 0px'
  },
  margin: '0 10px'
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  position: 'fixed',
  height: '140px',
  width: `calc(${drawerWidth} - 1px)`,
  display: 'flex',
  alignItems: 'center',
  // padding: theme.spacing(0, 1),
  // ...theme.mixins.toolbar,
  justifyContent: 'center',
  background: '#FFFF',
  zIndex: 2,
}));

function SidebarSubmenu({ haveAccessTo, submenu, label, icon, parent, path }) {
  const { pathname } = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  const classes = useStyles();

  useEffect(() => {
      if (
          submenu.filter((m) => {
              return m.path === pathname
          })[0]
      )
      setIsExpanded(true)
      // eslint-disable-next-line
  }, [])

  return (
    <>
      {/** Route header */}
      {haveAccessTo(parent, 'show', true) && (
        <ParentList sx={{ margin: '0 10px' }}>
          <ListItemButton
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{ maxHeight: '50px' }}
            component={Link}
          >
            {icon}
            <ListItemText primary={label} />
            <FaChevronDown
              style={{position: 'absolute', right: 0}}
              className={isExpanded ? classes.rotate180 : classes.rotate90}
            />
          </ListItemButton>
        </ParentList>
      )}

      {/** Submenu list */}
      <div className={isExpanded ? classes.show : classes.hidden}>
          {submenu.map((m, k) => {
              return (
                <ChildList key={k}>
                  {haveAccessTo(`${parent}_${m.key}`, 'show') && (
                    <ListItemButton
                      style={{background: pathname === m.path && '#1976d2', color: pathname === m.path && '#FFFF'}}
                      sx={{maxHeight: '50px', padding: '5px 38px', borderRadius: '5px' }}
                      component={Link}
                      to={m.path}
                    >
                        {m.icon}
                      <ListItemText primary={m.label} />
                    </ListItemButton>
                  )}
                </ChildList>
              )
          })}
      </div>
    </>
  )
}

export default function PersistentDrawerLeft(props) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { handleDrawerClose, open } = props;
  const { haveAccessTo } = useAuth();
  const classes = useStyles();
  
  // const [expanded, setExpanded] = React.useState(false);

  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxShadow:'rgb(210, 210, 210) 0px 70px 5px 5px',
              boxSizing: 'border-box',
              '&::-webkit-scrollbar': {
                width: '1px',
                height: '5px'
              },
              '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1'
              },
              '&::-webkit-scrollbar-thumb': {
                  background: '#888', 
                  borderRadius: '10px',
              }
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <img style={{ height: '140px', width: '200px', objectFit: 'contain' }} src={logo} alt="user" />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <div className={classes.menuList}>
            {DASHBOARD_SIDEBAR_LINKS.map((item, index) => (
              item.submenu === undefined ?
                  (
                    <React.Fragment key={index}>
                      <ParentList sx={{ margin: '0 10px' }}>
                        <ListItemButton
                          style={{background: pathname === item.path && '#1976d2', color: pathname === item.path && '#FFFF'}}
                          sx={{ maxHeight: '50px', borderRadius: '5px' }}
                          component={Link}
                          to={item.path}
                        >
                          {item.icon}
                          <ListItemText primary={item.label} />
                        </ListItemButton>
                      </ParentList>
                    </React.Fragment>
                  )
                : (
                  <SidebarSubmenu haveAccessTo={haveAccessTo} parent={item.key} {...item} />
                  /* <React.Fragment key={index}>
                  <Accordion expanded={expanded === item.key} onChange={handleChange(item.key)} sx={{ position: 'relative' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id={item.key}
                    >
                      <ListItemButton
                        sx={{ maxHeight: '50px', padding: 0 }}
                        component="a"
                      >
                          {item.icon}
                        <ListItemText primary={item.label} />
                      </ListItemButton>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingBottom: 0, paddingTop: 0 }}>
                    {item.submenu.map((sub, i) => (
                      <React.Fragment key={i}>
                        <ListItemButton
                          style={{background: pathname === sub.path && '#1976d2', color: pathname === sub.path && '#FFFF'}}
                          sx={{maxHeight: '50px', paddingBottom: '5px', paddingTop: '5px' }}
                          component={Link}
                          to={sub.path}
                        >
                            {sub.icon}
                          <ListItemText primary={sub.label} />
                        </ListItemButton>
                      </React.Fragment>
                    ))}
                    </AccordionDetails>
                  </Accordion>
                  </React.Fragment> */
                )
              ))}
          </div>
        </Drawer>
      </Box>
    </>
  );
}