import React from 'react';
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiFormLabel-root": {
            fontWeight: 'bold',
            fontSize: '16px',
            color: 'black'
        },
        "& .MuiFormHelperText-root": {
            position: 'absolute',
            fontSize: '0.75rem',
            bottom: '-20px',
            marginLeft: '0'
        }
    }
  }));

export default function Select(props) {
    const { name, label, value, error = null, onChange, options, ...other } = props
    const classes = useStyles();
    return (
        <FormControl className={classes.root} sx={{ width: '100%' }} variant="outlined" {...(error && { error: true })} {...other}>
            <InputLabel>{label}</InputLabel>
            <MuiSelect label={label} name={name} value={value} onChange={onChange}>
                {/* <MenuItem value="">Aucun</MenuItem> */}
                {options.map((item) => (
                    <MenuItem key={item.id} value={item.value || item.id}>
                        {item.title}
                    </MenuItem>
                ))}
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}
