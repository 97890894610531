import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import { BsPersonExclamation } from 'react-icons/bs';
// import { LuMapPin } from 'react-icons/lu';
import { LuBaby } from 'react-icons/lu';
import { LuPhone } from 'react-icons/lu';
import { IoCarSportOutline } from 'react-icons/io5';
import { CiImageOn } from 'react-icons/ci';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardBody from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import { Button } from '@mui/material';
import { CircularProgress } from '@material-ui/core';
import { Alert } from '@mui/material';
import avatar from '../../../assets/img/ava3.png';
import EmployeeService from '../services/Employee.jsx';
import { Toast } from '../../../components/notificationComponent/index.jsx';
import SvgBadge from '../../../components/Entry/svgBadge.jsx';
import SvgInfo from '../../../components/Entry/svgInfo.jsx';
import Zoom from '@material-ui/core/Zoom';
import Tooltips from '../../../components/tooltips/index.jsx';
import DocumentList from './DocumentList.jsx';
import ModalComponent from '../../../components/modalComponent/index.jsx';
import { makeStyles } from '@material-ui/core/styles';
import SvgAdd from '../../../components/Entry/svgAdd.jsx';
import FormControl from '@material-ui/core/FormControl';
import Controls from '../../../components/Entry/Controls.jsx';
import { FaRegEdit } from 'react-icons/fa';

const styles = {
  formControl: {
    width: '100%',
    marginBottom: '30px',
    position: 'relative',
    '& span': {
      position: 'absolute',
      fontSize: '0.75rem',
      color: '#d32f2f',
      bottom: '-15px',
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    },
    "& .MuiFormLabel-root": {
      background: '#FFFF',
      padding: '0 5px',
    },
    '& .MuiInputBase-root': {
      overflow: 'hidden'
    }
  }
}

const useStyles = makeStyles(styles);

function ModalContentBody(props) {
  const classes = useStyles();
  const { data, setData, isSubmitted, employee } = props

  useEffect(() => {
    if (employee?.EmployeePermis?.length !== 0) {
      setData({
        numero: employee?.EmployeePermis?.length !== 0 ? employee?.EmployeePermis[0]?.numero : '',
        category: employee?.EmployeePermis?.length !== 0 ? employee?.EmployeePermis[0]?.category : '',
        visiteDate: employee?.EmployeePermis?.length !== 0 ? employee?.EmployeePermis[0]?.visiteDate : '',
      })
    }
    // eslint-disable-next-line
  }, [])

  const checkNumeroEmpty = () => {
      if(isSubmitted && !data.numero) {
          return "Champ obligatoire."
      }
  }

  const checkVisiteDateEmpty = () => {
    if(isSubmitted && !data.visiteDate) {
        return "Champ obligatoire."
    }
  }

  const checkCategoryEmpty = () => {
    if(isSubmitted && !data.category) {
        return "Champ obligatoire."
    }
  }

  const handleChange = (e) => {
      const { name, value } = e.target
      setData({
          ...data,
          [name]: value
      })
  }

  return (
      <>
          <form>
              <FormControl className={classes.formControl} >
                  <Controls.Input
                      name="numero"
                      label="Numéro"
                      value={data.numero}
                      onChange={handleChange}
                      error={checkNumeroEmpty()}
                  />
              </FormControl>
              <FormControl className={classes.formControl} >
                  <Controls.Input
                      name="category"
                      label="Catégorie"
                      value={data.category}
                      onChange={handleChange}
                      error={checkCategoryEmpty()}
                  />
              </FormControl>
              <FormControl className={classes.formControl} >
                  <Controls.DatePicker
                    name="visiteDate"
                    label="Date de visite"
                    value={data.visiteDate}
                    onChange={handleChange}
                    error={checkVisiteDateEmpty()}
                  />
                  <span>{checkVisiteDateEmpty()}</span>
              </FormControl>
          </form>
      </>
  )
}

export default function ProfileInfo(props) {
  const { employee, setEmployee } = props;
  const url = process.env.REACT_APP_BASE_URL;
  const [loadingBadge, setLoadingBadge] = useState(false);
  const [loadingFDR, setLoadingFDR] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoadingSavePermis, setIsLoadingSavePermis] = useState(false);
  const initialDataValue = {
    numero: '',
    category: '',
    visiteDate: null,
}
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(initialDataValue);

  const downloadPDFBadge = () => {
    setLoadingBadge(true);
    EmployeeService.printPdfBadge(employee.id).then(
      (res) => {
        if(res?.status?.code === 200) {
          const pdf64 = res.data.content;
          let a = document.createElement("a");
          a.href = "data:application/octet-stream;base64,"+pdf64;
          a.download = `badge-${res.data.matricule}.pdf`
          a.click();
          setLoadingBadge(false);
        } else {
          Toast.error(res.status.message);
          setLoadingBadge(false);
        }
      },
    )
  }

  const downloadPDFFDR = () => {
    setLoadingFDR(true);
    EmployeeService.printPdfFDR(employee.id).then(
      (res) => {
        if(res?.status?.code === 200) {
          const pdf64 = res.data.content;
          let a = document.createElement("a");
          a.href = "data:application/octet-stream;base64,"+pdf64;
          a.download = `fiche-de-renseignement-${res.data.matricule}.pdf`
          a.click();
          setLoadingFDR(false);
        } else {
          Toast.error(res.status.message);
          setLoadingFDR(false);
        }
      },
    )
  }

  const openModalAdd = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setData(initialDataValue);
    setIsSubmitted(false);
  };

  const savePermis = async () => {
    setIsSubmitted(true);
    setIsLoadingSavePermis(true);
    if(data.numero && data.category && data.visiteDate) {
      const response = await EmployeeService.addPermis(employee.id, data);
      if(response?.status?.code === 200) {
            let dataRes = {
              id: response.data.id,
              numero: response.data.numero,
              category: response.data.category,
              visiteDate: response.data.visiteDate,
            }
            setEmployee({...employee, EmployeePermis: [dataRes]})
            setIsLoadingSavePermis(false);
            closeModal();
            Toast.success(response.status.message);
        }
    }
  }

  const addSpacesPhoneNumber = (str) => {
    if (str.slice(0, 1) === '0') {
      return '+261 ' + str.slice(1, 3) + ' '.repeat(1) + str.slice(3, 5) + ' '.repeat(1) + str.slice(5, 8) + ' '.repeat(1) + str.slice(8);
    } else {
      return str.slice(0, 4) + ' '.repeat(1) + str.slice(4, 6) + ' '.repeat(1) + str.slice(6, 8) + ' '.repeat(1) + str.slice(8, 11) + ' '.repeat(1) + str.slice(11);
    }
  }

  const addSpacesCIN = (str) => {
      return str.slice(0, 3) + ' '.repeat(1) + str.slice(3, 6) + ' '.repeat(1) + str.slice(6, 9) + ' '.repeat(1) + str.slice(9, 12);
  }

  return (
    <>
      {Object.keys(employee).length !== 0 &&
      <Box>
        <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }} >
            <Box style={{ width: '39%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Card style={{ height: '100%'}}>
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe">
                    <CiImageOn size={15} style={{ marginRight: '5px' }} />
                  </Avatar>
                }
                title="PHOTO"
              />
              <CardBody style={{ padding: '0', margin : 'auto', width: '200px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {employee.photo === null ? (
                  <img src={avatar} alt={avatar} style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
                ) : (
                  <>
                    <Typography component='div' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Box>
                        <img src={url+employee.photo} alt={employee.photo} style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
                      </Box>
                    </Typography>
                  </>
                )}
                <Box sx={{ flexDirection: 'row', marginTop: '20px' }}>
                  <Tooltips TransitionComponent={Zoom} title="Badge">
                    <Button
                      disabled={loadingBadge}
                      onClick={downloadPDFBadge}
                      style={{ textTransform: 'capitalize', opacity : loadingBadge ? '0.5' : '1' }}
                      variant="text"
                      size="small"
                    >{ loadingBadge && <CircularProgress style={{ width: '20px', height: '20px', position: 'absolute' }} color="inherit" />}
                      <SvgBadge />
                    </Button>
                  </Tooltips>
                  <Tooltips TransitionComponent={Zoom} title="Fiche de renseignement">
                    <Button
                      disabled={loadingFDR}
                      onClick={downloadPDFFDR}
                      style={{ textTransform: 'capitalize', opacity : loadingFDR ? '0.5' : '1' }}
                      variant="text"
                      size="small"
                    >{ loadingFDR && <CircularProgress style={{ width: '20px', height: '20px', position: 'absolute' }} color="inherit" />}
                      <SvgInfo />
                    </Button>
                  </Tooltips>
                </Box>
              </CardBody>
            </Card>
          </Box>
          <Box style={{ width: '59%' }}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe">
                    <BsPersonExclamation size={15} />
                  </Avatar>
                }
                title="ETAT CIVIL"
              />
              <CardBody style={{ paddingTop: '0' }}>
                <Box>
                  <Box>
                    <Box><u>Nom</u> : {employee.lastName || '-'}</Box>
                    <Box><u>Prénom</u> : {employee.firstName || '-'}</Box>
                  </Box>
                </Box>
                <br />

                {!moment(employee.dateOfBirth).isValid() ? (
                  <Alert severity="warning">
                    Veuillez renseigner la date de naissance de cette personne.
                  </Alert>
                ) : (
                  <>
                    <Box>
                      <u>Date de naissance</u> :{' '}
                      {employee.dateOfBirth ? moment(employee.dateOfBirth).format(
                        'DD MMMM YYYY'
                      ) : '-'}{' '}
                      à {employee.placeOfBirth || '-'}
                    </Box>
                  </>
                )}
                <br />

                {!employee.nationality ? (
                  <Alert severity="warning">
                    Veuillez renseigner la nationalité de cette personne.
                  </Alert>
                ) : (
                  <>
                    <Box>
                      <Box>
                        <Box>
                          <u>Nationalité</u> : {employee.nationality === 'MG' ? 'Malagasy' : 'Autre' || '-'}
                        </Box>

                        {employee.nationality === 'MG' && (
                          <Box>
                            <u>CIN</u> : {addSpacesCIN(employee.cin) || '-'} délivré
                            le{' '}
                            {employee.cinDate ? moment(employee.cinDate).format(
                              'DD MMMM YYYY'
                            ) : '-'}{' '}
                            à {employee.cinPlace || '-'}
                          </Box>
                        )}

                        {employee.nationality !== 'MG' && (
                          <Box>
                            <Box>
                              <u>Passeport</u> :{' '}
                              {employee.passeport || '-'} délivré le{' '}
                              {employee.passeportDate ? moment(employee.passeportDate).format(
                                'DD MMMM YYYY'
                              ) : '-'}{' '}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </>
                )}

                {!employee.nationality ? (
                  <Alert severity="warning">
                    Veuillez renseigner la situation familiale de cette personne.
                  </Alert>
                ) : (
                  <>
                    <Box style={{ marginTop: '10px' }}>
                      <Box>
                        <Box>
                          <u>Situation familiale</u> :{' '}
                          {employee.maritalStatus !== "veuf" ? (employee.maritalStatus !== "divorce" ? (employee.maritalStatus === "celibataire" ? "Célibataire" : "Marié(e)") : "Divorcé(e)") : "veuf(ve)"}
                        </Box>
                        {employee.maritalStatus === 'marie' && (
                          <>
                            <Box><u> Epoux(se)</u> : {employee.conjointName || '-'} </Box>

                            <Box> <u> Né(e) le </u> : {employee.conjointDob ? moment(employee.conjointDob)
                              .format(
                                'DD MMMM YYYY'
                              ) : '-'}
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                  </>
                )}

                {employee.EmployeeAddress.length === 0 ? (
                  <Alert severity="warning">
                    Pas d&apos;adresse enregistrée.
                  </Alert>
                ) : (
                  <>
                    <Box>
                      <u>Adresse</u> :{' '}
                        {employee.EmployeeAddress[0].address || '-'}
                        {employee.EmployeeAddress[0].city &&
                          ' ' + employee.EmployeeAddress[0].city}
                        {employee.EmployeeAddress[0].postalCode && ' ' + employee.EmployeeAddress[0].postalCode}{', '}
                        {employee.EmployeeAddress[0].country &&
                          ' ' + employee.EmployeeAddress[0].country}
                    </Box>
                  </>
                )}
              </CardBody>
            </Card>
          </Box>
        </Box>
        {/* <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px', width: '49%'}} >
          <Card>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe">
                  <LuMapPin size={15} style={{ marginRight: '5px' }} />
                </Avatar>
              }
              title="ADRESSE"
            />
            <CardBody style={{ paddingTop: '0' }}>
              {employee.EmployeeAddress.length === 0 ? (
                <Alert severity="warning">
                  Pas d&apos;adresse enregistrée.
                </Alert>
              ) : (
                  <Typography component='div'>
                    {employee.EmployeeAddress[0].address || '-'}
                    {employee.EmployeeAddress[0].city &&
                      ' ' + employee.EmployeeAddress[0].city}
                    {employee.EmployeeAddress[0].postalCode && ' ' + employee.EmployeeAddress[0].postalCode}{', '}
                    {employee.EmployeeAddress[0].country &&
                      ' ' + employee.EmployeeAddress[0].country}
                  </Typography>
              )}
            </CardBody>
          </Card>
        </Box> */}
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Card style={{ width: '100%', marginRight: '20px' }}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe">
                  <LuBaby size={15} style={{ marginRight: '5px' }} />
                </Avatar>
              }
              title="ENFANT(S)"
            />
            <CardBody style={{ paddingTop: '0' }}>
              <u>Nombre d&apos;enfants</u> : {employee?.EmployeeChildren?.length}
            </CardBody>
          </Card>

          <Card style={{ width: '92%', marginRight: '20px' }}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe">
                  <LuPhone size={15} style={{ marginRight: '5px' }} />
                </Avatar>
              }
              title="CONTACTS"
            />
            <CardBody style={{ paddingTop: '0' }}>
              {!employee.phoneNumber ? (
                <Alert severity="warning">Pas de téléphone enregistré</Alert>
              ) : (
                <>
                  <Box>
                    <Box><u> Téléphone</u> : {addSpacesPhoneNumber(employee.phoneNumber) || '-'}</Box>
                  </Box>
                </>
              )}
              <Box style={{ marginTop: '10px' }}>
                {!employee.urgencePhoneNumber ? (
                  <Alert severity="warning">
                    Pas de numéro d&apos;urgence enregistré
                  </Alert>
                ) : (
                  <>
                    <u>En cas d&apos;urgence</u> : <br />
                    <Box>
                      <Box>
                        <Box><u>Nom</u> : {employee.urgencePersonName || '-'}<br /></Box>
                        <Box><u>Téléphone</u> : {addSpacesPhoneNumber(employee.urgencePhoneNumber) || '-'}{' '}<br /></Box>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </CardBody>
          </Card>

          <Card style={{ width: '100%' }}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe">
                  <IoCarSportOutline size={15} style={{ marginRight: '5px' }} />
                </Avatar>
              }
              action={
                  <Tooltips TransitionComponent={Zoom} title={`${employee?.EmployeePermis !== undefined && employee?.EmployeePermis.length !== 0 ? "Modifier " : "Ajouter "} permis de conduire`}>
                    <Button onClick={openModalAdd}>
                        {(employee?.EmployeePermis !== undefined && employee?.EmployeePermis.length !== 0) ?
                        <FaRegEdit/> :
                        <SvgAdd />
                        }
                    </Button>
                  </Tooltips>
              }
              title="PERMIS DE CONDUIRE"
            />
            <CardBody style={{ paddingTop: '0' }}>
              {employee.EmployeePermis.length === 0 ? (
                <Alert severity="warning">
                  Pas de permis de conduire enregistré
                </Alert>
              ) : (
                <>
                  <u>Numéro</u> : {employee?.EmployeePermis[0]?.numero || '-'}<br />
                  <u>Catégorie</u> : {employee?.EmployeePermis[0]?.category || '-'}<br />
                  <u>Date de visite</u> : {moment(employee?.EmployeePermis[0]?.visiteDate).format('DD MMMM YYYY') || '-'}
                </>
              )}
            </CardBody>
          </Card>
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'row', marginTop: '40px' }}>
          <DocumentList employee={employee} setEmployee={setEmployee} />
        </Box>
      </Box>}
      <ModalComponent
        loading={isLoadingSavePermis}
        openModal={open}
        close={() => closeModal()}
        title={ employee?.EmployeePermis !== undefined && employee?.EmployeePermis.length !== 0 ? 'Modifier' : 'Ajouter'}
        submit={() => savePermis()}
        actionTitle={'Enregistrer'}
        modalContentBody={<ModalContentBody isLoadingSavePermis={isLoadingSavePermis} employee={employee} data={data} setData={setData} isSubmitted={isSubmitted} />}
      />
    </>
  )
}

