import React from 'react';
import { Button } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    progress: {
        width: '30px !important',
        height: '30px !important',
        position: 'absolute',
        left: 0
    }
}))

export default function ButtonComponent(props) {

    const { text, size, color, variant, onClick, loading = false, ...other } = props
    const classes = useStyles();

    return (
        <>
            <Button
                sx={{textTransform: 'none'}}
                variant={variant || "contained"}
                // color="primary"
                type='submit'
                onClick={onClick}
                size={size || "small"}
                {...other}
                disabled={loading}
                >
                { loading && <CircularProgress className={classes.progress} color="inherit" /> }
                    {text}
            </Button>
        </>
    )
}