import { makeStyles } from '@material-ui/core/styles';

const styles = {
  title: {
    marginTop: '20px !important',
    margin: '0px !important',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important',
    fontWeight: '500 !important',
    fontSize: '1.25rem !important',
    lineHeight: '1.6 !important',
    letterSpacing: '0.0075em !important',
    textTransform: 'none !important'
  },
  totalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& span': {
      margin: '0px !important',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important',
      fontWeight: '500 !important',
      fontSize: '1rem !important',
      lineHeight: '1.6 !important',
      letterSpacing: '0.0075em !important',
      textTransform: 'none !important'
    }
  },
  progress: {
    width: '16px !important',
    height: '16px !important',
    position: 'absolute',
    left: '-5px'
  },
}

const useStyles = makeStyles(styles);

export default useStyles;