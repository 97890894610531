export default class dataModels {
  static employeModel() {
      return [
          {
              label: 'Employé',
              tableName: 'employe',
              properties: [
                  // {
                  //   label: 'Matricule',
                  //   id: 'matricule',
                  //   isForeignKey: true,
                  //   type: 'text',
                  //   required: true,
                  // },
                  {
                    label: 'Nom',
                    id: 'lastName',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: 'Prénoms',
                    id: 'firstName',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: 'Civilité',
                    id: 'civility',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: 'Père',
                    id: 'fatherName',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Mère",
                    id: 'motherName',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: 'Genre',
                    id: 'sexe',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: 'Nationalité',
                    id: 'nationality',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: 'Date de naissance',
                    id: 'dateOfBirth',
                    type: 'date',
                    required: true,
                  },
                  {
                    label: 'Lieu de naissance',
                    id: 'placeOfBirth',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: 'Numéro CIN',
                    id: 'cin',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: 'Date de délivrance',
                    id: 'cinDate',
                    type: 'date',
                    required: true,
                  },
                  {
                    label: 'Lieu de délivrance',
                    id: 'cinPlace',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: 'Passeport',
                    id: 'passeport',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: 'Date passeport',
                    id: 'passeportDate',
                    type: 'date',
                    required: true,
                  },
                  {
                    label: 'Situation matrimonale',
                    id: 'maritalStatus',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: 'Nom de conjoint(e)',
                    id: 'conjointName',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: 'Date de naissance du conjoint(e)',
                    id: 'conjointDob',
                    type: 'date',
                    required: true,
                  },
                  {
                    label: 'Téléphone',
                    id: 'phoneNumber',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Numéro d'urgence",
                    id: 'urgencePhoneNumber',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Nom d'urgence",
                    id: 'urgencePersonName',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Relation",
                    id: 'relation',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Adresse",
                    id: 'address',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Ville",
                    id: 'city',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Région",
                    id: 'country',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Code postale",
                    id: 'postalCode',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Fonction",
                    id: 'jobTitle',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Type de contrat",
                    id: 'contractType',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Début du contrat",
                    id: 'contractStartDate',
                    type: 'date',
                    required: true,
                  },
                  {
                    label: "Fin du contrat",
                    id: 'contractEndDate',
                    type: 'date',
                    required: true,
                  },
                  {
                    label: "Département",
                    id: 'department',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Service",
                    id: 'service',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Catégorie",
                    id: 'category',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Groupe",
                    id: 'group',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Salaire de base",
                    id: 'baseSalary',
                    type: 'number',
                    required: true,
                  },
                  {
                    label: "Solde de congé",
                    id: 'conges',
                    type: 'number',
                    required: true,
                  },
                  {
                    label: "Numéro CNaPS",
                    id: 'cnaps',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Méthode de paiement",
                    id: 'paymentMethod',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Domiciliation",
                    id: 'domiciliation',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Code banque",
                    id: 'bankCode',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Code guichet",
                    id: 'agencyCode',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Numéro de compte",
                    id: 'accountNumber',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Clé",
                    id: 'key',
                    type: 'text',
                    required: true,
                  },
                  {
                    id: 'iban',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Mobile money",
                    id: 'mobileMoney',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Numéro mobile money",
                    id: 'mobileMoneyNumber',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Nom mobile money",
                    id: 'mobileMoneyName',
                    type: 'text',
                    required: true,
                  },
                  {
                    label: "Heures par semaine",
                    id: 'hoursWeek',
                    type: 'text',
                    required: true,
                  }
              ]
          },
          {
            label: 'Congé',
            tableName: 'conge',
            properties: [
              {
                label: 'Matricule',
                id: 'matricule',
                type: 'text',
                required: true,
              },
              {
                label: 'Date de début',
                id: 'start',
                type: 'date',
                required: true,
              },
              {
                label: 'AM/PM',
                id: 'startJourney',
                type: 'text',
                required: true,
              },
              {
                label: 'Date fin',
                id: 'end',
                type: 'date',
                required: true,
              },
              {
                label: 'AM/PM',
                id: 'endJourney',
                type: 'text',
                required: true,
              },
              {
                label: 'Type de congé',
                id: 'congeType',
                type: 'text',
                required: true,
              },
              {
                label: 'Autre motif',
                id: 'anotherReason',
                type: 'text',
                required: true,
              },
            ]
          },
          {
            label: 'Absence',
            tableName: 'abs',
            properties: [
              {
                label: 'Matricule',
                id: 'matricule',
                type: 'text',
                required: true,
              },
              {
                label: 'Date de début',
                id: 'startAbs',
                type: 'date',
                required: true,
              },
              {
                label: 'AM/PM',
                id: 'startJourneyAbs',
                type: 'text',
                required: true,
              },
              {
                label: 'Date fin',
                id: 'endAbs',
                type: 'date',
                required: true,
              },
              {
                label: 'AM/PM',
                id: 'endJourneyAbs',
                type: 'text',
                required: true,
              },
              {
                label: "Type d'absence",
                id: 'absType',
                type: 'text',
                required: true,
              },
              {
                label: 'Autre motif',
                id: 'anotherReasonAbs',
                type: 'text',
                required: true,
              },
            ]
          },
          {
            label: 'Pointage',
            tableName: 'pointage',
            properties: [
              {
                label: 'Matricule',
                id: 'matricule',
                type: 'text',
                required: true,
              },
              {
                label: 'Date',
                id: 'date',
                type: 'date',
                required: true,
              },
              {
                label: 'Heure de début matin',
                id: 'startAM',
                type: 'time',
                required: true,
              },
              {
                label: 'Heure de fin matin',
                id: 'endAM',
                type: 'time',
                required: true,
              },
              {
                label: 'Heure de début après midi',
                id: 'startPM',
                type: 'time',
                required: true,
              },
              {
                label: 'Heure de fin après midi',
                id: 'endPM',
                type: 'time',
                required: true,
              }
            ]
          },
          {
            label: 'Document (.zip)',
            tableName: 'document',
            // properties: [
            //   {
            //     label: 'Matricule',
            //     id: 'matricule',
            //     type: 'text',
            //     required: true,
            //   },
            // ]
          }
      ]
  }
}