import React from 'react';

export default function SvgLock() {
    return (
        <svg version="1.1" id="Capa_1" width="30px" height="30px" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 32 32">
            <g>
                <g>
                    <path fill="rgb(25, 118, 210)" d="M32,23.001c0-3.917-2.506-7.24-5.998-8.477V4h-2V1.999h2V0h-23
                        C2.918,0.004,2.294-0.008,1.556,0.354C0.808,0.686-0.034,1.645,0.001,3c0,0.006,0.001,0.012,0.001,0.018V30c0,2,2,2,2,2h21.081
                        l-0.007-0.004C28.014,31.955,32,27.945,32,23.001z M2.853,3.981C2.675,3.955,2.418,3.869,2.274,3.743
                        C2.136,3.609,2.017,3.5,2.002,3c0.033-0.646,0.194-0.686,0.447-0.856c0.13-0.065,0.289-0.107,0.404-0.125
                        C2.97,1.997,3,2.005,3.002,1.999h19V4h-19C3,4,2.97,4.002,2.853,3.981z M4,30V6h20v8.06C23.671,14.023,23.337,14,22.998,14
                        c-2.143,0-4.105,0.751-5.65,2H6v2h9.516c-0.413,0.616-0.743,1.289-0.995,2H6v2h8.057c-0.036,0.329-0.059,0.662-0.059,1.001
                        c0,2.829,1.307,5.351,3.348,6.999H4z M23,30c-3.865-0.008-6.994-3.135-7-6.999c0.006-3.865,3.135-6.995,7-7.001
                        c3.865,0.006,6.992,3.136,7,7.001C29.992,26.865,26.865,29.992,23,30z"/>
                    <rect x="6" y="12" fill="rgb(25, 118, 210)" width="16" height="2"/>
                    <rect x="22" y="22" fill="rgb(25, 118, 210)" width="2" height="6"/>
                    <rect x="22" y="18" fill="rgb(25, 118, 210)" width="2" height="2"/>
                </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
        </svg>
    )
}