import React from 'react';

export default function SvgContract() {
    return (
        <svg fill="rgb(25, 118, 210)" height="30px" width="30px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" enableBackground="new 0 0 44 44">
            <g>
                <g>
                <g>
                    <path d="m29.184,15c0-0.553-0.448-1-1-1h-18c-0.553,0-1,0.447-1,1 0,0.553 0.447,1 1,1h18c0.552,0 1-0.447 1-1zm10,24h-5v-10c0-0.553-0.448-1-1-1-0.553,0-1,0.447-1,1v10h-23c-0.553,0-1,0.447-1,1 0,1.104-0.896,2-2,2s-2-0.896-2-2v-38h28v9c0,0.553 0.447,1 1,1 0.552,0 1-0.447 1-1v-10c0-0.553-0.448-1-1-1h-30c-0.553,0-1,0.447-1,1v39c0,2.209 1.791,4 4,4h30c2.209,0 4-1.791 4-4 0-0.553-0.448-1-1-1zm-3,3h-26.564c0.181-0.312 0.33-0.643 0.423-1h27.864c-0.347,0.596-0.984,1-1.723,1zm-13-34c0.552,0 1-0.447 1-1 0-0.553-0.448-1-1-1h-10c-0.553,0-1,0.447-1,1 0,0.553 0.447,1 1,1h10zm-13,11c-0.553,0-1,0.447-1,1 0,0.553 0.447,1 1,1h12c0.552,0 1-0.447 1-1 0-0.553-0.448-1-1-1h-12z"/>
                </g>
                </g>
                <g>
                <g>
                    <path d="m41.318,5.447c-0.479-0.276-1.09-0.112-1.366,0.366l-15,23.32c-0.275,0.479-0.112,1.09 0.366,1.366 0.479,0.276 1.09,0.112 1.366-0.366l3.271-5.085c0.757,0.591 1.695,0.952 2.729,0.952 2.229,0 4.067-1.625 4.425-3.754l4.582-15.452c0.261-0.476 0.1-1.074-0.373-1.347zm-5.615,14.597c-0.476,2.879-2.195,4.583-4.425,3.364-0.07-0.038-0.121-0.086-0.187-0.127l6.985-10.859-2.373,7.622z"/>
                </g>
                </g>
            </g>
        </svg>
    )
}