import { Http } from '../../../applications/Http.js'

// class EmployeeService extends Service {
class ImportDataService {
    static async verifyDataEmploye(file) {
        const formdata = new FormData();
        formdata.append('file', file);
        return await Http.put(`/api/employees/import-excel`, formdata);
    }
    static async verifyDataConge(file) {
        const formdata = new FormData();
        formdata.append('file', file);
        return await Http.put(`/api/holyday/import-excel`, formdata);
    }
    static async verifyDataAbs(file) {
        const formdata = new FormData();
        formdata.append('file', file);
        return await Http.put(`/api/absences/import-excel`, formdata);
    }
    static async verifyDataPointage(file) {
        const formdata = new FormData();
        formdata.append('file', file);
        return await Http.put(`/api/pointages/import-excel`, formdata);
    }
    static async verifyDataZip(file) {
        const formdata = new FormData();
        formdata.append('file', file);
        return await Http.put(`/api/employees/import-file-zip`, formdata);
    }
    static async saveEmploye(payload) {
        return await Http.put(`/api/employees/save-import-excel`, payload);
    }
    static async saveConge(payload) {
        return await Http.put(`/api/holyday/save-import-excel`, payload);
    }
    static async saveAbs(payload) {
        return await Http.put(`/api/absences/save-import-excel`, payload);
    }
    static async savePointage(payload) {
        return await Http.put(`/api/pointages/save-import-excel`, payload);
    }
    static async saveDataZip(payload) {
        return await Http.put(`/api/employees/save-file-zip?url=${payload}`);
    }
}

export default ImportDataService
