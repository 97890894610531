import React, { useEffect, useContext } from 'react';
import { Grid } from '@mui/material';
import Controls from '../../../components/Entry/Controls.jsx';
import { AddEmployeeContext } from './index.jsx';
import FormControl from '@material-ui/core/FormControl';

const arrayCity = [
    {id: 1, value: 'Antananarivo', title: 'Antananarivo' },
    {id: 2, value: 'Fianarantsoa', title: 'Fianarantsoa' },
    {id: 4, value: 'Toamasina', title: 'Toamasina' },
]

export default function Address(props) {
    const { errors, setErrors, valuesAddress, setValuesAddress, arrayRegion, arrayPostalCode } = useContext(AddEmployeeContext)
    // const [values, setValues] = useState(initialFValues)

    const handleInputChange = (e) => {
        const { name, value } = e.target
        if (name === 'city') {
            setValuesAddress({ ...valuesAddress, [name]: value, country : '', postalCode: '' });
        } else {
            setValuesAddress({
                ...valuesAddress,
                [name]: value
            })
        }
    }

    const validate = (fieldValues = valuesAddress) => {
        let temp = { ...errors }
        if ('address' in fieldValues) temp.address = fieldValues.address ? '' : 'Champ obligatoire.'
        if ('city' in fieldValues) temp.city = fieldValues.city ? '' : 'Champ obligatoire.'
        if ('country' in fieldValues) temp.country = fieldValues.country ? '' : 'Champ obligatoire.'
        if ('postalCode' in fieldValues) temp.postalCode = fieldValues.postalCode ? '' : 'Champ obligatoire.'

        for (const key in temp) {
            if (temp[key] === '') {
                delete temp[key]
            }
        }

        setErrors({
            ...temp
        })

        return Object.keys(temp).length === 0
    }

    useEffect(() => {
        validate()
    })

    // //handle load
    // useEffect(() => {
    //     !addEmployeeData.address
    //         ? setAddEmployeeData({
    //               ...addEmployeeData,
    //               address: values
    //           })
    //         : setValues(addEmployeeData.address)
    // }, [])

    // //handle click to next
    // useEffect(() => {
    //     setAddEmployeeData({
    //         ...addEmployeeData,
    //         address: values
    //     })
    // }, [clickBack, clickNext, values])

    return (
        <form>
            <Grid container style={{ padding: '0 0 50px' }}>
                <Grid container>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.Input
                                name="address"
                                label="Adresse"
                                value={valuesAddress.address}
                                onChange={handleInputChange}
                                error={errors.address}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.Select
                                name="city"
                                label="Ville"
                                value={valuesAddress.city}
                                onChange={(e) => {
                                    handleInputChange(e)
                                }}
                                options={arrayCity}
                                error={errors.city}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%' }}>
                            <Controls.Select
                                name="country"
                                label="Région"
                                value={valuesAddress.country}
                                onChange={(e) => {
                                    handleInputChange(e)
                                }}
                                options={arrayRegion}
                                error={errors.country}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%' }}>
                            <Controls.Select
                                name="postalCode"
                                label="Code postal"
                                value={valuesAddress.postalCode}
                                onChange={(e) => {
                                    handleInputChange(e)
                                }}
                                options={arrayPostalCode}
                                error={errors.postalCode}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}
