import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Controls from '../../../components/Entry/Controls';
import { AddEmployeeContext } from './index.jsx';
import FormControl from '@material-ui/core/FormControl';
import BigNumber from 'bignumber.js';
import { Chip } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { makeStyles } from '@material-ui/core/styles';

const styles = {
    choiceMobileMoney: {
        width: '50%',
        marginBottom: '40px',
        '& .MuiFormControl-root': {
            alignItems: 'center'
        }
    },
}

const typeContractItems = [
    { id: 'cdi', title: 'CDI' },
    { id: 'cdd', title: 'CDD' },
    { id: 'pe', title: "Essai" }
]

const groupItems = [
    { id: 'I', title: 'I' },
    { id: 'II', title: 'II' },
    { id: 'III', title: 'III' },
    { id: 'IV', title: 'IV' },
    { id: 'V', title: 'V' }
]

const mobileMoneyItems = [
    { id: 'mvola', title: 'Mvola' },
    { id: 'orangeMoney', title: 'Orange Money' },
    { id: 'airtelMoney', title: 'Airtel Money' }
]

const serviceItems = [
    { id: 'Administration', title: 'Administration' },
    { id: 'Production', title: 'Production' }
]

const departmentItems = [
    { id: 'DRH', title: 'DRH' },
    { id: 'DAF', title: 'DAF' },
    { id: 'Maintenance', title: 'Maintenance' }
]

const statusItems = [
    //{ id: 0, title: 'Terminé' },
    { id: 1, title: 'En poste' },
    { id: 2, title: 'Démissionnaire' },
]

const paymentMethodItems = [
    { id: 1, title: 'Espèces' },
    { id: 2, title: 'Virement bancaire' },
    { id: 3, title: 'Chèque' },
    { id: 4, title: 'Mobile money' }
]

const useStyles = makeStyles(styles);

export default function Contract(props) {
    const { errors, setErrors, setValuesContract, valuesContract, arrayCategory, valuesOther, setValuesOther, idEmployee } = useContext(AddEmployeeContext);
    const classes = useStyles();
    const [iban, setIban] = useState(null);

    const verifyRIB = (rib) => {
        const numeroRIB =  rib.replace(/ /g, '');
        if (!numeroRIB) {
          return false;
        }
    
        const ribSansCle = numeroRIB.slice(0, -2) + '00';
        const ribChiffres = ribSansCle
          .replace(/[AJ]/g, '1')
          .replace(/[BKS]/g, '2')
          .replace(/[CLT]/g, '3')
          .replace(/[DMU]/g, '4')
          .replace(/[ENV]/g, '5')
          .replace(/[FOW]/g, '6')
          .replace(/[GPX]/g, '7')
          .replace(/[HQY]/g, '8')
          .replace(/[IRZ]/g, '9');
    
        const modulo = BigNumber(ribChiffres).modulo(97).toNumber();
        const checkDigit = 97 - modulo;
    
        return checkDigit === parseInt(numeroRIB.slice(-2));
    };

    const validate = (fieldValues = valuesContract, fieldValuesOther = valuesOther, fieldIban = iban) => {
        let temp = { ...errors }
        if ('jobTitle' in fieldValues) temp.jobTitle = fieldValues.jobTitle ? '' : 'Champ obligatoire.'
        if ('contractType' in fieldValues) temp.contractType = fieldValues.contractType ? '' : 'Champ obligatoire.'
        if ('contractStartDate' in fieldValues) temp.contractStartDate = fieldValues.contractStartDate ? '' : 'Champ obligatoire.'
        if ('hoursWeek' in fieldValues) temp.hoursWeek = fieldValues.hoursWeek ? '' : 'Champ obligatoire.'

        if ('category' in fieldValues) temp.category = fieldValues.category ? '' : 'Champ obligatoire.'
        if ('group' in fieldValues) temp.group = fieldValues.group ? '' : 'Champ obligatoire.'
        if ('baseSalary' in fieldValues) temp.baseSalary = fieldValues.baseSalary ? '' : 'Champ obligatoire.'
        if ('status' in fieldValues) temp.status = fieldValues.status ? '' : 'Champ obligatoire.'

        if (fieldValues.contractType !== 'cdi') {
            if ('contractEndDate' in fieldValues)
                temp.contractEndDate = fieldValues.contractEndDate ? '' : 'Champ obligatoire.'
        } else {
            delete temp.contractEndDate
        }

        // other
        // if ('cnaps' in fieldValuesOther) temp.cnaps = fieldValuesOther.cnaps ? '' : 'Champ obligatoire.'
        if ('paymentMethod' in fieldValuesOther) temp.paymentMethod = fieldValuesOther.paymentMethod ? '' : 'Champ obligatoire.'
        if (fieldValuesOther.paymentMethod === 2) {
            if ('domiciliation' in fieldValuesOther && fieldValuesOther.paymentMethod === 2)
                temp.domiciliation = fieldValuesOther.domiciliation ? '' : 'Champ obligatoire.'
            if ('bankCode' in fieldValuesOther && fieldValuesOther.paymentMethod === 2)
                temp.bankCode = fieldValuesOther.bankCode ? '' : 'Champ obligatoire.'
            if ('bankCode' in fieldValuesOther && fieldValuesOther.bankCode)
                temp.bankCode = fieldValuesOther.bankCode.length !== 5 && fieldValuesOther.paymentMethod === 2 ? '5 chiffres.' : ''
            if ('agencyCode' in fieldValuesOther && fieldValuesOther.paymentMethod === 2)
                temp.agencyCode = fieldValuesOther.agencyCode ? '' : 'Champ obligatoire.'
            if ('agencyCode' in fieldValuesOther && fieldValuesOther.agencyCode)
                temp.agencyCode = fieldValuesOther.agencyCode.length !== 5 && fieldValuesOther.paymentMethod === 2 ? '5 chiffres.' : ''
            if ('accountNumber' in fieldValuesOther && fieldValuesOther.paymentMethod === 2)
                temp.accountNumber = fieldValuesOther.accountNumber ? '' : 'Champ obligatoire.'
            if ('accountNumber' in fieldValuesOther && fieldValuesOther.accountNumber)
                temp.accountNumber = fieldValuesOther.accountNumber.length !== 11 && fieldValuesOther.paymentMethod === 2 ? '11 chiffres.' : ''
            if ('key' in fieldValuesOther && fieldValuesOther.paymentMethod === 2) temp.key = fieldValuesOther.key ? '' : 'Champ obligatoire.'
            if ('key' in fieldValuesOther && fieldValuesOther.key)
                temp.key = fieldValuesOther.key.length !== 2 && fieldValuesOther.paymentMethod === 2 ? '2 chiffres.' : ''
            if (fieldIban && fieldIban.length === 23 && fieldValuesOther.paymentMethod === 2) {
                temp.iban = !verifyRIB(fieldIban) ? 'Rib invalide' : ''
            } else {
                temp.iban = ''
            }
        } else {
            temp.domiciliation = ''
            temp.bankCode = ''
            temp.agencyCode = ''
            temp.accountNumber = ''
            temp.key = ''
        }
        if (fieldValuesOther.paymentMethod === 4) {
            if ('mobileMoney' in fieldValuesOther) temp.mobileMoney = fieldValuesOther.mobileMoney ? '' : 'Champ obligatoire.'
            if ('mobileMoneyName' in fieldValuesOther) temp.mobileMoneyName = fieldValuesOther.mobileMoneyName ? '' : 'Champ obligatoire.'
            if ('mobileMoneyNumber' in fieldValuesOther) temp.mobileMoneyNumber = fieldValuesOther.mobileMoneyNumber ? '' : 'Champ obligatoire.'
            if ('mobileMoneyNumber' in fieldValuesOther && fieldValuesOther.mobileMoneyNumber && fieldValuesOther.mobileMoney === 'mvola')
                temp.mobileMoneyNumber = /^(\+26134|\+26138|034|038)/.test(fieldValuesOther.mobileMoneyNumber) ? '' : '+26134/+26138/034/038'
            if ('mobileMoneyNumber' in fieldValuesOther && fieldValuesOther.mobileMoneyNumber && fieldValuesOther.mobileMoney === 'orangeMoney')
                temp.mobileMoneyNumber = /^(\+26132|032)/.test(fieldValuesOther.mobileMoneyNumber) ? '' : '+26132/032'
            if ('mobileMoneyNumber' in fieldValuesOther && fieldValuesOther.mobileMoneyNumber && fieldValuesOther.mobileMoney === 'airtelMoney')
                temp.mobileMoneyNumber = /^(\+26133|033)/.test(fieldValuesOther.mobileMoneyNumber) ? '' : '+26133/033'
            if ('mobileMoneyNumber' in fieldValuesOther && /^(\+26134|\+26138)/.test(fieldValuesOther.mobileMoneyNumber) && fieldValuesOther.mobileMoney === 'mvola')
                temp.mobileMoneyNumber = fieldValuesOther.mobileMoneyNumber.length !== 13 ? 'Numéro incorrect' : ''
            if ('mobileMoneyNumber' in fieldValuesOther && /^(034)/.test(fieldValuesOther.mobileMoneyNumber) && fieldValuesOther.mobileMoney === 'mvola')
                temp.mobileMoneyNumber = fieldValuesOther.mobileMoneyNumber.length !== 10 ? 'Numéro incorrect' : ''
            if ('mobileMoneyNumber' in fieldValuesOther && /^(\+26132)/.test(fieldValuesOther.mobileMoneyNumber) && fieldValuesOther.mobileMoney === 'orangeMoney')
                temp.mobileMoneyNumber = fieldValuesOther.mobileMoneyNumber.length !== 13 ? 'Numéro incorrect' : ''
            if ('mobileMoneyNumber' in fieldValuesOther && /^(032)/.test(fieldValuesOther.mobileMoneyNumber) && fieldValuesOther.mobileMoney === 'orangeMoney')
                temp.mobileMoneyNumber = fieldValuesOther.mobileMoneyNumber.length !== 10 ? 'Numéro incorrect' : ''
            if ('mobileMoneyNumber' in fieldValuesOther && /^(\+26133)/.test(fieldValuesOther.mobileMoneyNumber) && fieldValuesOther.mobileMoney === 'airtelMoney')
                temp.mobileMoneyNumber = fieldValuesOther.mobileMoneyNumber.length !== 13 ? 'Numéro incorrect' : ''
            if ('mobileMoneyNumber' in fieldValuesOther && /^(033)/.test(fieldValuesOther.mobileMoneyNumber) && fieldValuesOther.mobileMoney === 'airtelMoney')
                temp.mobileMoneyNumber = fieldValuesOther.mobileMoneyNumber.length !== 10 ? 'Numéro incorrect' : ''
        } else {
            temp.mobileMoney = ''
            temp.mobileMoneyName = ''
            temp.mobileMoneyNumber = ''
        }
        for (const key in temp) {
            if (temp[key] === '') {
                delete temp[key]
            }
        }

        setErrors({
            ...temp
        })

        return Object.keys(temp).length === 0
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        
        if(name === 'baseSalary') {
            let newValue = value.replace(/[^\d+]/g, '');
            setValuesContract({ ...valuesContract, [name]: newValue });
        } else if(name === 'group') {
            setValuesContract({ ...valuesContract, [name]: value, category : "" });
        } else if (name === 'hoursWeek') {
            let newValue = value.replace(/[^\d+]/g, '');
            setValuesContract({ ...valuesContract, [name]: newValue })
        } else {
            setValuesContract({ ...valuesContract, [name]: value });
        }
    }

    const handleInputChangeOther = (e) => {
        const { name, value } = e.target

        if (name === 'bankCode' || name === 'agencyCode') {
        // if (name === 'conges') {
            let newValue = value.replace(/[^\d+]/g, '');
            const limitedValue = newValue.slice(0, 5);
            if (limitedValue.length === 5) {
            setValuesOther({ ...valuesOther, [name]: limitedValue })
            }
            setValuesOther({ ...valuesOther, [name]: limitedValue })
        } else if(name === 'accountNumber') {
            let newValue = value.replace(/[^\d+]/g, '');
            const limitedValue = newValue.slice(0, 11);
            if (limitedValue.length === 11) {
            setValuesOther({ ...valuesOther, [name]: limitedValue })
            }
            setValuesOther({ ...valuesOther, [name]: limitedValue })
        } else if(name === 'key') {
            let newValue = value.replace(/[^\d+]/g, '');
            const limitedValue = newValue.slice(0, 2);
            if (limitedValue.length === 2) {
            setValuesOther({ ...valuesOther, [name]: limitedValue })
            }
            setValuesOther({ ...valuesOther, [name]: limitedValue })
        } else if(name === 'mobileMoneyNumber') {
            let newValue = value.replace(/[^\d+]/g, '');
            const limitedValue = newValue.slice(0, 13);
            if (limitedValue.length === 10 || limitedValue.length === 13) {
            setValuesOther({ ...valuesOther, [name]: limitedValue })
            }
            setValuesOther({ ...valuesOther, [name]: limitedValue })
        } else {
            setValuesOther({
                ...valuesOther,
                [name]: value
            })
        }
    }

    //handle load
    // useEffect(() => {
    //     !addEmployeeData.contract
    //         ? setAddEmployeeData({
    //               ...addEmployeeData,
    //               contract: values
    //           })
    //         : setValues(addEmployeeData.contract)
    // }, [])

    const resRIB =  valuesOther.bankCode+
                    valuesOther.agencyCode+
                    valuesOther.accountNumber+
                    valuesOther?.key;

    useEffect(() => {
        setIban(resRIB);
    }, [resRIB])

    // useEffect(() => {
    //     !addEmployeeData.other
    //         ? setAddEmployeeData({
    //               ...addEmployeeData,
    //               other: {
    //                   ...valuesOther,
    //                   contractSocietyStartDate: addEmployeeData.contract.contractStartDate,
    //                   status: addEmployeeData.contract.status
    //               }
    //           })
    //         : setValuesOther({
    //               ...addEmployeeData.other,
    //               contractSocietyStartDate: addEmployeeData.contract.contractStartDate,
    //               status: addEmployeeData.contract.status
    //           })
    //           // eslint-disable-next-line
    // }, [])

    useEffect(() => {
        validate()
    });

    //handle click to next
    // useEffect(() => {
    //     setAddEmployeeData({
    //         ...addEmployeeData,
    //         contract: values
    //     })
    // }, [clickBack, clickNext, values])
    const status = {
        1: { title: 'En Poste', color: 'success', icon: <CheckCircleOutlineOutlinedIcon /> },
        2: { title: 'Démissionnaire', color: 'warning', icon: <ErrorOutlineOutlinedIcon /> },
        3: { title: "Ex-employé", color: 'info', icon: <AccessTimeOutlinedIcon /> },
        4: { title: 'Renvoyer', color: 'error', icon: <DoNotDisturbAltOutlinedIcon /> }
    }

    return (
        <form>
            <Grid container>
                <Grid container>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.Input
                                name="jobTitle"
                                label="Fonction"
                                value={valuesContract.jobTitle}
                                onChange={handleInputChange}
                                error={errors.jobTitle}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center', marginTop: '20px'}} >
                        { idEmployee ?  (
                            <Chip
                                label={status[valuesContract.status]?.title}
                                color={status[valuesContract.status]?.color}
                                icon={status[valuesContract.status]?.icon}
                                size="small"
                                variant="outlined"
                            />
                        ) :
                            (<FormControl style={{ width: '50%', marginBottom: '40px' }}>
                                <Controls.Select
                                    name="status"
                                    label="Statut"
                                    value={valuesContract.status}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    options={statusItems}
                                    error={errors.status}
                                />
                            </FormControl>)
                        }
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.Select
                                name="contractType"
                                label="Type de contrat"
                                value={valuesContract.contractType}
                                onChange={(e) => {
                                    handleInputChange(e)
                                }}
                                options={typeContractItems}
                                error={errors.contractType}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        {valuesContract.contractType === 'cdi' && (
                            <FormControl style={{ width: '50%', marginBottom: '40px', marginTop: '-8px' }}>
                                <Controls.DatePicker
                                    name="contractStartDate"
                                    label="Début du contrat"
                                    value={valuesContract.contractStartDate}
                                    onChange={handleInputChange}
                                    error={errors.contractStartDate}
                                />
                            </FormControl>
                        )}
                    </Grid>
                </Grid>
                {valuesContract.contractType !== 'cdi' && (
                    <Grid container>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                            <FormControl style={{ width: '50%', marginBottom: '40px', marginTop: '-8px' }}>
                                <Controls.DatePicker
                                    name="contractStartDate"
                                    label="Début du contrat"
                                    value={valuesContract.contractStartDate}
                                    onChange={handleInputChange}
                                    error={errors.contractStartDate}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                            <FormControl style={{ width: '50%', marginBottom: '40px', marginTop: '-8px' }}>
                                <Controls.DatePicker
                                    name="contractEndDate"
                                    label="Fin du contrat"
                                    value={valuesContract.contractEndDate}
                                    onChange={handleInputChange}
                                    error={errors.contractEndDate}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                )}
                <Grid container>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.Select
                                name="group"
                                label="Groupe"
                                value={valuesContract.group}
                                onChange={(e) => {
                                    handleInputChange(e)
                                }}
                                options={groupItems}
                                error={errors.group}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.Select
                                name="category"
                                label="Catégorie"
                                value={valuesContract.category}
                                onChange={(e) => {
                                    handleInputChange(e)
                                }}
                                options={arrayCategory}
                                error={errors.category}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.Select
                                name="service"
                                label="Service"
                                value={valuesContract.service}
                                onChange={(e) => {
                                    handleInputChange(e)
                                }}
                                options={serviceItems}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.Select
                                name="department"
                                label="Département"
                                value={valuesContract.department}
                                onChange={(e) => {
                                    handleInputChange(e)
                                }}
                                options={departmentItems}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.Input
                                name="baseSalary"
                                label="Salaire de base (en Ar)"
                                value={valuesContract.baseSalary}
                                onChange={handleInputChange}
                                error={errors.baseSalary}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.Input
                                name="cnaps"
                                label="Numéro CNAPS"
                                value={valuesOther.cnaps}
                                onChange={handleInputChangeOther}
                                error={errors.cnaps}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.Input
                                name="hoursWeek"
                                label="Heures par semaine"
                                value={valuesContract.hoursWeek}
                                onChange={handleInputChange}
                                error={errors.hoursWeek}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}></Grid>
                </Grid>
                <Grid container>
                    {/* <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                            <Controls.Input
                                name="conges"
                                label="Solde du congé (en jours)"
                                value={valuesOther.conges}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid> */}
                </Grid>
                <Grid container>
                    <Grid container>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                            <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                                <Controls.Select
                                    name="paymentMethod"
                                    label="Méthode de paiement"
                                    value={valuesOther.paymentMethod}
                                    onChange={(e) => {
                                        handleInputChangeOther(e)
                                    }}
                                    options={paymentMethodItems}
                                    error={errors.paymentMethod}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                            {valuesOther.paymentMethod === 2 && (
                                <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                                    <Controls.Input
                                        name="domiciliation"
                                        label="Domiciliation"
                                        value={valuesOther.domiciliation}
                                        onChange={handleInputChangeOther}
                                        error={errors.domiciliation}
                                    />
                                </FormControl>
                            )}
                        </Grid>
                    </Grid>
                    {valuesOther.paymentMethod === 2 && (
                        <>
                            <Grid item xs={3} sx={{display: 'flex', justifyContent: 'center'}}>
                                <FormControl style={{ width: '90%', marginBottom: '40px' }}>
                                    <Controls.Input
                                        name="bankCode"
                                        label="Code banque"
                                        value={valuesOther.bankCode}
                                        onChange={handleInputChangeOther}
                                        error={errors.bankCode}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{display: 'flex', justifyContent: 'center'}}>
                                <FormControl style={{ width: '90%', marginBottom: '40px' }}>
                                    <Controls.Input
                                        name="agencyCode"
                                        label="Code guichet"
                                        value={valuesOther.agencyCode}
                                        onChange={handleInputChangeOther}
                                        error={errors.agencyCode}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{display: 'flex', justifyContent: 'center'}}>
                                <FormControl style={{ width: '90%', marginBottom: '40px' }}>
                                    <Controls.Input
                                        name="accountNumber"
                                        label="Numéro de compte"
                                        value={valuesOther.accountNumber}
                                        onChange={handleInputChangeOther}
                                        error={errors.accountNumber}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{display: 'flex', justifyContent: 'center'}}>
                                <FormControl style={{ width: '90%', marginBottom: '40px' }}>
                                    <Controls.Input
                                        name="key"
                                        label="Clé"
                                        value={valuesOther.key}
                                        onChange={handleInputChangeOther}
                                        error={errors.key}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container>
                            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', position: 'relative'}}>
                                <span style={{ position: 'absolute', fontSize: '0.75rem', color: '#d32f2f', bottom: '-10px' }}>{errors.iban}</span>
                            </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
                {valuesOther.paymentMethod === 4 && 
                    (
                        <>
                            <Grid container>
                                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                                    <FormControl className={classes.choiceMobileMoney}>
                                        <Controls.RadioGroup
                                            name="mobileMoney"
                                            label="Mobile money"
                                            value={valuesOther.mobileMoney}
                                            onChange={handleInputChangeOther}
                                            items={mobileMoneyItems}
                                            error={errors.mobileMoney}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                                    <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                                        <Controls.Input
                                            label="Numéro mobile money"
                                            name="mobileMoneyNumber"
                                            value={valuesOther.mobileMoneyNumber}
                                            onChange={handleInputChangeOther}
                                            error={errors.mobileMoneyNumber}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                                    <FormControl style={{ width: '50%', marginBottom: '40px' }}>
                                        <Controls.Input
                                            label="Nom"
                                            name="mobileMoneyName"
                                            value={valuesOther.mobileMoneyName}
                                            onChange={handleInputChangeOther}
                                            error={errors.mobileMoneyName}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </>
                    )
                }
            </Grid>
        </form>
    )
}
