import React, { useState } from "react";
import PayrollService from '../service/Payroll.jsx';
import moment from 'moment';
import {Download} from "@mui/icons-material";
import { Button } from '@mui/material';
import { CircularProgress } from '@material-ui/core';


const PrintPaySlip = (props) => {
    const { idEmployee, searchDate, tab } = props

const [loading, setLoading] = useState(false);

const downloadPDF = () => {
    setLoading(true);
    PayrollService.generatePdfPaySlip(idEmployee, searchDate, tab).then(
        (res) => {
            const pdf64 = res.data.content;
            let a = document.createElement("a");
            a.href = "data:application/octet-stream;base64,"+pdf64;
            a.download = `${res.data.status === 1 ? 'bulletin-de-paie' : 'STC'}-${res.data.matricule}-${moment(res.data.date).format("MMMM-YYYY")}.pdf`
            a.click();
            setLoading(false);
        },
        () => {
            setLoading(false);
        }
    )
}

    return (
        <>
            <Button disabled={loading} onClick={downloadPDF}>
                { 
                    loading &&
                    <CircularProgress style={{ width: '30px', height: '30px', position: 'absolute', left: '5px' }} color="inherit" />
                }
                <Download />
                Télécharger
            </Button>
        </>
    );
}

export default PrintPaySlip;