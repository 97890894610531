import React from "react";
import { styled } from '@mui/material/styles';
import Header from "./Header";
import PersistentDrawerLeft from "./Sidebar";
import Box from '@mui/material/Box';
import PageContent from './Content.jsx';

const drawerWidth = 290;
export default function Layouts () {

  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      width: `calc(100% - ${drawerWidth}px)`,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    }),
  );

  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Header drawerWidth={drawerWidth} handleDrawerOpen={handleDrawerOpen} open={open} />
      <PersistentDrawerLeft handleDrawerClose={handleDrawerClose} open={open} />
      <Main sx={{ marginTop: '60px', height: 'calc(100vh - 60px)', padding: 0, background: 'rgb(245 245 245)' }} open={open}>
          <PageContent />
      </Main>
    </Box>
  )
}