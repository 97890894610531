// All components mapping with path for internal routes
import Dashboard from '../modules/dashboard/index.jsx';
import Employees from '../modules/employee/index.jsx';
import Holyday from '../modules/holyday/index.jsx';
import Abs from '../modules/abs/index.jsx';
import Advances from '../modules/advances/index.jsx';
import GainRetenu from '../modules/gainRetenu/index.jsx';
import Rubrique from '../modules/rubrique/index.jsx';
import Payroll from '../modules/payroll/index.jsx';
import Page404 from '../modules/404.jsx';
import Configuration from "../modules/config";
import Sanction from '../modules/sanction/index.jsx';
import ImportData from '../modules/importData/index.jsx';
import Pointage from '../modules/pointage/index.jsx';
import Roles from '../modules/users/Roles.jsx';
import Users from '../modules/users/Users.jsx';


const routes = [
  {
    path: '/dashboard', // the url
    component: Dashboard, // view rendered
  },
  {
    path: '/users', // the url
    component: Users, // view rendered
  },
  {
    path: '/users/roles', // the url
    component: Roles, // view rendered
  },
  {
    path: '/employees', // the url
    component: Employees, // view rendered
  },
  {
    path: '/sanctions', // the url
    component: Sanction, // view rendered
  },
  {
    path: '/payroll', // the url
    component: Payroll, // view rendered
  },
  {
    path: '/advances', // the url
    component: Advances, // view rendered
  },
  {
    path: '/gainRetenu', // the url
    component: GainRetenu, // view rendered
  },
  {
    path: '/rubrique', // the url
    component: Rubrique, // view rendered
  },
  {
    path: '/holyday', // the url
    component: Holyday, // view rendered
  },
  {
    path: '/absences', // the url
    component: Abs, // view rendered
  },
  {
    path: '/404',
    component: Page404,
  },
  {
    path: "/setting", // the url
    component: Configuration, // view rendered
  },
  {
    path: "/imports", // the url
    component: ImportData, // view rendered
  },
  {
    path: "/pointages", // the url
    component: Pointage, // view rendered
  },
]

export default routes
