import { Http } from '../../../applications/Http.js'

class pointageService {

    static async getAllPointage() {
        return await Http.get('/api/pointages/list');
    }
    static async generateExcel() {
        return await Http.get(`/api/pointages/export-excel-pointage`);
    }
    static async calculHs(payload) {
        return await Http.put(`/api/pointages/calcul-hs`, payload);
    }
}

export default pointageService
