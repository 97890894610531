import { makeStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    "& .MuiFormLabel-root": {
        fontWeight: 'bold',
        fontSize: '16px',
        color: 'black'
    }
  },
  headContent: {
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    '& h4': {
      marginLeft: '20px',
      margin: '0px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '1.25rem',
      lineHeight: '1.6',
      letterSpacing: '0.0075em',
    }
  },
  iconTitle: {
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
    borderRadius: "5px",
    padding: "20px",
  },
}

const useStyles = makeStyles(styles);

export default useStyles;