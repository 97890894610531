import { Http } from '../../../applications/Http.js'

class DashboardService {
    static async getAllEmployee() {
        return await Http.get('/api/employees/list');
    }
    static async getAllChild() {
        return await Http.get('/api/employees/child');
    }
}

export default DashboardService
