import { makeStyles } from '@material-ui/core/styles';

const styles = {
  listItemText: {
    width: 'calc(100% - 90px)',
    overflowX: 'auto',
    wordWrap: 'break-word',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
        width: '1px',
        height: '5px'
    },
    '&::-webkit-scrollbar-track': {
        background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#888', 
        borderRadius: '10px',
    }
  },
  listItemText2: {
      width: 'calc(100% - 175px)',
      overflowX: 'auto',
      wordWrap: 'break-word',
      whiteSpace: 'nowrap',
      '&::-webkit-scrollbar': {
          width: '1px',
          height: '5px'
      },
      '&::-webkit-scrollbar-track': {
          background: '#f1f1f1'
      },
      '&::-webkit-scrollbar-thumb': {
          background: '#888', 
          borderRadius: '10px',
      }
  },
  headContent: {
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    '& h4': {
      marginLeft: '20px',
      margin: '0px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '1.25rem',
      lineHeight: '1.6',
      letterSpacing: '0.0075em',
    },
    '& span': {
      marginLeft: '20px',
    }
  },
  iconTitle: {
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
    borderRadius: "5px",
    padding: "20px",
  },
}

const useStyles = makeStyles(styles);

export default useStyles;