import { Http } from '../../../applications/Http.js'

// class EmployeeService extends Service {
class HolydayService {

    static async getAllHolyday(filterDates) {
        return await Http.get(`/api/holyday/list`, filterDates);
    }
    // static async deleteEmployee(id) {
    //     return await Http.delete(`/api/employees/delete?id=${id}`);
    // }
    static async addHolyday(payload) {
        return await Http.put(`/api/holyday/add`, payload);
    }
    static async getAllEmployee() {
        return await Http.get('/api/employees/list');
    }
    static async generateExcel(payload) {
        return await Http.get(`/api/holyday/export-excel-conge`, payload);
    }
    static async generatePdf(id) {
        return await Http.get(`/api/holyday/export-pdf-conge?id=${id}`);
    }
}

export default HolydayService
