import { useEffect, useState } from "react";
import ErrorHandler from "../../applications/Error";
// import { useAuth } from "../../applications/hooks/UseAuth";
// import ToolbarButton from "../../components/DataTable/ToolbarButton";
// import PageHeader from "../../components/Typography/PageHeader";
// import AddNewConfig from "./components/AddNewConfig";
import Test from "./components/GlobalConfig";
import ConfigEmailService from "./services/globalCongif";
import { Toast } from "../../components/notificationComponent/index.jsx";
import useStyles from './style.jsx';
import { HiOutlineCog } from 'react-icons/hi';
import ButtonComponent from '../../components/Entry/Button.jsx';

export default function Index() {
  const service = ConfigEmailService.load();
  const classes = useStyles();
  const [globalData, setGlobalData] = useState(null);
  // const { haveAccessTo } = useAuth();
  // const [needLoad, setNeedLoad] = useState(true);
  // const useDrawer = false;

  // const CURRENT_PAGE = "administration_roles";

  async function valideEmailConfig() {
    try {
      const newConfig = globalData?.map((d) => {
        return {
          ...d,
          data: JSON.stringify(d.data),
        };
      });
      const res = await service.updateConfig(newConfig);
      if (res?.status?.code === 200) {
        Toast.success("Mise à jour effectuée avec succès.");
      } else {
        Toast.error("Une erreur s'est produite.");
      }
    } catch (err) {
      ErrorHandler.handle(err);
    }
  }
  useEffect(() => {
    const getAllConfigs = async () => {
      try {
        const configs = await service.getAllConfig();
        if (configs) {
          const newConfig = configs.data.map((d) => {
            return {
              ...d,
              data: JSON.parse(d.data),
            };
          });
          setGlobalData(newConfig);
        }
      } catch (e) {
        ErrorHandler.handle(e);
      }
    };
    getAllConfigs();
  }, [service]);

  return (
    <>
      <div className={"card w-full bg-base-100"} style={{ paddingBottom: 20 }}>
        <div style={{ padding: 20 }}>
          <div className={classes.headContent}>
              <div className={classes.iconTitle}>
                  <HiOutlineCog style={{ fontSize: '1.1rem' }} />
              </div>
              <div>
                  <h4>Configurations</h4>
                  <span>Gestion des configurations</span>
              </div>
          </div>
          <>
            <div className="flex flex-col gap-6">
              {globalData !== null &&
                globalData?.length > 0 &&
                globalData.map((d) => (
                  <Test key={d.id} data={d} setGlobalData={setGlobalData} />
                ))}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <ButtonComponent style={{textTransform: 'none'}} text="Annuler" variant="contained"/>
              <ButtonComponent style={{marginLeft: '20px', textTransform: 'none'}} text="Valider" variant="contained" onClick={valideEmailConfig}/>
            </div>
          </>
        </div>
      </div>
    </>
  );
}
