import React, { useContext } from 'react';
import { Button, Grid } from '@mui/material';
import Controls from '../../../components/Entry/Controls';
// import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { MdDeleteOutline } from 'react-icons/md';
import { AddEmployeeContext } from './index.jsx';
import FormControl from '@material-ui/core/FormControl';

const AddChildrenForm = (props) => {
    const { values, setValues, index, handleDeleteChildren } = props

    const handleInputChange = (e, index) => {
        const { name, value } = e.target
        values[index][name] = value
        setValues([...values])
    }

    const childGenreItems = [
        { id: 'garcon', title: 'Garçon' },
        { id: 'fille', title: 'Fille' }
    ]

    return (
        <Grid sx={{ width: '100%', marginBottom: '20px', justifyContent: 'space-between', alignItems: 'center' }} container>
            <FormControl style={{ width: '40%' }}>
                <Controls.Input
                    name="childrenName"
                    label="Nom/Prénom"
                    value={values[index].childrenName}
                    onChange={(e) => {
                        handleInputChange(e, index)
                    }}
                />
            </FormControl>
            <FormControl style={{ width: '25%' , marginTop: '-8px' }}>
                <Controls.DatePicker
                    name="childrenDob"
                    label="Date de naissance"
                    value={values[index].childrenDob}
                    onChange={(e) => {
                        handleInputChange(e, index)
                    }}
                />
            </FormControl>
            <FormControl style={{ width: '25%' }}>
                <Controls.Select
                    name="childrenSexe"
                    label="Genre"
                    value={values[index].childrenSexe}
                    onChange={(e) => {
                        handleInputChange(e, index)
                    }}
                    options={childGenreItems}
                />
            </FormControl>
            <FormControl style={{ width: '5%' }}>
                <Button onClick={() => handleDeleteChildren(index)} color="error">
                    <MdDeleteOutline style={{ width: '2em', height: '2em' }} />
                </Button>
            </FormControl>
        </Grid>
    )
}

export default function Children(props) {
    const { valuesChild, setValuesChild } = useContext(AddEmployeeContext)
    const handleAddChildren = (e) => {
        const oldValues = [...valuesChild]
        const key = oldValues.length
        oldValues.push({ key: key+1, childrenName: '', childrenDob: null, childrenSexe: '' })
        setValuesChild(oldValues)
    }

    const handleDeleteChildren = (e, key) => {
        const oldValues = [...valuesChild]
        const updateValues = oldValues.filter((item) => item.key !== key)
        setValuesChild(updateValues)
    }

    //handle load
    // useEffect(() => {
    //     !addEmployeeData.children
    //         ? setAddEmployeeData({
    //               ...addEmployeeData,
    //               children: values
    //           })
    //         : setValues(addEmployeeData.children)
    // }, [])

    //handle click to next
    // useEffect(() => {
    //     setAddEmployeeData({
    //         ...addEmployeeData,
    //         children: values.filter((item) => item.childrenName !== '')
    //     })
    // }, [clickBack, clickNext, values])

    return (
        <form>
            <Grid container>
                <Grid container style={{ marginBottom: '20px' }}>
                    <Button
                        sx={{textTransform: 'none'}}
                        variant="contained"
                        onClick={handleAddChildren}
                        size={"small"}
                    >
                        Ajouter un enfant
                    </Button>
                </Grid>
                {valuesChild.map((child, index) => {
                    return (
                        <AddChildrenForm
                            key={index}
                            index={index}
                            values={valuesChild}
                            setValues={setValuesChild}
                            handleDeleteChildren={(e) => handleDeleteChildren(e, child.key)}
                        />
                    )
                })}
            </Grid>
        </form>
    )
}
