import React from "react";
import {
  DataGrid,
  GridToolbar,
} from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import { useDemoData } from '@mui/x-data-grid-generator';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';

const styles = {
  dataGrid: {
    minHeight: '500px !important',
    position: 'relative !important',
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgba(0, 0, 0, 0.87) !important",
    padding: "20px",
    boxShadow: "rgb(210, 210, 210) 0px 5px 5px -3px, rgb(210, 210, 210) 0px 8px 10px 1px, rgb(210, 210, 210) 0px 3px 14px 2px"
  },
}
const useStyles = makeStyles(styles);
const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '100px',
  position: 'absolute',
  '& .no-rows-primary': {
    fill: theme.palette.mode === 'light' ? '#AEB8C2' : '#3D4751',
  },
  '& .no-rows-secondary': {
    fill: theme.palette.mode === 'light' ? '#E8EAED' : '#1D2126',
  },
}));  

function Table (props) {
  const { columns, rows, columnGroupingModel, isSelection = false, setSelectedRows } = props
  const { dataGridData } = useDemoData({
    dataSet: 'Mat.',
    rowLength: 10,
    maxColumns: 6,
  });
  const classes = useStyles();

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width={96}
            viewBox="0 0 452 257"
            aria-hidden
            focusable="false"
          >
            <path
              className="no-rows-primary"
              d="M348 69c-46.392 0-84 37.608-84 84s37.608 84 84 84 84-37.608 84-84-37.608-84-84-84Zm-104 84c0-57.438 46.562-104 104-104s104 46.562 104 104-46.562 104-104 104-104-46.562-104-104Z"
            />
            <path
              className="no-rows-primary"
              d="M308.929 113.929c3.905-3.905 10.237-3.905 14.142 0l63.64 63.64c3.905 3.905 3.905 10.236 0 14.142-3.906 3.905-10.237 3.905-14.142 0l-63.64-63.64c-3.905-3.905-3.905-10.237 0-14.142Z"
            />
            <path
              className="no-rows-primary"
              d="M308.929 191.711c-3.905-3.906-3.905-10.237 0-14.142l63.64-63.64c3.905-3.905 10.236-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142l-63.64 63.64c-3.905 3.905-10.237 3.905-14.142 0Z"
            />
            <path
              className="no-rows-secondary"
              d="M0 10C0 4.477 4.477 0 10 0h380c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 20 0 15.523 0 10ZM0 59c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 69 0 64.523 0 59ZM0 106c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 153c0-5.523 4.477-10 10-10h195.5c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 200c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 247c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10Z"
            />
          </svg>
          <Box style={{ width: '210px' }} sx={{ mt: 2 }}>Aucune donnée n'est disponible.</Box>
      </StyledGridOverlay>
    );
  }

  
  return (
    <DataGrid
      checkboxSelection={isSelection}
      className={classes.dataGrid + ' customGridTable'}
      localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
      columns={columns}
      rows={rows}
      onRowSelectionModelChange={(ids) => {
        const selectedIDs = new Set(ids);
        const selectedRows = rows.filter((row) =>
          selectedIDs.has(row.id),
        );
        setSelectedRows(selectedRows);
      }}
      slots={{
        toolbar: GridToolbar,
        noRowsOverlay: CustomNoRowsOverlay
      }}
      columnGroupingModel={columnGroupingModel}
      // onRowSelectionModelChange={(ids) => {
      //   const selectedIDs = new Set(ids);
      //   const selectedRows = rows.filter((row) =>
      //     selectedIDs.has(row.id),
      //   );
      //   setSelectedRows(selectedRows);
      // }}
      initialState={{
        pagination: { paginationModel: { pageSize: 10 } },
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          printOptions: { disableToolbarButton: true },
          csvOptions: { disableToolbarButton: true },
        },
      }}
      pageSizeOptions={[10, 25, 50, 100]}
      {...dataGridData}
    />
  )
}

export default Table