import React from 'react';
import { FormControl, FormLabel, RadioGroup as MuiRadioGroup, FormControlLabel, Radio, FormHelperText } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiFormLabel-root": {
            fontWeight: 'bold',
            fontSize: '16px',
            color: 'black'
        },
        "& .MuiFormHelperText-root": {
            position: 'absolute',
            fontSize: '0.75rem',
            bottom: '-20px',
            marginLeft: '0'
        }
    }
  }));

export default function RadioGroup(props) {
    const classes = useStyles();
    const { name, label, value, error = null, onChange, items } = props

    return (
        <FormControl className={classes.root} {...(error && { error: true })} variant='outlined'>
            <FormLabel>{label}</FormLabel>
            <MuiRadioGroup row name={name} value={value} onChange={onChange}>
                {items.map((item) => (
                    <FormControlLabel key={item.id} value={item.id} control={<Radio />} label={item.title} />
                ))}
            </MuiRadioGroup>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}
