import React from "react";
import useStyles from './style.jsx';
import { Modal } from '@material-ui/core';
import { AiFillCloseCircle } from "react-icons/ai";
import ButtonComponent from '../../components/Entry/Button.jsx';

function ModalComponent (props) {
  const classes = useStyles();
  const { openModal, close, title, submit, actionTitle, modalContentBody, loading } = props
  
  return (
    <Modal open={openModal} onClose={close}>
      <div className={classes.modalContent} >
        <div className={classes.modalHeader} >
          <h4>{title}</h4>
          <AiFillCloseCircle
            className={classes.btnClose}
            onClick={() => { close() }}
          />
        </div>
        <div className={classes.modalBody}>
          {modalContentBody}
        </div>
        <div className={classes.modalFooter}>
          <ButtonComponent
            loading={loading}
            style={{ margin: '0 20px', width: '100%', textTransform: 'none'}}
            text={actionTitle}
            variant="contained"
            onClick={submit}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ModalComponent;