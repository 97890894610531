import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ProfileInfo from './ProfileInfo.jsx';
import ContratInfo from './ContratInfo.jsx';
// import DocumentList from './DocumentList.jsx';
import Register from './Register';
import EmployeeService from '../services/Employee.jsx';
import { TbUserExclamation } from 'react-icons/tb';
import { HiOutlineDocumentText } from 'react-icons/hi2';
// import { HiOutlineClipboardDocument } from 'react-icons/hi2';
import { HiOutlineQueueList } from "react-icons/hi2";
import useStyles from './style.jsx';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

SingleEmployee.propTypes = {
  employeeNumber: PropTypes.any,
  handleBack: PropTypes.any,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function SingleEmployee(props) {
  const { idEmployee } = props;
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const [employee, setEmployee] = useState({});

  const getEmployee = async () => {
    const res = await EmployeeService.getSingleEmploye(idEmployee);
    if (res?.status?.code === 200) {
      setEmployee(res?.data);
    }
  };

  useEffect(() => {
    getEmployee();
    // eslint-disable-next-line
  }, []);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Tabs
            value={tab}
            onChange={handleChange}
            centered
            aria-label="icon position tabs"
            style={{
              width: '86%',
            }}
          >
            <Tab
              className={classes.title + ' iconSize'}
              icon={<TbUserExclamation />}
              iconPosition="start"
              label="Informations personnelles"
              {...a11yProps(0)}
            />
            <Tab
              className={classes.title + ' iconSize'}
              icon={<HiOutlineDocumentText />}
              iconPosition="start"
              label="Contrat et Rémuneration"
              {...a11yProps(1)}
            />
            {/* <Tab
              className={classes.title + ' iconSize'}
              icon={<HiOutlineClipboardDocument />}
              iconPosition="start"
              label="Documents associés"
              {...a11yProps(2)}
            /> */}
            <Tab
              className={classes.title + ' iconSize'}
              icon={<HiOutlineQueueList />}
              iconPosition="start"
              label="Historique"
              {...a11yProps(2)}
            />

          </Tabs>

        </Box>
        <Box>
          <TabPanel value={tab} index={0}>
            <ProfileInfo employee={employee} setEmployee={setEmployee} />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <ContratInfo employee={employee} />
          </TabPanel>
          {/* <TabPanel value={tab} index={2}>
            <DocumentList employee={employee} />
          </TabPanel> */}
          <TabPanel value={tab} index={2}>
            <Register employee={employee} />
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}

export default SingleEmployee;
