import { makeStyles } from '@material-ui/core/styles';

const styles = {
  title: {
    margin: '0px !important',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important',
    fontWeight: '500 !important',
    fontSize: '1.25rem !important',
    lineHeight: '1.6 !important',
    letterSpacing: '0.0075em !important',
    textTransform: 'none !important'
  },
}

const useStyles = makeStyles(styles);

export default useStyles;