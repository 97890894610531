import React from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
      "& .MuiFormLabel-root": {
          fontWeight: 'bold',
          fontSize: '16px',
          color: 'black'
      },
      "& .MuiFormHelperText-root": {
        position: 'absolute',
        fontSize: '0.75rem',
        bottom: '-20px',
        marginLeft: '0'
      }
    }
  }));
export default function Textarea(props) {
    const { name, label, value,error=null, onChange, ...other } = props;
    const classes = useStyles();

    return (
        <TextField
            className={classes.root}
            multiline
            rows={5}
            variant="outlined"
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            {...other}
            {...(error && {error:true,helperText:error})}
        />
    )
}