import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Button, Modal } from '@mui/material';
import PayrollService from '../service/Payroll.jsx';
import PrintPaySlip from './PrintPaySlip.jsx';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const styles = {
  headerPaye: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px',
  },
  modalPayeContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'auto',
    height: '100%',
    paddingTop: 0,
  },
  modalPayeContentChild: {
    width: '21cm',
    backgroundColor: 'white',
    padding: 20,
    paddingTop: 0,
  },
  titlePayeContent: {
    justifyContent: 'center',
    fontSize: 28,
    fontWeight: 'bold',
    display: 'flex',
    marginBottom: '20px',
  },
  filtreContent: {
    display: 'flex',
    flexDirection: 'column',
  },
};

const useStyles = makeStyles(styles);

function EmployeePayslip(props) {

  const {searchDate, tab, idEmployee, open, onClose} = props
  const [date, setDate] = useState(new Date());
  const classes = useStyles();

  const [employeePayslip, setEmployeePayslip] = useState([]);
  const [employeeInfo, setEmployeeInfo] = useState(null);
  
  function renderList(item, valueStyle, i, keyStyle) {
    return (
      <div
        key={item.id + " " + i}
        style={{ display: 'flex', padding: 10, alignItems: 'flex-end' }}
      >
        {item.valeur !== 'fixe' &&
            (item.divider === 1 ? (
              <div style={{ display: 'flex', flex: 0.5 }}>{item?.variableName} (heures)</div>
            ) : (
              <div style={{ display: 'flex', flex: 0.5 }}>{item?.variableName} (jours)</div>
            ))}
        {item.valeur === 'fixe' &&
          (<div style={{ display: 'flex', flex: 0.5 }}>{item?.variableName}</div>)
        }
        <div
          style={{
            display: 'flex',
            flex: 0.5,
          }}
        >
          {item.valeur !== 'fixe' &&
            <div style={{ display: 'flex', flex: 0.5, ...keyStyle }}>
              {item?.jours}
            </div>
          }
          <div style={{ display: 'flex', flex: item.valeur !== 'fixe' ? 0.5 : 1, ...valueStyle }}>
            {item?.amount.replace(/\d(?=(\d{3})+\.)/g, '$& ')}
          </div>
        </div>
      </div>
    );
  }

  const generateBlock = (data) => {
    const valueStyle = {
      backgroundColor: '#56766730',
      borderRadius: 5,
      padding: 7,
      height: 22,
    };
    const keyStyle = {
      borderWidth: 1,
      borderColor: '#567667',
      borderStyle: 'solid',
      borderRadius: 5,
      padding: 7,
      height: 22,
    };
    if (data) {
      // eslint-disable-next-line
      return data.map((k, index) => {
        if (k?.value === 'header') {
          return (
            <div
              key={'y-' + index}
              style={{ display: 'flex', padding: 10, alignItems: 'flex-end' }}
            >
              <div style={{ display: 'flex', flex: 0.5 }}>
                {
                  <b>
                    <u>{k?.label}</u>
                  </b>
                }
              </div>
            </div>
          );
        } else if (k?.value === 'empty') {
          return null
        } else if (k?.value === 'gain' || k?.value === 'retenue') {
          const items = k?.list;
          if (items.length > 0) {
            let Html = [];
            // eslint-disable-next-line
            items.map((item, i) => {
            Html.push(renderList(item, valueStyle, i, keyStyle));
          })
            return <>{Html}</>;
          }
        } else {
          if (
            k?.type &&
            (k?.type === 'highlight' || k?.type === 'highlight2')
          ) {
            const fontSize = k?.type === 'highlight' ? 18 : 23;
            return (
              <div
                key={'y-' + index}
                style={{
                  fontSize: fontSize,
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  display: 'flex',
                  padding: 20,
                  alignItems: 'flex-end',
                }}
              >
                <div style={{ display: 'flex', flex: 0.5 }}>{k?.label}</div>
                <div
                  style={{
                    display: 'flex',
                    flex: 0.5,
                  }}
                >
                  {k?.value}
                </div>
              </div>
            );
          } else {
            return (
              <div
                key={'y-' + index}
                style={{ display: 'flex', padding: 10, alignItems: 'center' }}
              >
                <div style={{ display: 'flex', flex: 0.5 }}>{k?.label}</div>
                <div
                  style={{
                    display: 'flex',
                    flex: 0.5,
                  }}
                >
                  {typeof k?.value !== 'object' ? (
                    <div style={{ display: 'flex', flex: 1, ...valueStyle }}>
                      {typeof k?.value === 'number'
                        ? k?.value
                            ?.toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$& ')
                        : k?.value}
                    </div>
                  ) : (
                    k?.value && (
                      <>
                        {/* <div style={{display: 'flex', flex: 1}}> */}
                        <div
                          style={{ display: 'flex', flex: 0.5, ...keyStyle }}
                        >
                          {typeof k?.value?.value1 === 'number'
                            ? k?.value?.value1
                                ?.toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$& ')
                            : k?.value?.value1}
                        </div>
                        <div
                          style={{ display: 'flex', flex: 0.5, ...valueStyle }}
                        >
                          {typeof k?.value?.value2 === 'number'
                            ? k?.value?.value2
                                ?.toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$& ')
                            : k?.value?.value2}
                        </div>
                        {/* </div> */}
                      </>
                    )
                  )}
                </div>
              </div>
            );
          }
        }
      });
    }
    return null;
  };

  const getEmployeePayslipData = async () => {
    const response = await PayrollService.getPayroll(idEmployee, searchDate, tab);
    if(response?.status?.code === 200) {
        setEmployeeInfo(response.data.employee);
        setEmployeePayslip(response.data.payslip);
        setDate(response.data.date);
    }
  }

  useEffect(() => {
      getEmployeePayslipData();
      // eslint-disable-next-line
  }, []);

  return (
    <>
      <Modal open={open} style={{ paddingTop: 0, marginTop: 0 }}>
        <div className={classes.modalPayeContent}>
          <div className={classes.modalPayeContentChild}>
            <div className={classes.headerPaye}>
              <PrintPaySlip
                idEmployee={idEmployee}
                searchDate={searchDate}
                tab={tab}
              />
              <Button onClick={onClose}>Fermer</Button>
            </div>
            <div className={classes.titlePayeContent}>
              {
                (employeeInfo?.EmployeeSocietyInfo[0]?.status &&
                employeeInfo?.EmployeeSocietyInfo[0]?.status === 1) ? 
                `Bulletin de paie (${moment(date).format('MMM YYYY')})` :
                `STC (${moment(date).format('MMM YYYY')})`
              }
            </div>
            <div style={{ display: 'block' }}>
              <div
                style={{ padding: 10, display: 'flex', alignItems: 'flex-end' }}
              >
                <div style={{ display: 'flex', flex: 0.5 }}>
                  <b>
                    <u>FICHE DU PERSONNEL</u>
                  </b>
                </div>
              </div>
              <div
                style={{ padding: 10, display: 'flex', alignItems: 'flex-end' }}
              >
                <div style={{ display: 'flex', flex: 0.5 }}>Employé</div>
                <div style={{ display: 'flex', flex: 0.5 }}>
                  {employeeInfo ? (employeeInfo.lastName +
                    ' ' +
                    employeeInfo.firstName) : ''}
                </div>
              </div>
              <div
                style={{ padding: 10, display: 'flex', alignItems: 'flex-end' }}
              >
                <div style={{ display: 'flex', flex: 0.5 }}>Matricule</div>
                <div style={{ display: 'flex', flex: 0.5 }}>
                  {employeeInfo ? employeeInfo.matricule : ''}
                </div>
              </div>
              <div
                style={{ padding: 10, display: 'flex', alignItems: 'flex-end' }}
              >
                <div style={{ display: 'flex', flex: 0.5 }}>Fonction</div>
                <div style={{ display: 'flex', flex: 0.5 }}>
                  {employeeInfo ? employeeInfo.EmployeeContract[0].jobTitle : ''}
                </div>
              </div>
              <div
                style={{ padding: 10, display: 'flex', alignItems: 'flex-end' }}
              >
                <div style={{ display: 'flex', flex: 0.5 }}>Catégorie</div>
                <div style={{ display: 'flex', flex: 0.5 }}>
                  {employeeInfo ? employeeInfo.EmployeeContract[0].category : ''}
                </div>
              </div>
              <div
                style={{ padding: 10, display: 'flex', alignItems: 'flex-end' }}
              >
                <div style={{ display: 'flex', flex: 0.5 }}>Type du contrat</div>
                <div style={{ display: 'flex', flex: 0.5 }}>
                  {employeeInfo ? employeeInfo.EmployeeContract[0].contractType.toUpperCase() : ''}
                </div>
              </div>
            </div>
            <div style={{ display: 'block' }}>
              {generateBlock(employeePayslip)}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
              }}
            ></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

// EmployeePayslip.propTypes = {
//   children: PropTypes.any,
//   employeeData: PropTypes.any,
//   open: PropTypes.bool,
//   onClose: PropTypes.func,
// };

export default EmployeePayslip;
