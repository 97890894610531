
import React from "react";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { toast } from "sonner";

const style = {
  style: {
    padding: '10px',
  },
}
export class Toast {
  static success(message) {
    return toast.success(message, style);
  }

  static error(message) {
    return toast.error(message, style);
  }
  static info(message) {
    return toast.info(message, style);
  }
}
export default function Notification(props) {
  const { message, openNotif, handleClose, severity } = props;
  // const { duration } = props;
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={openNotif}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}