import { Http } from '../../../applications/Http.js';
import Service from '../../../applications/Service.js';

class RoleService extends Service {
    static async getAllRole() {
        return await Http.get('/api/users/roles/list');
    }

    static async setPermission(id, params) {
        return await Http.post(`/api/users/roles/permission?id=${id}`, params);
    }

    static async getPermission(id) {
        return await Http.get(`/api/users/roles/permission?id=${id}`);
    }

    static async getSingleRole(id) {
        return await Http.get(`/api/users/roles/single-role?id=${id}`);
    }

    static async addRole(payload) {
        return await Http.put(`/api/users/roles/add`, payload);
    }

    static async editRole(id, payload) {
        return await Http.put(`/api/users/roles/edit?id=${id}`, payload);
    }

    static async deleteRole(id) {
        return await Http.delete(`/api/users/roles/delete?id=${id}`);
    }
}

export default RoleService;