import React, { useState, useEffect } from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core';
import { useAuth } from '../../../applications/hooks/UseAuth';
import ModalComponent from '../../../components/modalComponent/index.jsx';
import Controls from '../../../components/Entry/Controls.jsx';
import { Button, Menu, MenuItem, Fade } from '@mui/material';
import { AiFillFile } from "react-icons/ai";
import Tooltips from '../../../components/tooltips/index.jsx';
import { makeStyles } from '@material-ui/core/styles';
import EmployeeService from '../services/Employee';
import { Toast } from '../../../components/notificationComponent/index.jsx';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardBody from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import { Typography } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
// import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Zoom from '@material-ui/core/Zoom';
import SvgAdd from '../../../components/Entry/svgAdd.jsx';
import FormControl from '@material-ui/core/FormControl';
import { HiOutlineClipboardDocument } from 'react-icons/hi2';
import Avatar from '@mui/material/Avatar';

const CURRENT_PAGE = 'employee';

const styles = {
  contentImagePdf: {
    border: '1px solid rgba(0, 0, 0, 0.3)',
    height: '100%',
    '& img': {
      height: '100% !important',
      width: '100% !important',
      objectFit: 'cover',
    },
  },
  uploadFileContent: {
    height: '200px',
    width: '200px',
    '& div': {
      height: '100% !important',
      width: '100% !important',
      fontSize: '20px',
      fontWeight: 'bold',
      position: 'relative'
    },
  },
  contentImagePdfEmpty: {
    alignItems: 'center',
    border: '2px dashed rgba(0, 0, 0, .3)',
    display: 'flex',
    fontSize: '2rem',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  selectfile: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  error: {
    position: 'absolute',
    top: '40px',
    color: '#d32f2f',
    fontWeight: '500',
    fontSize: '14px',
  },
  iconAddDoc: {
    color: 'white !important',
    '&:hover': {
      opacity: 0.5,
    },
  },
  docContent: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  contentPdfImagelist: {
    height: '140px',
    borderBottom: '1px solid #E0DCDC'
  },
  docTitle: {
    '& h4': {
      maxWidth: '112px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      margin: 0,
      lineHeight: 'normal',
      fontSize: '16px'
    },
  },
  btnDownload: {
    marginRight: '10px !important',
    marginLeft: '2px !important',
    background: '#FFFF !important',
    padding: '0px !important',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.87) !important',
      background: 'none !important',
    },
    '& svg': {
      fontSize: '20px'
    }
  },
  btnDelete: {
    background: '#FFFF !important',
    padding: '0px !important',
    '&:hover': {
      color: 'red !important',
      background: 'none !important',
    },
    '& svg': {
      fontSize: '20px'
    }
  },
  SeeMore: {
    marginLeft: 'auto !important',
    padding: '0 !important',
    '& svg': {
      fontSize: '20px'
    } 
  },
};

const useStyles = makeStyles(styles);

function ModalContentBody(props) {
    const classes = useStyles();
    const { data, setData, setFile, isSubmitted } = props
    const onChangeFile = async (e) => {
        const files = e.target.files[0];
        if(files.type === 'application/pdf') {
        setFile(files);
        setData({ ...data, extension: URL.createObjectURL(files), type: 'data:application' });
        } else {
        setFile(files);
        setData({ ...data, extension: URL.createObjectURL(files), type: 'data:image' });
        }
    };

    const checkTitleEmpty = () => {
        if(isSubmitted && !data.title) {
            return "Champ obligatoire."
        }
    }

    const checkTitleFile = () => {
        if(isSubmitted && !data.extension) {
            return "Champ obligatoire."
        }
    }

    const handleChangeTitle = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }

    return (
        <>
            <form>
                <FormControl style={{ width: '100%', marginBottom: '10px', position: 'relative' }}>
                    <Controls.Input
                        name="title"
                        label="Titre"
                        value={data.title}
                        onChange={handleChangeTitle}
                        error={checkTitleEmpty()}
                    />
                </FormControl>
                <div className={classes.selectfile} >
                    <Button component="label" style={{ textTransform: 'none' }}>
                        <AiFillFile style={{ marginRight: '5px', fontSize: '25px' }} />
                        Sélectionner un fichier (.pdf,.jpg,.png)
                        <input hidden type="file" accept=".pdf,.jpg,.png" onChange={onChangeFile} />
                    </Button>
                    <div className={classes.uploadFileContent}>
                        {data.extension ? (
                            <div className={classes.contentImagePdf}>
                            {data.type === 'data:application' ? (
                                <Viewer fileUrl={data.extension} />
                            ) : (
                                <img src={data.extension} alt="" />
                            )}
                            </div>
                        ) : (
                            <div className={classes.contentImagePdfEmpty}>
                                <span className={classes.error}>{checkTitleFile()}</span>
                                Contenu vide
                            </div>
                        )}
                    </div>
                </div>
            </form>
        </>
    )
}


function DocumentList(props) {
    const { employee, setEmployee } = props
    const classes = useStyles();
    const { haveAccessTo } = useAuth();
    const initialDataValue = {
        title: '',
        extension: '',
        type: ''
    }
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(initialDataValue);
    const [file, setFile] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [deletedId, setDeletedId] = useState(null);
    const [permission, setPermission] = useState('');
    const [listDocument, setListDocument] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [imagePdp, setImagePdp] = useState(null);
    const openMenu = Boolean(anchorEl);
    const url = process.env.REACT_APP_BASE_URL;
    
    const openModalAdd = () => {
        if(haveAccessTo(CURRENT_PAGE, 'addDocument')) {
            setOpen(true);
        } else {
            setPermission('Vous n\'avez pas la permission d\'ajouter un document');
        }
    };

    useEffect(() => {
        setListDocument(employee.EmployeeDocument);
        // eslint-disable-next-line
    }, []);

    const closeModal = () => {
        setOpen(false);
        setData(initialDataValue);
        setFile(null);
        setIsSubmitted(false);
        // setDataDeleted(initialDataValue);
    };

    const saveDoc = async () => {
        setIsSubmitted(true);
        if(data.title && data.extension) {
            const payload = {
                employeeId: employee.id,
                title: data.title,
              };
            const response = await EmployeeService.addDocument(payload, file);
            if(response?.status?.code === 200) {
                setListDocument([...listDocument, response.data]);
                closeModal();
                Toast.success("Document ajouter avec succès.");
            }
        }
    }

    const handleDeleteDoc = (data) => {
        setDeletedId(data.id);
        setOpenDeleteConfirm(true);
    }

    const closeDeleteConfirm = () => {
        setDeletedId(null);
        setOpenDeleteConfirm(false);
    }
    
    const confirmDelete = async () => {
        const response = await EmployeeService.deleteDocument(deletedId);
        if(response?.status?.code === 200) {
            setListDocument(listDocument.filter((data) => data.id !== deletedId));
            closeDeleteConfirm();
            Toast.success("Document supprimer avec succès.");
        }
    }

    const changePDP = async () => {
        const response = await EmployeeService.updatePdp(employee.id, imagePdp);
        if(response?.status?.code === 200) {
            Toast.success(response?.status?.message);
            setEmployee({...employee, photo: response?.data?.photo });
            setImagePdp(null);
            setAnchorEl(null);
        }
    }

    const handleClick = (event, urlImage) => {
        setAnchorEl(event.currentTarget);
        setImagePdp(urlImage);
    };
    
    const handleCloseMenu = () => {
        setImagePdp(null);
        setAnchorEl(null);
      };

    return (
        <>
            <Card style={{ width: '100%', marginRight: '20px' }}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe">
                            <HiOutlineClipboardDocument size={15} style={{ marginRight: '5px' }} />
                        </Avatar>
                    }
                    action={
                        <Tooltips TransitionComponent={Zoom} title={!haveAccessTo(CURRENT_PAGE, 'addDocument') ? permission : "Ajouter un document"}>
                            <Button onClick={openModalAdd}>
                                <SvgAdd />
                            </Button>
                        </Tooltips>
                    }
                    title="Documents associés"
                />
                <CardBody style={{ paddingTop: '0' }}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        {/* <Card style={{ width: '100%', display: 'flex', marginTop: '30px', flexDirection: 'column' }}>
                            <CardHeader
                                title="Liste des documents associés à cette personne"
                            />
                            <CardBody style={{ paddingTop: '25px' }}> */}
                                <div className={classes.docContent}>
                                    {listDocument.map((item) => (
                                        <Card
                                            key={item.id}
                                            sx={{
                                                maxWidth: 145,
                                                minWidth: 145,
                                                position: 'relative',
                                                margin: '10px',
                                            }}
                                        >
                                            {item.type === 'data:application' ? (
                                                <CardMedia
                                                    className={classes.contentPdfImagelist}
                                                    alt="green iguana"
                                                >
                                                    <Viewer fileUrl={url + item.url} />
                                                </CardMedia>
                                                ) : (
                                                    <CardMedia
                                                    className={classes.contentPdfImagelist}
                                                    component="img"
                                                    height="140"
                                                    image={url + item.url}
                                                    alt="green iguana"
                                                    />
                                                )
                                            }
                                        <CardBody>
                                            <Typography
                                            className={classes.docTitle}
                                            gutterBottom
                                            component="div"
                                            >
                                            <h4>{item.title}</h4>
                                            </Typography>
                                        </CardBody>
                                        <CardActions>
                                            <a href={url + item.url} rel="noreferrer" target="_blank" download={item.title}>
                                                <IconButton
                                                    className={classes.btnDownload}
                                                >
                                                    <ZoomOutMapIcon />
                                                </IconButton>
                                            </a>
                                            <IconButton
                                                onClick={() => {
                                                    handleDeleteDoc(item);
                                                }}
                                                className={classes.btnDelete}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                            {item.type === 'data:image' &&
                                                (
                                                    <>
                                                        <IconButton
                                                            onClick={(event) => handleClick(event, item.url)}
                                                            className={classes.SeeMore}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <Menu
                                                            id="fade-menu"
                                                            MenuListProps={{
                                                                'aria-labelledby': 'fade-button',
                                                            }}
                                                            anchorEl={anchorEl}
                                                            open={openMenu}
                                                            onClose={handleCloseMenu}
                                                            TransitionComponent={Fade}
                                                        >
                                                            <MenuItem>
                                                                <Button
                                                                component="label"
                                                                onClick={changePDP}
                                                            >
                                                                Définir comme photo de profile
                                                                </Button>
                                                            </MenuItem>
                                                        </Menu>
                                                    </>
                                                )
                                            }
                                        </CardActions>
                                        </Card>
                                    ))}
                                </div>
                            {/* </CardBody>
                        </Card> */}
                    </Worker>
                </CardBody>
            </Card>
            <ModalComponent
                openModal={openDeleteConfirm}
                close={() => closeDeleteConfirm()}
                title={'Suppression'}
                submit={() => confirmDelete()}
                actionTitle={'Confirmer'}
                modalContentBody={'Est-ce que vous voulez vraiment supprimer cet employé?'}
            />
            <ModalComponent
                openModal={open}
                close={() => closeModal()}
                title={'Ajout'}
                submit={() => saveDoc()}
                actionTitle={'Enregistrer'}
                modalContentBody={<ModalContentBody data={data} setData={setData} setFile={setFile} isSubmitted={isSubmitted} />}
            />
        </>
    )
}

export default DocumentList;