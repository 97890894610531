import React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/fr';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
      "& .MuiFormLabel-root": {
          fontWeight: 'bold',
          fontSize: '16px',
          color: 'black'
      }
    }
  }));

export default function DatePickerInput(props) {
    const { name, label, value, onChange, view, formatPes } = props
    const classes = useStyles();
    const convertToDefEventPara = (name, value) => ({
        target: {
            name,
            value
        }
    })

    return (
        <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
                <DatePicker
                    className={classes.root}
                    sx={{ width: '100%', background: "#FFFF" }}
                    views={view || ['day', 'month', 'year']} 
                    label={label}
                    value={dayjs(value)}
                    format={formatPes || "DD/MM/YYYY"}
                    name={name}
                    onChange={(date) => onChange(convertToDefEventPara(name, date))}
                />
            </DemoContainer>
        </LocalizationProvider>
    )
}
