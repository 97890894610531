import React from 'react';

export default function SvgBadge() {
    return (
        <svg fill="rgb(25, 118, 210)" height="30px" width="30px" version="1.1" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" >
            <path fillRule='evenodd' d="M10.014,5l-6.012,0c-0.796,0 -1.559,0.316 -2.122,0.879c-0.562,0.562 -0.878,1.325 -0.878,2.121c-0,3.832 -0,12.168 -0,16c-0,0.796 0.316,1.559 0.878,2.121c0.563,0.563 1.326,0.879 2.122,0.879c5.153,0 18.842,0 23.995,0c0.796,0 1.559,-0.316 2.121,-0.879c0.563,-0.562 0.879,-1.325 0.879,-2.121c0,-3.832 0,-12.168 0,-16c0,-0.796 -0.316,-1.559 -0.879,-2.121c-0.562,-0.563 -1.325,-0.879 -2.121,-0.879l-6.012,-0c-0,-0.796 -0.316,-1.559 -0.879,-2.121c-0.563,-0.563 -1.326,-0.879 -2.121,-0.879c-1.736,0 -4.236,0 -5.971,0c-0.796,0 -1.559,0.316 -2.121,0.879c-0.563,0.562 -0.879,1.325 -0.879,2.121Zm11.799,2c-0.148,0.418 -0.387,0.802 -0.707,1.121c-0.563,0.563 -1.326,0.879 -2.121,0.879c-1.736,0 -4.236,0 -5.971,0c-0.796,-0 -1.559,-0.316 -2.121,-0.879c-0.32,-0.319 -0.56,-0.703 -0.708,-1.121l-6.183,0c-0.265,0 -0.52,0.105 -0.707,0.293c-0.188,0.187 -0.293,0.442 -0.293,0.707l-0,16c-0,0.265 0.105,0.52 0.293,0.707c0.187,0.188 0.442,0.293 0.707,0.293c5.153,0 18.842,0 23.995,0c0.265,0 0.52,-0.105 0.707,-0.293c0.188,-0.187 0.293,-0.442 0.293,-0.707l0,-16c0,-0.265 -0.105,-0.52 -0.293,-0.707c-0.187,-0.188 -0.442,-0.293 -0.707,-0.293l-6.184,-0Zm-1.828,-2l-0,1c-0,0.265 -0.106,0.52 -0.293,0.707c-0.188,0.188 -0.442,0.293 -0.707,0.293c-0,-0 -5.971,0 -5.971,0c-0.265,-0 -0.52,-0.105 -0.707,-0.293c-0.188,-0.187 -0.293,-0.442 -0.293,-0.707l-0,-1c-0,-0.265 0.105,-0.52 0.293,-0.707c0.187,-0.188 0.442,-0.293 0.707,-0.293c-0,0 5.971,-0 5.971,-0c0.265,0 0.519,0.105 0.707,0.293c0.187,0.187 0.293,0.442 0.293,0.707Z"/>
            <path fill="rgb(25, 118, 210)" d="M9.079,16.313c-2.368,0.799 -4.077,3.043 -4.081,5.686c-0.001,0.551 0.447,1 0.999,1.001c0.552,0.001 1,-0.447 1.001,-0.999c0.003,-2.208 1.791,-4.001 3.993,-4.001c2.203,-0 3.99,1.793 3.993,4.001c0.001,0.552 0.45,1 1.002,0.999c0.552,-0.001 0.999,-0.45 0.998,-1.001c-0.004,-2.631 -1.697,-4.866 -4.049,-5.675c0.659,-0.551 1.079,-1.378 1.079,-2.303c-0,-1.656 -1.344,-3 -3,-3c-1.656,-0 -3,1.344 -3,3c-0,0.918 0.414,1.741 1.065,2.292Zm1.935,-3.292c0.552,-0 1,0.448 1,1c-0,0.552 -0.448,1 -1,1c-0.552,-0 -1,-0.448 -1,-1c-0,-0.552 0.448,-1 1,-1Z"/>
            <path fill="rgb(25, 118, 210)" d="M26,11l-5.994,0c-0.552,0 -1,0.448 -1,1c0,0.552 0.448,1 1,1l5.994,0c0.552,0 1,-0.448 1,-1c0,-0.552 -0.448,-1 -1,-1Z"/>
            <path fill="rgb(25, 118, 210)" d="M26,16l-5.994,-0c-0.552,0 -1,0.448 -1,1c0,0.552 0.448,1 1,1l5.994,-0c0.552,0 1,-0.448 1,-1c0,-0.552 -0.448,-1 -1,-1Z"/>
            <path fill="rgb(25, 118, 210)" d="M26,21l-5.994,-0c-0.552,0 -1,0.448 -1,1c0,0.552 0.448,1 1,1l5.994,-0c0.552,0 1,-0.448 1,-1c-0,-0.552 -0.448,-1 -1,-1Z"/>
            <g id="Icon"/>
        </svg>
    )
}