import React, { createContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Check from '@mui/icons-material/Check';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import ChildCareOutlinedIcon from '@mui/icons-material/ChildCareOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LastPageOutlinedIcon from '@mui/icons-material/LastPageOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Employee from './Employee.jsx';
import Address from './Address.jsx';
import Children from './Children.jsx';
import Contract from './Contract.jsx';
import Other from './Other.jsx';
import Recap from './Recap.jsx';
import EmployeeService from '../services/Employee';
import { Grid } from '@mui/material';
import { Toast } from "../../../components/notificationComponent/index.jsx";

const MuiPaper = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    marginTop: '20px'
}))

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4'
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor'
    }
}))

function QontoStepIcon(props) {
    const { active, completed, className } = props

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? <Check className="QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
        </QontoStepIconRoot>
    )
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: theme.palette.primary.main
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: theme.palette.primary.main
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1
    }
}))

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: theme.palette.primary.main,
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
    }),
    ...(ownerState.completed && {
        backgroundColor: theme.palette.primary.main
    })
}))

function ColorlibStepIcon(props) {
    const { active, completed, className } = props

    const icons = {
        1: <Person2OutlinedIcon />,
        2: <AddLocationAltOutlinedIcon />,
        3: <ChildCareOutlinedIcon />,
        4: <AssignmentOutlinedIcon />,
        5: <InfoOutlinedIcon />
    }

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    )
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node
}

const steps = ['Fiche employé', 'Adresse', 'Enfant(s)', 'Contrats et Rémunérations']

const initialDataValues = []

const initialFValuesProfil = {
    matricule: '',
    photo: '',
    civility: '',
    firstName: '',
    lastName: '',
    fatherName: '',
    motherName: '',
    sexe: '',
    nationality: '',
    dateOfBirth: null,
    placeOfBirth: '',
    cin: '',
    cinDate: null,
    cinPlace: '',
    passeport: '',
    passeportDate: null,
    maritalStatus: '',
    conjointName: '',
    conjointDob: null,
    phoneNumber: '',
    urgencyContact: false,
    urgencePhoneNumber: '',
    urgencePersonName: '',
    relation: ''
}

const initialFValuesAddress = {
    address: '',
    city: '',
    country: '',
    postalCode: ''
}

const initialFValuesContract = {
    jobTitle: '',
    contractType: '',
    contractStartDate: null,
    contractEndDate: null,
    contractStatus: 1,
    department: '',
    service: '',
    category: '',
    group: '',
    baseSalary: '',
    hoursWeek: '',
    status: ''
}

const initialFValuesOther = {
    // conges: 0,
    contractSocietyStartDate: null,
    contractSocietyEndDate: null,
    motifDepart: '',
    status: '',
    cnaps: '',
    paymentMethod: '',
    domiciliation: '',
    bankCode: '',
    agencyCode: '',
    accountNumber: '',
    key: '',
    mobileMoney: '',
    mobileMoneyNumber: '',
    mobileMoneyName: ''
}

function contractCategoryForm(group) {
    switch (group) {
        case 'I':
          return [
            {id: 'M1', title: 'M1' },
            {id: 'M2', title: 'M2' },
            {id: '1A', title: '1A' },
            {id: '1B', title: '1B' },
          ]
        case 'II':
          return [
            { id: 'OS1', title: 'OS1' },
            { id: 'OS2', title: 'OS2' },
            { id: 'OS3', title: 'OS3' },
            { id: 'OP1', title: 'OP1' },
            { id: '2A', title: '2A' },
            { id: '2B', title: '2B' },
            { id: '3A', title: '3A' },
            { id: '3B', title: '3B' },
            { id: 'A1', title: 'A1' },
            { id: 'A2', title: 'A2' },
            { id: 'A3', title: 'A3' },
            { id: 'B1', title: 'B1' },
            { id: 'B2', title: 'B2' },
            { id: 'B3', title: 'B3' },
            { id: 'B4', title: 'B4' },
            { id: 'C1', title: 'C1' },
            { id: 'C2', title: 'C2' },
            { id: 'C3', title: 'C3' },
            { id: 'D1', title: 'D1' },
            { id: 'D2', title: 'D2' },
            { id: 'D3', title: 'D3' },
          ] 
        case 'III' :
          return [
            { id: 'OP2', title: 'OP2' },
            { id: 'OP3', title: 'OP3' },
            { id: '4A', title: '4A' },
            { id: '4B', title: '4B' },
            { id: '5A', title: '5A' },
            { id: '5B', title: '5B' },
            { id: 'A4', title: 'A4' },
            { id: 'B5', title: 'B5' },
            { id: 'C4', title: 'C4' },
            { id: 'D4', title: 'D4' },
          ]  
        case 'IV':
          return [
            { id: 'HC1', title: 'HC1' },
            { id: 'HC2', title: 'HC2' },
            { id: 'HC3', title: 'HC3' },
            { id: 'HC4', title: 'HC4' },
            { id: 'HC5', title: 'HC5' },
          ]
        case 'V':
          return [
            { id: 'HC6', title: 'HC6' },
            { id: 'HC7', title: 'HC7' },
          ]
        default: 
        return [];
        }
}

function regionForm(ville) {
    switch (ville) {
        case 'Antananarivo':
            return [
                {id: 1, value: 'Analamanga', title: 'Analamanga' },
                {id: 2, value: 'Avaradrano', title: 'Avaradrano' },
            ]
        case 'Fianarantsoa':
            return [
                {id: 1, value: 'Haute Mahatsiatra', title: 'Haute Mahatsiatra' },
            ]
        case 'Toamasina':
            return [
                {id: 1, value: 'Vakinankaratra', title: 'Vakinankaratra' },
            ]
        default: 
        return [];
        }
}

function postalCodeForm(ville) {
    switch (ville) {
        case 'Antananarivo':
            return [
                {id: 1, value: '101', title: '101' },
                {id: 2, value: '102', title: '102' },
                {id: 3, value: '104', title: '104' },
                {id: 4, value: '105', title: '105' },
                {id: 5, value: '116', title: '116' },
            ]
        case 'Fianarantsoa':
            return [
                {id: 1, value: '301', title: '301' },
                {id: 2, value: '303', title: '303' },
                {id: 3, value: '304', title: '304' },
                {id: 4, value: '305', title: '305' },
                {id: 5, value: '313', title: '313' },
            ]
        case 'Toamasina':
            return [
                {id: 1, value: '503', title: '503' },
                {id: 2, value: '508', title: '508' },
                {id: 3, value: '504', title: '504' },
                {id: 4, value: '505', title: '505' },
                {id: 5, value: '509', title: '509' },
                {id: 6, value: '502', title: '502' },
            ]
        default: 
        return [];
        }
}

export const AddEmployeeContext = createContext();

export default function AddEditEmployee(props) {
    const { toggleDrawer, getListEmploye, idEmployee } = props
    const [activeStep, setActiveStep] = React.useState(0)
    const [skipped, setSkipped] = React.useState(new Set())

    const [addEmployeeData, setAddEmployeeData] = React.useState(initialDataValues)
    const [clickNext, setClickNext] = React.useState(false)
    const [clickBack, setClickBack] = React.useState(false)
    const [clickSkip, setClickSkip] = React.useState(false)

    const [valuesProfil, setValuesProfil] = React.useState(initialFValuesProfil)
    const [valuesAddress, setValuesAddress] = React.useState(initialFValuesAddress)
    const [valuesChild, setValuesChild] = React.useState([])
    const [valuesContract, setValuesContract] = React.useState(initialFValuesContract)
    const [valuesOther, setValuesOther] = React.useState(initialFValuesOther)

    const [file, setFile] =  React.useState(null);
    const [previewPhoto, setPreviewPhoto] = React.useState(null);
    const url = process.env.REACT_APP_BASE_URL;

    const [arrayCategory, setArrayCategory] = React.useState([]);
    const [arrayRegion, setArrayRegion] = React.useState([]);
    const [arrayPostalCode, setArrayPostalCode] = React.useState([]);

    const [fileCin, setFileCin] = React.useState(null);
    const [filePass, setFilePass] = React.useState(null);

    useEffect(() => {
        setAddEmployeeData(initialDataValues);
        async function getSingleEmployee() {
            const response = await EmployeeService.getSingleEmploye(idEmployee);
            if(response?.status?.code === 200) {
                const defaultDataEmployee = response.data;
                    initialFValuesProfil.matricule = defaultDataEmployee.matricule
                    initialFValuesProfil.civility = defaultDataEmployee.civility
                    initialFValuesProfil.firstName = defaultDataEmployee.firstName
                    initialFValuesProfil.lastName = defaultDataEmployee.lastName
                    initialFValuesProfil.fatherName = defaultDataEmployee.fatherName
                    initialFValuesProfil.motherName = defaultDataEmployee.motherName
                    initialFValuesProfil.sexe = defaultDataEmployee.sexe
                    initialFValuesProfil.nationality = defaultDataEmployee.nationality
                    initialFValuesProfil.dateOfBirth = defaultDataEmployee.dateOfBirth
                    initialFValuesProfil.placeOfBirth = defaultDataEmployee.placeOfBirth
                    initialFValuesProfil.cin = defaultDataEmployee.cin
                    initialFValuesProfil.cinDate = defaultDataEmployee.cinDate
                    initialFValuesProfil.cinPlace = defaultDataEmployee.cinPlace
                    initialFValuesProfil.passeport = defaultDataEmployee.passeport
                    initialFValuesProfil.passeportDate = defaultDataEmployee.passeportDate
                    initialFValuesProfil.maritalStatus = defaultDataEmployee.maritalStatus
                    initialFValuesProfil.conjointName = defaultDataEmployee.conjointName
                    initialFValuesProfil.conjointDob = defaultDataEmployee.conjointDob
                    initialFValuesProfil.phoneNumber = defaultDataEmployee.phoneNumber
                    initialFValuesProfil.urgencyContact = defaultDataEmployee.urgencePhoneNumber ? true :false
                    initialFValuesProfil.urgencePhoneNumber = defaultDataEmployee.urgencePhoneNumber
                    initialFValuesProfil.urgencePersonName = defaultDataEmployee.urgencePersonName
                    initialFValuesProfil.relation = defaultDataEmployee.relation

                const defaultDataAddresses = response.data.EmployeeAddress[0];
                    setArrayRegion(regionForm(defaultDataAddresses.city));
                    setArrayPostalCode(postalCodeForm(defaultDataAddresses.city));
                    initialFValuesAddress.address = defaultDataAddresses.address
                    initialFValuesAddress.city = defaultDataAddresses.city
                    initialFValuesAddress.country = defaultDataAddresses.country
                    initialFValuesAddress.postalCode = defaultDataAddresses.postalCode

                const newRowChild = []
                response?.data?.EmployeeChildren.forEach(el => {
                    newRowChild.push({
                        key: el.id,
                        childrenDob: el.childrenDob,
                        childrenName: el.childrenName,
                        childrenSexe: el.childrenSexe,
                    });
                });
                setValuesChild(newRowChild)

                const defaultDataContracts = response.data.EmployeeContract[0];
                    setArrayCategory(contractCategoryForm(defaultDataContracts.group));
                    initialFValuesContract.jobTitle = defaultDataContracts.jobTitle
                    initialFValuesContract.contractType = defaultDataContracts.contractType
                    initialFValuesContract.contractStartDate = defaultDataContracts.contractStartDate
                    initialFValuesContract.contractEndDate = defaultDataContracts.contractEndDate
                    initialFValuesContract.contractStatus = defaultDataContracts.contractStatus
                    initialFValuesContract.department = defaultDataContracts.department
                    initialFValuesContract.service = defaultDataContracts.service
                    initialFValuesContract.category = defaultDataContracts.category
                    initialFValuesContract.group = defaultDataContracts.group
                    initialFValuesContract.baseSalary = defaultDataContracts.baseSalary
                    initialFValuesContract.hoursWeek = defaultDataContracts.hoursWeek
                    
                const defaultDataOther = response.data.EmployeeSocietyInfo[0];
                    initialFValuesContract.status = defaultDataOther.status
                const defaultDataBank = response.data.EmployeeSocietyInfo[0].EmployeeBank[0];
                const defaultDataMobileMoney = response.data.EmployeeSocietyInfo[0].EmployeeMobileMoney[0];
                    // initialFValuesOther.conges = defaultDataOther.conges
                    initialFValuesOther.contractSocietyStartDate = defaultDataOther.contractSocietyStartDate
                    initialFValuesOther.contractSocietyEndDate = defaultDataOther.contractSocietyEndDate
                    initialFValuesOther.motifDepart = defaultDataOther.motifDepart
                    initialFValuesOther.cnaps = defaultDataOther.cnaps
                    initialFValuesOther.paymentMethod = defaultDataOther.paymentMethod
                    initialFValuesOther.domiciliation = defaultDataOther.paymentMethod === 2 ? defaultDataBank.domiciliation : ''
                    initialFValuesOther.bankCode = defaultDataOther.paymentMethod === 2 ? defaultDataBank.bankCode : ''
                    initialFValuesOther.agencyCode = defaultDataOther.paymentMethod === 2 ? defaultDataBank.agencyCode : ''
                    initialFValuesOther.accountNumber = defaultDataOther.paymentMethod === 2 ? defaultDataBank.accountNumber : ''
                    initialFValuesOther.key = defaultDataOther.paymentMethod === 2 ? defaultDataBank.key : ''
                    initialFValuesOther.mobileMoney = defaultDataOther.paymentMethod === 4 ? defaultDataMobileMoney.mobileMoney : ''
                    initialFValuesOther.mobileMoneyNumber = defaultDataOther.paymentMethod === 4 ? defaultDataMobileMoney.mobileMoneyNumber : ''
                    initialFValuesOther.mobileMoneyName = defaultDataOther.paymentMethod === 4 ? defaultDataMobileMoney.mobileMoneyName : ''
                
                if (defaultDataEmployee.photo) {
                    setPreviewPhoto(url+defaultDataEmployee.photo);
                }
            }
        }
        if(idEmployee) {
            getSingleEmployee();
        } else {
            initialFValuesProfil.matricule = ""
            initialFValuesProfil.civility = ""
            initialFValuesProfil.firstName = ""
            initialFValuesProfil.lastName = ""
            initialFValuesProfil.fatherName = ""
            initialFValuesProfil.motherName = ""
            initialFValuesProfil.sexe = ""
            initialFValuesProfil.nationality = ""
            initialFValuesProfil.dateOfBirth = null
            initialFValuesProfil.placeOfBirth = ""
            initialFValuesProfil.cin = ""
            initialFValuesProfil.cinDate = null
            initialFValuesProfil.cinPlace = ""
            initialFValuesProfil.passeport = ""
            initialFValuesProfil.passeportDate = null
            initialFValuesProfil.maritalStatus = ""
            initialFValuesProfil.conjointName = ""
            initialFValuesProfil.conjointDob = null
            initialFValuesProfil.phoneNumber = ""
            initialFValuesProfil.urgencyContact = false
            initialFValuesProfil.urgencePhoneNumber = ""
            initialFValuesProfil.urgencePersonName = ""
            initialFValuesProfil.relation = ""
            initialFValuesAddress.address = ""
            initialFValuesAddress.city = ""
            initialFValuesAddress.country = ""
            initialFValuesAddress.postalCode = ""
            setValuesChild([])
            initialFValuesContract.jobTitle = ""
            initialFValuesContract.contractType = ""
            initialFValuesContract.contractStartDate = null
            initialFValuesContract.contractEndDate = null
            initialFValuesContract.contractStatus = 1
            initialFValuesContract.department = ""
            initialFValuesContract.service = ""
            initialFValuesContract.category = ""
            initialFValuesContract.group = ""
            initialFValuesContract.baseSalary = ""
            initialFValuesContract.hoursWeek = ""
            initialFValuesContract.status = ""
            // initialFValuesOther.conges = 0
            initialFValuesOther.contractSocietyStartDate = null
            initialFValuesOther.contractSocietyEndDate = null
            initialFValuesOther.motifDepart = ""
            initialFValuesOther.cnaps = ""
            initialFValuesOther.paymentMethod = ""
            initialFValuesOther.domiciliation = ""
            initialFValuesOther.bankCode = ""
            initialFValuesOther.agencyCode = ""
            initialFValuesOther.accountNumber = ""
            initialFValuesOther.key = ""
            initialFValuesOther.mobileMoney = ""
            initialFValuesOther.mobileMoneyNumber = ""
            initialFValuesOther.mobileMoneyName = ""
            setPreviewPhoto(null);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setArrayCategory(contractCategoryForm(valuesContract.group));
        // eslint-disable-next-line
    }, [valuesContract.group]);

    useEffect(() => {
        setArrayRegion(regionForm(valuesAddress.city));
        setArrayPostalCode(postalCodeForm(valuesAddress.city));
        // eslint-disable-next-line
    }, [valuesAddress.city]);

    const [errors, setErrors] = React.useState({})

    const setDataProfil = () => {
        setAddEmployeeData({...addEmployeeData, profil: valuesProfil})
    }

    const setDataAddress = () => {
        setAddEmployeeData({...addEmployeeData, address: valuesAddress})
    }

    const setDataChild = () => {
        setAddEmployeeData({
            ...addEmployeeData,
            children: valuesChild.filter((item) => item.childrenName !== '')
        })
    }

    const setDataContract = () => {
        setAddEmployeeData({...addEmployeeData, contract: valuesContract, other: valuesOther})
    }

    // const setDataOther = () => {
    //     setAddEmployeeData({...addEmployeeData, other: valuesOther})
    // }

    const isStepOptional = (step) => {
        return false //step === 1 || step === 2
    }

    const isStepSkipped = (step) => {
        return skipped.has(step)
    }

    const handleNext = () => {
        setClickNext(true)
        if (Object.keys(errors).length === 0) {
            if (activeStep === 0) {
                setDataProfil();
            }
            if (activeStep === 1) {
                setDataAddress();
            }
            if (activeStep === 2) {
                setDataChild();
            }
            if (activeStep === 3) {
                setDataContract();
            }
            // if (activeStep === 4) {
            //     setDataOther();
            // }
            let newSkipped = skipped
            if (isStepSkipped(activeStep)) {
                newSkipped = new Set(newSkipped.values())
                newSkipped.delete(activeStep)
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1)
            setSkipped(newSkipped)
        } else {
            if(errors.iban) {
                Toast.error("Le RIB est incorrect.");
            } else {
                Toast.error("Veuillez remplir tous les champs obligatoires.");
            }
        }
    }

    const handleBack = () => {
        setClickBack(true)
        setErrors({})
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.")
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values())
            newSkipped.add(activeStep)
            return newSkipped
        })
        setClickSkip(true)
    }

    const handleSave = async () => {
        if(!idEmployee) {
            const response = await EmployeeService.addEmployee(addEmployeeData, file, fileCin, filePass)
            if (response?.status?.code === 200) {
                Toast.success("L'employé a été ajouté avec succès.");
                toggleDrawer();
                getListEmploye();
            } else {
                Toast.error(response.status.message);
            }
        } else {
            const response = await EmployeeService.updateEmployee(idEmployee, addEmployeeData, file, fileCin, filePass)
            if (response?.status?.code === 200) {
                Toast.success("L'employé a été modifié avec succès.");
                toggleDrawer();
                getListEmploye();
            } else {
                Toast.error(response.status.message);
            }
        }
    }

    return (
        <>
            <AddEmployeeContext.Provider
                value={{
                    errors,
                    setErrors,
                    addEmployeeData,
                    setAddEmployeeData,
                    clickNext,
                    clickBack,
                    clickSkip,
                    setValuesProfil,
                    valuesProfil,
                    setValuesAddress,
                    valuesAddress,
                    setValuesChild,
                    valuesChild,
                    setValuesContract,
                    valuesContract,
                    setValuesOther,
                    valuesOther,
                    setFile,
                    setFileCin,
                    fileCin,
                    setFilePass,
                    filePass,
                    previewPhoto,
                    setPreviewPhoto,
                    idEmployee,
                    arrayCategory,
                    setArrayCategory,
                    arrayRegion,
                    arrayPostalCode,
                    contractCategoryForm
                }}
            >
                <Box sx={{ width: '100%' }}>
                    <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                        {steps.map((label, index) => {
                            const stepProps = {}
                            const labelProps = {}
                            if (isStepOptional(index)) {
                                labelProps.optional = <Typography variant="caption">Optionnel</Typography>
                            }
                            if (isStepSkipped(index)) {
                                stepProps.completed = false
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon} {...labelProps}>
                                        {label}
                                    </StepLabel>
                                </Step>
                            )
                        })}
                    </Stepper>

                    <Grid style={{ paddingTop: 30 }}>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Box sx={{ flex: '1 1 auto' }}>
                                        <Button sx={{ padding: '8px 20px 8px 10px'}} color="primary" variant="contained" onClick={handleBack}>
                                            <KeyboardArrowLeftOutlinedIcon />
                                            PRECEDENT
                                        </Button>
                                    </Box>
                                    <Button sx={{ padding: '8px 10px 8px 20px'}} color="primary" variant="contained" onClick={handleSave}>
                                        <SaveAltOutlinedIcon />
                                        ENREGISTRER
                                    </Button>
                                </Box>
                                <MuiPaper elevation={3} sx={{ p: 3 }}>
                                    <Box>
                                        <Recap />
                                    </Box>
                                </MuiPaper>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Box sx={{ flex: '1 1 auto' }}>
                                        <Button sx={{ padding: '8px 20px 8px 10px'}} color="primary" variant="contained" onClick={handleBack}>
                                            <KeyboardArrowLeftOutlinedIcon />
                                            PRECEDENT
                                        </Button>
                                    </Box>
                                    <Button sx={{ padding: '8px 10px 8px 20px'}} color="primary" variant="contained" onClick={handleSave}>
                                        <SaveAltOutlinedIcon />
                                        ENREGISTRER
                                    </Button>
                                </Box>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button
                                        sx={{ padding: '8px 20px 8px 10px'}}
                                        color="primary"
                                        variant="contained"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                    >
                                        <KeyboardArrowLeftOutlinedIcon />
                                        PRECEDENT
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    {isStepOptional(activeStep) && (
                                        <Button color="inherit" variant="contained" onClick={handleSkip}>
                                            PASSER
                                            <LastPageOutlinedIcon />
                                        </Button>
                                    )}

                                    <Button sx={{ padding: '8px 10px 8px 20px'}} color="primary" variant="contained" onClick={handleNext}>
                                        {activeStep === steps.length - 1 ? 'SUIVANT' : 'SUIVANT'}
                                        <KeyboardArrowRightOutlinedIcon />
                                    </Button>
                                </Box>
                                <MuiPaper elevation={3} sx={{ p: 3 }}>
                                    <Box>
                                        {activeStep === 0 && <Employee />}
                                        {activeStep === 1 && <Address />}
                                        {activeStep === 2 && <Children />}
                                        {activeStep === 3 && <Contract />}
                                        {activeStep === 4 && <Other />}
                                    </Box>
                                </MuiPaper>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button
                                        sx={{ padding: '8px 20px 8px 10px'}}
                                        color="primary"
                                        variant="contained"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                    >
                                        <KeyboardArrowLeftOutlinedIcon />
                                        PRECEDENT
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    {isStepOptional(activeStep) && (
                                        <Button color="inherit" variant="contained" onClick={handleSkip}>
                                            PASSER
                                            <LastPageOutlinedIcon />
                                        </Button>
                                    )}

                                    <Button sx={{ padding: '8px 10px 8px 20px'}} color="primary" variant="contained" onClick={handleNext}>
                                        {activeStep === steps.length - 1 ? 'SUIVANT' : 'SUIVANT'}
                                        <KeyboardArrowRightOutlinedIcon />
                                    </Button>
                                </Box>
                            </React.Fragment>
                        )}
                    </Grid>
                </Box>
            </AddEmployeeContext.Provider>
        </>
    )
}
