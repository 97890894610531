import { Http } from '../applications/Http.js'

export default class LayoutsService {
  static async getListNotification() {
    return await Http.get('/api/notification/list');
  }
  static async updateNotification(payload) {
    return await Http.put(`/api/notification/update-status-notification?id=${payload.id}`);
  }
}
