import React from "react";
import Login from './modules/users/Login.jsx';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layouts from './layouts/index.jsx';
import Page404 from './modules/404.jsx';
import initializeApp from './applications/Init';
import RequireAuth from './layouts/RequireAuth';
import LoginGuard from './layouts/LoginGuard';
import { Toaster } from "sonner";

initializeApp()

function App() {
  return (
    <div className="App">
      <Toaster position="top-right" expand={true} richColors closeButton />
      <Router>
        <Routes>
            <Route element={<LoginGuard />}>
              <Route path="/login" element={<Login />} />
            </Route>
            <Route element={<RequireAuth />}>
              <Route path="/app/*" element={<Layouts />} />
            </Route>
            <Route path="*" element={<Page404 />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
