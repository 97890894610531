import {
    HiOutlineViewGrid,
    HiOutlineQuestionMarkCircle,
    HiOutlineCog
} from 'react-icons/hi'
import { BiNotepad } from "react-icons/bi"
import { GiNotebook, GiStabbedNote, GiBookCover, GiTakeMyMoney } from "react-icons/gi"
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import { GrDocumentConfig } from "react-icons/gr"
import { TbDatabaseImport } from "react-icons/tb"
import { FaFingerprint } from "react-icons/fa"
import SvgSanction from "../components/Entry/svgSanction.jsx"
import SvgPay from "../components/Entry/svgPay.jsx"

export const DASHBOARD_SIDEBAR_LINKS = [
    {
        key: 'dashboard',
        label: 'Tableau de Bord',
        path: '/app/dashboard',
        icon: <HiOutlineViewGrid />,
        permissions: ['show']
    },
    {
        key: 'employee',
        label: 'Gestion des Employés',
        path: '/app/employees',
        icon: <WorkOutlineOutlinedIcon style={{ fontSize: '1.1rem' }} />,
        permissions: ['show', 'see', 'add', 'edit', 'delete', 'save', 'export']
    },
    {
        key: 'pointage',
        label: 'Gestion des Pointage',
        path: '/app/pointages',
        icon: <FaFingerprint style={{ fontSize: '1.1rem' }} />,
        permissions: ['show', 'export']
    },
    {
        key: 'congesabsence',
        label: 'Congés & Absences',
        path: '',
        icon: <BiNotepad />,
        submenu: [
            {
                key: 'holyday',
                label: 'Congés',
                path: '/app/holyday',
                icon: <GiNotebook style={{ fontSize: '1.1rem' }} />,
                permissions: ['show', 'add', 'edit', 'delete', 'save', 'export']
            },
            {
                key: 'absences',
                label: 'Absences',
                path: '/app/absences',
                icon: <GiStabbedNote style={{ fontSize: '1.1rem' }} />,
                permissions: ['show', 'add', 'edit', 'delete', 'save', 'export']
            }
        ]
    },
    // {
    //     key: 'sanctions',
    //     label: 'Gestions des Sanctions',
    //     path: '/app/sanctions',
    //     icon: <WorkOutlineOutlinedIcon style={{ fontSize: '1.1rem' }} />,
    //     permissions: ['show', 'add', 'edit', 'delete', 'save', 'export']
    // },
    {
        key: 'pay',
        label: 'Paie',
        path: '',
        icon: <SvgPay />,
        submenu: [
            {
                key: 'payroll',
                label: 'Livre de paie',
                path: '/app/payroll',
                icon: <GiBookCover style={{ fontSize: '1.1rem' }} />,
                permissions: ['show', 'see', 'add', 'edit', 'delete', 'save', 'export']
            },
            {
                key: 'advances',
                label: 'Avances sur salaire',
                path: '/app/advances',
                icon: <GiTakeMyMoney style={{ fontSize: '1.1rem' }} />,
                permissions: ['show', 'add', 'edit', 'delete', 'save', 'export']
            },
            {
                key: 'gainRetenu',
                label: 'Entrée des rubriques',
                path: '/app/gainRetenu',
                icon: <AccountBalanceWalletIcon style={{ fontSize: '1.1rem' }} />,
                permissions: ['show', 'add', 'delete', 'save', 'export', 'print'],
            },
        ]
    },
    {
        key: 'sanction',
        label: 'Gestion des Sanctions',
        path: '/app/sanctions',
        icon: <SvgSanction style={{ fontSize: '1.1rem' }} />,
        permissions: ['show', 'add', 'edit', 'delete', 'save', 'export']
    },
    // {
    //     key: 'statistics',
    //     label: 'Statistiques et Rapports',
    //     path: '/app/statistics',
    //     icon: <WorkOutlineOutlinedIcon style={{ fontSize: '1.1rem' }} />,
    //     permissions: ['show', 'add', 'edit', 'delete', 'save', 'export']
    // },
    {
        key: 'administration',
        label: 'Administration',
        path: '',
        icon: <HiOutlineCog />,
        submenu: [
            {
                key: 'rubrique',
                path: '/app/rubrique',
                icon: <GrDocumentConfig style={{ fontSize: '1.1rem' }} />,
                label: 'Gestion des rubriques',
                permissions: ['show', 'add', 'delete', 'save', 'export', 'print']
            },
            {
                key: 'users',
                path: '/app/users',
                icon: <ManageAccountsOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Utilisateurs',
                permissions: ['show', 'add', 'edit', 'delete', 'save', 'export']
            },
            {
                key: 'roles',
                path: '/app/users/roles',
                icon: <LockPersonOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Rôles',
                permissions: ['show', 'add', 'edit', 'delete', 'permission']
            },
            {
                key: 'configuration',
                path: '/app/setting',
                icon: <HiOutlineCog style={{ fontSize: '1.1rem' }} />,
                label: 'Configuration',
                permissions: ['show', 'add', 'delete', 'save']
            },
            {
                key: 'imports',
                path: '/app/imports',
                icon: <TbDatabaseImport style={{ fontSize: '1.1rem' }} />,
                label: 'importation de donnée',
                permissions: ['show', 'import']
            },
        ]
    }
]

export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
    {
        key: 'settings',
        label: 'Settings',
        path: '/app/settings',
        icon: <HiOutlineCog />
    },
    {
        key: 'support',
        label: 'Help & Support',
        path: '/app/support',
        icon: <HiOutlineQuestionMarkCircle />
    }
]

export const PERMISSIONS_LABEL = {
    show: 'Affichage',
    add: 'Création',
    edit: 'Modification',
    delete: 'Suppression',
    save: 'Enregistrement',
    import: 'Importation',
    export: 'Exportation',
    permission: 'Permissions',
    see: 'Consultation',
}
