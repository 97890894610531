import React, { useEffect, useState } from 'react';
import AdvancesService from './service/Advances.jsx';
import Table from '../../components/table/index.jsx';
import moment from 'moment';
import Button from '@mui/material/Button';
import ModalComponent from '../../components/modalComponent/index.jsx';
import { useAuth } from '../../applications/hooks/UseAuth';
import { Toast } from "../../components/notificationComponent/index.jsx";
import Controls from '../../components/Entry/Controls.jsx';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '../../components/Autocomplete';
import TextField from '@mui/material/TextField';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { Chip } from '@mui/material';
import Tooltips from '../../components/tooltips/index.jsx';
import { MdDeleteOutline } from 'react-icons/md';
import { FaRegEdit } from 'react-icons/fa';
import Zoom from '@material-ui/core/Zoom';
import useStyles from './style.jsx';
import ButtonComponent from '../../components/Entry/Button.jsx';
import { GiTakeMyMoney } from "react-icons/gi";

const CURRENT_PAGE = 'advances';

function ModalContentBody(props) {
    const classes = useStyles();
    const {editId, valuesAdvance, setValuesAdvance, isSubmitted, options, fullName, setFullName} = props;

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setValuesAdvance({
            ...valuesAdvance,
            [name]: value
        })
    }

    const checkEmployeeNumberEmptyAdvance = () => {
        if (isSubmitted && !valuesAdvance.employeeId) {
          return 'Le champ matricule est requis.'
        }
    }
    
    const checkAdvanceInsertDateEmpty = () => {
        if (isSubmitted && !valuesAdvance.advanceInsertDate) {
            return 'Le champ date est requis.'
        }
    }

    const checkAdvanceStartDateEmpty = () => {
        if (isSubmitted && valuesAdvance.advanceType !== 'Quinzaine' && !valuesAdvance.advanceStartDate) {
            return 'Le champ date de début est requis.'
        }
    }
    
    const checkSommeValide = () => {
        if (isSubmitted && !valuesAdvance.somme) {
            return 'Le champ somme est requis.'
        }
        if (isSubmitted && valuesAdvance.somme && valuesAdvance.somme < 1) {
            return 'La somme doit être suppérieur à zéro'
        }
    }

    const onTagsChangeAdvance = (event, values) => {
        if (values !== null) {
            setValuesAdvance({ ...valuesAdvance, employeeId: values.id });
            setFullName({ ...fullName, lastName: values.name });
        } else {
            setValuesAdvance({ ...valuesAdvance, employeeId: null });
            setFullName('');
        }
    }

    useEffect(() => {
        if(editId) {
            AdvancesService.getSingleAdvances(editId).then((res) => {
                if(res?.status?.code === 200) {
                    const dataEdit = {
                        advanceInsertDate: res.data.advanceInsertDate,
                        advanceStartDate: res.data.advanceStartDate,
                        advanceType: res.data.advanceType,
                        employeeId: res.data.employeeId,
                        numberMonthly: res.data.numberMonthly,
                        somme: res.data.somme,
                    }
                    setValuesAdvance(dataEdit)
                }
            });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <form>
            <FormControl style={{ width: '100%', marginBottom: '10px', position: 'relative' }}>
                <Autocomplete
                    label={editId !== null ? ("N° Matricule : ") : "N° Matricule"}
                    editMat={editId}
                    onTagsChange={onTagsChangeAdvance}
                    options={options}
                    lastName={fullName.lastName}
                />
                <span style={{ position: 'absolute', fontSize: '0.75rem', color: '#d32f2f', bottom: '-5px' }}>{checkEmployeeNumberEmptyAdvance()}</span>
            </FormControl>
            <FormControl style={{ width: '100%', marginBottom: '30px' }}>
                <Controls.DatePicker
                    name="advanceInsertDate"
                    label="Date"
                    value={valuesAdvance.advanceInsertDate}
                    onChange={handleInputChange}
                    error={checkAdvanceInsertDateEmpty()}
                />
                <span style={{ position: 'absolute', fontSize: '0.75rem', color: '#d32f2f', bottom: '-15px' }}>{checkAdvanceInsertDateEmpty()}</span>
            </FormControl>
            <FormControl style={{ width: '100%', marginBottom: '30px' }}>
                <TextField
                    className={classes.root}
                    type="number"
                    label="Somme(MGA)"
                    value={valuesAdvance.somme}
                    variant="outlined"
                    onChange={(e) => {
                        setValuesAdvance({ ...valuesAdvance, somme: e.target.value })
                    }}
                />
                <span style={{ position: 'absolute', fontSize: '0.75rem', color: '#d32f2f', bottom: '-15px' }}>{checkSommeValide()}</span>
            </FormControl>
            <FormControl style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '30px'}} >
                <label style={{ fontWeight: 'bold', fontSize: '14px', color: 'black' }}>Type d&apos;avance :</label>
                <select
                    style={{
                        width: '100%',
                        padding: '8px',
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderRight: 'none',
                        borderBottom: '1px solid #ccc'
                    }}
                    value={valuesAdvance.advanceType}
                    onChange={(e) => {
                        setValuesAdvance({ ...valuesAdvance, advanceType: e.target.value })
                    }}
                >
                    <option placeholder='Quinzaine'>Quinzaine</option>
                    <option value="Special">Special</option>
                </select>
            </FormControl>
            {valuesAdvance.advanceType !== 'Quinzaine' && (
                <>
                    <FormControl style={{ width: '100%', marginBottom: '30px' }}>
                        <Controls.DatePicker
                            name="advanceStartDate"
                            label="Date de début"
                            value={valuesAdvance.advanceStartDate}
                            onChange={handleInputChange}
                        />
                        <span style={{ position: 'absolute', fontSize: '0.75rem', color: '#d32f2f', bottom: '-15px' }}>{checkAdvanceStartDateEmpty()}</span>
                    </FormControl>
                    <FormControl style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '30px'}} >
                        <label style={{ fontWeight: 'bold', fontSize: '14px', color: 'black' }}>Mensualité :</label>
                        <select
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderTop: 'none',
                                borderLeft: 'none',
                                borderRight: 'none',
                                borderBottom: '1px solid #ccc'
                            }}
                            value={valuesAdvance.numberMonthly}
                            onChange={(e) => {
                                setValuesAdvance({ ...valuesAdvance, numberMonthly: e.target.value })
                            }}
                        >
                            <option placeholder='1'>1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                        </select>
                    </FormControl>
                </>
            )}
        </form>
    )
}

function Advance() {
    const { haveAccessTo } = useAuth();
    const classes = useStyles();
    const initialeValueAdvance = {
        employeeId: null,
        advanceInsertDate: new Date(),
        advanceStartDate: new Date(),
        somme: 0,
        numberMonthly: 1,
        advanceType: 'Quinzaine',
        // status: 0, backend
        // advanceKey: '', backend
    }
    const [rows, setRows] = useState([]);
    const [rowsLine, setRowsLine] = useState([]);
    const [openAddEdit, setOpenAddEdit] = useState(false);
    const [valuesAdvance, setValuesAdvance] = useState(initialeValueAdvance);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [options, setOptions] = useState([]);
    const [openValide, setOpenValide] = useState(false);
    const [idAdvance, setIdAdvance] = useState(null);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [deletedId, setDeletedId] = useState(null);
    const [editId, setEditId] = useState(null);
    const [fullName, setFullName] = useState({
        lastName: ''
    });

    useEffect(() => {
        async function getEmpoyee() {
            const response = await AdvancesService.getAllEmployee();
            if(response?.status?.code === 200) {
                setOptions(response.data);
            }
        }
        getEmpoyee();
    }, []);

    const getListAdvance = async () => {
        const response = await AdvancesService.getAllAdvances()
        const newRows = []
        if (response?.status?.code === 200) {
            response?.data?.forEach(el => {
                newRows.push({
                  id: el.id,
                  advanceKey: el.advanceKey,
                  matricule: el.matricule,
                  name: el.lastname + " " + el.firstname,
                  somme: parseFloat(el.somme).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " MGA",
                  advanceType: el.advanceType + (el.advanceType === 'Special' ? ` (${el.numberMonthly} mois)` : ''),
                  status: el.status,
                  advanceInsertDate: moment(el.advanceInsertDate).format('DD MMMM YYYY'),
                  advanceStartDate: el.advanceStartDate ? moment(el.advanceStartDate).format('MMMM YYYY') : "",
                });
            });
        }
        setRows(newRows);
    }

    const getListAdvanceLine = async () => {
        const response = await AdvancesService.getAllAdvancesLine();
        const newRows = []
        if (response?.status?.code === 200) {
            response?.data?.forEach(el => {
                newRows.push({
                  id: el.id,
                  advanceKey: el.advanceKey,
                  matricule: el.matricule,
                  name: el.lastname + " " + el.firstname,
                  somme: parseFloat(el.somme).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " MGA",
                  advanceType: el.advanceType,
                  status: el.status,
                  paymentDate: el.paymentDate ? moment(el.paymentDate).format('MMMM YYYY') : "",
                });
            });
        }
        setRowsLine(newRows);
    }

    useEffect(() => {
        getListAdvance();
        getListAdvanceLine();
    }, [])

    const closeModal = () => {
        setFullName('');
        setEditId(null);
        setOpenAddEdit(false);
        setValuesAdvance(initialeValueAdvance);
        setIsSubmitted(false);
    }

    const handleValide = (params) => () => {
        if(params.status === 1) {
            setIdAdvance(params.id);
            setOpenValide(true);
        }
    }

    const handleEdit = (data) => () => {
        setFullName({ ...fullName, lastName: data.row.name });
        setEditId(data.id);
        setOpenAddEdit(true);
    }

    const columns = [
        {
            field: 'advanceKey',
            headerName: 'Code',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'matricule',
            headerName: 'Matricule',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'name',
            headerName: 'Nom/Prénoms',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'somme',
            headerName: 'Montant',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'advanceType',
            headerName: 'Type',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'status',
            type: 'actions',
            headerName: 'Status',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            getActions: (params) => {
                const status = {
                    1: { title: 'A valider', color: 'warning', icon: <ErrorOutlineOutlinedIcon /> },
                    2: { title: 'A payer', color: 'error', icon: <DoNotDisturbAltOutlinedIcon /> },
                    3: { title: "En cours", color: 'info', icon: <AccessTimeOutlinedIcon /> },
                    4: { title: 'Payer', color: 'success', icon: <CheckCircleOutlineOutlinedIcon /> }
                }

                return [
                    <Chip
                        onClick={handleValide(params.row)}
                        label={status[params.row.status]?.title}
                        color={status[params.row.status]?.color}
                        icon={status[params.row.status]?.icon}
                        size="small"
                        variant="outlined"
                    />
                ]
            }
        },
        {
            field: 'advanceInsertDate',
            headerName: "Date de la demande",
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'advanceStartDate',
            headerName: "Début du remboursement",
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'action',
            type: 'actions',
            headerName: 'Action',
            align: 'center',
            headerAlign: 'center',
            width: 200,
            getActions: (params) => {
                const handleDelete = async (id) => {
                    setOpenDeleteConfirm(true);
                    setDeletedId(id);
                }

                return [
                    <>
                        <Tooltips TransitionComponent={Zoom} title="Supprimer">
                            <Button
                                // className="event-delete-btn contentBtnStyle forAllBtn"
                                // disabled={!haveAccessTo(CURRENT_PAGE, 'delete')}
                                onClick={() => { handleDelete(params.id) }}
                            >
                                <MdDeleteOutline className='actionIconStyle' />
                            </Button>
                        </Tooltips>
                        <Tooltips TransitionComponent={Zoom} title="Modifier">
                            <Button
                                // className="event-edit-btn contentBtnStyle forAllBtn"
                                // disabled={!haveAccessTo(CURRENT_PAGE, 'edit')}
                                onClick={handleEdit(params)}
                            >
                                <FaRegEdit className='actionIconStyle'/>
                            </Button>
                        </Tooltips>
                    </>
                ]
            }
        },
    ]

    const columnsLine = [
        {
            field: 'status',
            type: 'actions',
            headerName: 'Status',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            getActions: (params) => {
                const status = {
                    1: { title: 'A payer', color: 'error', icon: <DoNotDisturbAltOutlinedIcon /> },
                    2: { title: 'Payer', color: 'success', icon: <CheckCircleOutlineOutlinedIcon /> }
                }

                return [
                    <Chip
                        label={status[params.row.status]?.title}
                        color={status[params.row.status]?.color}
                        icon={status[params.row.status]?.icon}
                        size="small"
                        variant="outlined"
                    />
                ]
            }
        },
        {
            field: 'advanceKey',
            headerName: 'Code',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'matricule',
            headerName: 'Matricule',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'name',
            headerName: 'Nom/Prénoms',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'advanceType',
            headerName: 'Type',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'somme',
            headerName: 'Montant',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'paymentDate',
            headerName: "Fiche de paie",
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
    ]

    const closeModalValide = () => {
        setOpenValide(false);
        setIdAdvance(null);
    }

    const onSaveValide = async () => {
        const response = await AdvancesService.validateAdvance(idAdvance);
        if(response?.status?.code === 200) {
            setRows(
                rows.map((row) => {
                  return row.id === response.data.id ? {...row, status: response.data.status} : row;
                })
              );
            closeModalValide();
            Toast.success("Validation d'une avance avec succès.");
            getListAdvanceLine();
        }
    }

    const onSaveNewAdvance = async () => {
        if (haveAccessTo(CURRENT_PAGE, 'add')) {
          setIsSubmitted(true);
          const validate =
            valuesAdvance.advanceType === 'Quinzaine' ?
            valuesAdvance.employeeId &&
            valuesAdvance.advanceInsertDate &&
            valuesAdvance.somme &&
            valuesAdvance.advanceType &&
            valuesAdvance.somme > 0
            :
            valuesAdvance.employeeId &&
            valuesAdvance.advanceInsertDate &&
            valuesAdvance.somme &&
            valuesAdvance.advanceType &&
            valuesAdvance.numberMonthly &&
            valuesAdvance.advanceStartDate &&
            valuesAdvance.somme > 0

          if (validate) {
            if(editId) {
                const response = await AdvancesService.editAdvance(editId, valuesAdvance);
                if(response?.status?.code === 200) {
                    let dataResponse = response.data;
                    setRows(
                        rows.map((row) => {
                            const updateData = {
                                ...row,
                                somme: parseFloat(dataResponse.somme).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " MGA",
                                advanceType: dataResponse.advanceType + (dataResponse.advanceType === 'Special' ? ` (${dataResponse.numberMonthly} mois)` : ''),
                                status: dataResponse.status,
                                advanceInsertDate: moment(dataResponse.advanceInsertDate).format('DD MMMM YYYY'),
                                advanceStartDate: dataResponse.advanceStartDate ? moment(dataResponse.advanceStartDate).format('MMMM YYYY') : "",
                            }
                          return row.id === response.data.id ? updateData : row;
                        })
                      );
                    closeModal();
                    Toast.success("Avance modifier avec succès.");
                    getListAdvanceLine();
                }
            } else {
                const response = await AdvancesService.addAdvances(valuesAdvance);
                if(response?.status?.code === 200) {
                    let dataResponse = response.data;
                    const addData = {
                        id: dataResponse.id,
                        matricule: dataResponse.matricule,
                        name: dataResponse.name,
                        advanceKey: dataResponse.advanceKey,
                        somme: parseFloat(dataResponse.somme).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " MGA",
                        advanceType: dataResponse.advanceType + (dataResponse.advanceType === 'Special' ? ` (${dataResponse.numberMonthly} mois)` : ''),
                        status: dataResponse.status,
                        advanceInsertDate: moment(dataResponse.advanceInsertDate).format('DD MMMM YYYY'),
                        advanceStartDate: dataResponse.advanceStartDate ? moment(dataResponse.advanceStartDate).format('MMMM YYYY') : "",
                    }
                    setRows([...rows, addData])
                    closeModal();
                    Toast.success("Avance ajouter avec succès.");
                }
            }
          }
        }
    };

    const confirmDelete = async () => {
        const response = await AdvancesService.deleteAdvance(deletedId);
        if(response?.status?.code === 200) {
            setDeletedId(null);
            Toast.success(`L'avance ${response.data.advanceKey} a été supprimé avec succès.`);
            setRows(rows.filter((el) => el.id !== deletedId));
            setOpenDeleteConfirm(false);
            getListAdvanceLine();
        }
    }

    return (
        <>
            <div style={{ padding: 20 }}>
                <div className={classes.headContent}>
                    <div className={classes.iconTitle}>
                        <GiTakeMyMoney style={{ fontSize: '1.1rem' }} />
                    </div>
                    <div>
                        <h4>Gestion des avances sur Salaire</h4>
                        <ButtonComponent style={{marginLeft: '20px', textTransform: 'none'}} text="Nouveau" variant="contained" onClick={() => setOpenAddEdit(true)}/>
                    </div>
                </div>
                <Table columns={columns} rows={rows}/>
            </div>
            <div style={{ padding: 20 }}>
                <div className={classes.headContent}>
                    <div className={classes.iconTitle}>
                        <GiTakeMyMoney style={{ fontSize: '1.1rem' }} />
                    </div>
                    <div>
                        <h4>Liste des remboursements</h4>
                    </div>
                </div>
                <Table columns={columnsLine} rows={rowsLine}/>
            </div>
            <ModalComponent
                openModal={openAddEdit}
                close={() => closeModal()}
                title={!editId ? 'Ajout' : 'Modifier'}
                submit={() => onSaveNewAdvance()}
                actionTitle={'Enregistrer'}
                modalContentBody={<ModalContentBody fullName={fullName} setFullName={setFullName} editId={editId} options={options} setValuesAdvance={setValuesAdvance} valuesAdvance={valuesAdvance} isSubmitted={isSubmitted} />}
            />
            <ModalComponent
                openModal={openValide}
                close={() => closeModalValide()}
                title={'Validation'}
                submit={() => onSaveValide()}
                actionTitle={'Confirmer'}
                modalContentBody={'Valider cette avance'}
            />
            <ModalComponent
                openModal={openDeleteConfirm}
                close={() => {
                    setOpenDeleteConfirm(false)
                    setDeletedId(null);
                }}
                title={'Suppression'}
                submit={() => confirmDelete()}
                actionTitle={'Confirmer'}
                modalContentBody={'Est-ce que vous voulez vraiment supprimer cet avance?'}
            />
        </>
    )
}

export default Advance;