import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class UserService extends Service {
    static async login(params) {
        return await Http.post('/api/auth/login', params)
    }

    static async setSession(params) {
        const { data, status } = await Http.put('/api/auth/session', params)
        if (status?.code === 200) {
            sessionStorage.setItem('sessID', data.sessID)
            return data
        }

        return null
    }

    static async getSession() {
        const sessID = sessionStorage.getItem('sessID')
        const { data, status } = await Http.get('/api/auth/session', { sessID })
        if (status?.code === 401) {
            sessionStorage.removeItem('sessID')
            return null
        }

        return data
    }

    static async getAllRole() {
        return await Http.get('/api/users/roles/list');
    }

    static async getAllUser() {
        return await Http.get('/api/users/list');
    }

    static async addUser(payload) {
        return await Http.put(`/api/users/add`, payload);
    }

    static async editUser(id, payload) {
        return await Http.put(`/api/users/edit?id=${id}`, payload);
    }

    static async deleteUser(id) {
        return await Http.delete(`/api/users/delete?id=${id}`);
    }

    static async getSingleUser(id) {
        return await Http.get(`/api/users/single-user?id=${id}`);
    }
}

export default UserService
