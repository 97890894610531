import React, { useEffect, useState } from 'react';
import RubriqueService from './service/Rubrique.jsx';
import Table from '../../components/table/index.jsx';
import Button from '@mui/material/Button';
import ModalComponent from '../../components/modalComponent/index.jsx';
import { useAuth } from '../../applications/hooks/UseAuth.js';
import { Toast } from '../../components/notificationComponent/index.jsx';
import Controls from '../../components/Entry/Controls.jsx';
import FormControl from '@material-ui/core/FormControl';
import { Chip } from '@mui/material';
import Tooltips from '../../components/tooltips/index.jsx';
import { MdDeleteOutline } from 'react-icons/md';
import { FaRegEdit } from 'react-icons/fa';
import Zoom from '@material-ui/core/Zoom';
import { GrDocumentConfig } from "react-icons/gr";
import useStyles from './style.jsx';
import ButtonComponent from '../../components/Entry/Button.jsx';

const CURRENT_PAGE = 'rubrique';

const percentageToInt = (probability) => {
    const percentage = Math.round( ( probability * 100) );
    return percentage;
}

function ModalContentBody(props) {
    const {editId, valuesRubrique, setValuesRubrique, isSubmitted} = props;
    const handleInputChange = (e) => {
        const { name, value } = e.target
        if (name === 'taux') {
            let newValue = value.replace(/[^\d+]/g, '');
            setValuesRubrique({ ...valuesRubrique, [name]: newValue })
        } else {
            setValuesRubrique({
                ...valuesRubrique,
                [name]: value
            })
        }
    }

    const checkCompteEmpty = () => {
        if (isSubmitted && !valuesRubrique.compte) {
          return 'Champ requis.'
        }
    }
    
    const checkVariableNameEmpty = () => {
        if (isSubmitted && !valuesRubrique.variableName) {
            return 'Champ requis.'
        }
    }
      
    const checkTypeEmpty = () => {
        if (isSubmitted && !valuesRubrique.type) {
            return 'Le champ type est requis.'
        }
    }
    
    const checkActiveEmpty = () => {
    if (isSubmitted && !valuesRubrique.active) {
        return 'Le champ active est requis.'
    }
    }
    
    const checkFiscalEmpty = () => {
        if (isSubmitted && !valuesRubrique.fiscal) {
          return 'Ce champ est requis.'
        }
    }
      
    const checkValeurEmpty = () => {
        if (isSubmitted && !valuesRubrique.valeur) {
            return 'Ce champ est requis.'
        }
    }
    
    const checkDividerEmpty = () => {
        if (isSubmitted && !valuesRubrique.divider) {
            return 'Base de calcul est requis.'
        }
    }

    useEffect(() => {
        if(editId) {
            RubriqueService.getSingleRubrique(editId).then((res) => {
                if(res?.status?.code === 200) {
                    const dataEdit = {
                        valeur: res.data.valeur,
                        variableName: res.data.variableName,
                        compte: res.data.compte,
                        type: res.data.type,
                        divider: res.data.divider,
                        active: res.data.active,
                        taux: res.data.taux ? percentageToInt(res.data.taux) : null,
                        fiscal: res.data.fiscal,
                    }
                    setValuesRubrique(dataEdit)
                }
            });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <form style={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormControl style={{ width: '48%' }}>
                <FormControl style={{ width: '100%', marginBottom: '30px' }}>
                    <Controls.Input
                        name="compte"
                        label="Code"
                        value={valuesRubrique.compte}
                        onChange={handleInputChange}
                        error={checkCompteEmpty()}
                    />
                </FormControl>
                <FormControl style={{ width: '100%', marginBottom: '30px' }}>
                    <Controls.Input
                        name="variableName"
                        label="Rubrique"
                        value={valuesRubrique.variableName}
                        onChange={handleInputChange}
                        error={checkVariableNameEmpty()}
                    />
                </FormControl>
                <FormControl style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '30px'}} >
                    <label style={{ fontWeight: 'bold', fontSize: '14px', color: 'black' }}>Type</label>
                    <select
                        style={{
                            width: '100%',
                            padding: '8px',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderRight: 'none',
                            borderBottom: '1px solid #ccc'
                        }}
                        value={valuesRubrique.type}
                        onChange={(e) => {
                            setValuesRubrique({ ...valuesRubrique, type: e.target.value })
                        }}
                    >
                        <option></option>
                        <option value="gain">Gain</option>
                        <option value="retenue">Retenue</option>
                    </select>
                    <span style={{ position: 'absolute', fontSize: '0.75rem', bottom: '-20px', color: '#d32f2f' }}>{checkTypeEmpty()}</span>
                </FormControl>
                <FormControl style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '30px'}} >
                    <label style={{ fontWeight: 'bold', fontSize: '14px', color: 'black' }}>Choix fiscal</label>
                    <select
                        style={{
                            width: '100%',
                            padding: '8px',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderRight: 'none',
                            borderBottom: '1px solid #ccc'
                        }}
                        value={valuesRubrique.fiscal}
                        onChange={(e) => {
                            setValuesRubrique({ ...valuesRubrique, fiscal: e.target.value })
                        }}
                    >
                        <option></option>
                        <option value="1">imposable</option>
                        <option value="2">Non imposable</option>
                    </select>
                    <span style={{ position: 'absolute', fontSize: '0.75rem', bottom: '-20px', color: '#d32f2f' }}>{checkFiscalEmpty()}</span>
                </FormControl>
            </FormControl>
            <FormControl style={{ width: '46%' }}>
                <FormControl style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '30px'}} >
                    <label style={{ fontWeight: 'bold', fontSize: '14px', color: 'black' }}>Est active?</label>
                    <select
                        style={{
                            width: '100%',
                            padding: '8px',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderRight: 'none',
                            borderBottom: '1px solid #ccc'
                        }}
                        value={valuesRubrique.active}
                        onChange={(e) => {
                            setValuesRubrique({ ...valuesRubrique, active: e.target.value })
                        }}
                    >
                        <option></option>
                        <option value="1">Activé</option>
                        <option value="2">Désactivé</option>
                    </select>
                    <span style={{ position: 'absolute', fontSize: '0.75rem', bottom: '-20px', color: '#d32f2f' }}>{checkActiveEmpty()}</span>
                </FormControl>
                <FormControl style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '30px'}} >
                    <label style={{ fontWeight: 'bold', fontSize: '14px', color: 'black' }}>Valeur</label>
                    <select
                        style={{
                            width: '100%',
                            padding: '8px',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderRight: 'none',
                            borderBottom: '1px solid #ccc'
                        }}
                        value={valuesRubrique.valeur}
                        onChange={(e) => {
                            setValuesRubrique({ ...valuesRubrique, valeur: e.target.value })
                        }}
                    >
                        <option></option>
                        <option value="fixe">Fixe</option>
                        <option value="dynamique">Dynamique</option>
                    </select>
                    <span style={{ position: 'absolute', fontSize: '0.75rem', bottom: '-20px', color: '#d32f2f' }}>{checkValeurEmpty()}</span>
                </FormControl>
                {valuesRubrique.valeur === "dynamique" &&
                    <>
                        <FormControl style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '30px'}} >
                            <Controls.Input
                                name="taux"
                                label="Taux %"
                                value={valuesRubrique.taux}
                                onChange={handleInputChange}
                            />
                            {/* <label style={{ fontWeight: 'bold', fontSize: '14px', color: 'black' }}>Base de calcul</label>
                            <select
                                style={{
                                    width: '100%',
                                    padding: '8px',
                                    borderTop: 'none',
                                    borderLeft: 'none',
                                    borderRight: 'none',
                                    borderBottom: '1px solid #ccc'
                                }}
                                value={valuesRubrique.base}
                                onChange={(e) => {
                                    setValuesRubrique({ ...valuesRubrique, base: e.target.value })
                                }}
                            >
                                <option></option>
                                <option value='1'>Salaire de base</option>
                            </select>
                            <span style={{ position: 'absolute', fontSize: '0.75rem', bottom: '-20px', color: '#d32f2f' }}>{checkBaseEmpty()}</span> */}
                        </FormControl>
                        <FormControl style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '30px'}} >
                            <label style={{ fontWeight: 'bold', fontSize: '14px', color: 'black' }}>Base de calcul (jour)</label>
                            <select
                                style={{
                                    width: '100%',
                                    padding: '8px',
                                    borderTop: 'none',
                                    borderLeft: 'none',
                                    borderRight: 'none',
                                    borderBottom: '1px solid #ccc'
                                }}
                                value={valuesRubrique.divider}
                                onChange={(e) => {
                                    setValuesRubrique({ ...valuesRubrique, divider: e.target.value })
                                }}
                            >
                                <option></option>
                                <option value='24'>24 jours</option>
                                <option value="30">30 jours</option>
                                <option value="1">Horaire</option>
                                <option value="2">Jours ouvrables</option>
                            </select>
                            <span style={{ position: 'absolute', fontSize: '0.75rem', bottom: '-20px', color: '#d32f2f' }}>{checkDividerEmpty()}</span>
                        </FormControl>
                    </>
                }
            </FormControl>
        </form>
    )
}

function Rubrique() {
    const { haveAccessTo } = useAuth();
    const classes = useStyles();
    const initialeValueRubrique = {
        valeur: 'fixe',
        variableName: '',
        compte: '',
        type: 'gain',
        divider: 30,
        active: 1,
        taux: null,
        fiscal: 1,
    }
    const [rows, setRows] = useState([]);
    const [openAddEdit, setOpenAddEdit] = useState(false);
    const [valuesRubrique, setValuesRubrique] = useState(initialeValueRubrique);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [deletedId, setDeletedId] = useState(null);
    const [editId, setEditId] = useState(null);

    const getListRubrique = async () => {
        const response = await RubriqueService.getAllRubrique()
        const newRows = []
        if (response?.status?.code === 200) {
            response.data.forEach(el => {
                newRows.push({
                  id: el.id,
                  compte: el.compte,
                  variableName: el.variableName,
                  valeur: el.valeur,
                  type: el.type,
                  divider: el.divider,
                  fiscal: el.fiscal === 1 ? true : false,
                  active: el.active === 1 ? true : false,
                  taux: el.taux ? percentageToInt(el.taux) + "%" : "",
                });
            });
        }
        setRows(newRows);
    }

    useEffect(() => {
        getListRubrique();
        // eslint-disable-next-line
    }, [])

    const closeModal = () => {
        setEditId(null);
        setOpenAddEdit(false);
        setValuesRubrique(initialeValueRubrique);
        setIsSubmitted(false);
    }

    const handleEdit = (data) => () => {
        setEditId(data.id);
        setOpenAddEdit(true);
    }

    const columns = [
        {
            field: 'compte',
            headerName: 'Code',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'variableName',
            headerName: 'Variable',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'valeur',
            headerName: 'Valeur',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (params) => {
              if (!params?.value) {
                return '-';
              }
              return params?.value?.replace(/^./, params?.value[0]?.toUpperCase());
            },
        },
        {
            field: 'type',
            headerName: 'Type',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (params) => {
              if (!params?.value) {
                return '-';
              }
              const label = params?.value?.replace(
                /^./,
                params?.value[0]?.toUpperCase()
              );
              return params?.value === 'gain' ? (
                <Chip label={label} color="success" variant="outlined" />
              ) : (
                <Chip label={label} color="error" variant="outlined" />
              );
            },
        },
        {
            field: 'divider',
            headerName: 'Base de calcul',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            valueFormatter: (params) => {
                return params ? (params !== 2 ? `${params} ${params !== 1 ? 'jours' : 'heure'}` : 'Jours ouvrables') : ''
            }
        },
        {
            field: 'taux',
            headerName: 'Taux',
            align: 'center',
            headerAlign: 'center',
            width: 80,
        },
        {
            field: 'fiscal',
            headerName: 'Imposable?',
            type: 'boolean',
            headerAlign: 'center',
            width: 90,
        },
        {
            field: 'active',
            headerName: 'Est active?',
            type: 'boolean',
            headerAlign: 'center',
            width: 90,
        },
        {
            field: 'action',
            type: 'actions',
            headerName: 'Action',
            align: 'center',
            headerAlign: 'center',
            width: 150,
            getActions: (params) => {
                const handleDelete = async (id) => {
                    setOpenDeleteConfirm(true);
                    setDeletedId(id);
                }

                return [
                    <>
                        <Tooltips TransitionComponent={Zoom} title="Supprimer">
                            <Button
                                // className="event-delete-btn contentBtnStyle forAllBtn"
                                // disabled={!haveAccessTo(CURRENT_PAGE, 'delete')}
                                onClick={() => { handleDelete(params.id) }}
                            >
                                <MdDeleteOutline className='actionIconStyle' />
                            </Button>
                        </Tooltips>
                        <Tooltips TransitionComponent={Zoom} title="Modifier">
                            <Button
                                // className="event-edit-btn contentBtnStyle forAllBtn"
                                // disabled={!haveAccessTo(CURRENT_PAGE, 'edit')}
                                onClick={handleEdit(params)}
                            >
                                <FaRegEdit className='actionIconStyle'/>
                            </Button>
                        </Tooltips>
                    </>
                ]
            }
        },
    ]

    const onSaveNewRubrique = async () => {
        if (haveAccessTo(CURRENT_PAGE, 'add')) {
          setIsSubmitted(true);
            const validate =
                valuesRubrique.valeur === 'dynamique' ?
                valuesRubrique.compte &&
                valuesRubrique.variableName &&
                valuesRubrique.type &&
                valuesRubrique.active &&
                valuesRubrique.fiscal &&
                valuesRubrique.valeur &&
                valuesRubrique.divider
                :
                valuesRubrique.compte &&
                valuesRubrique.variableName &&
                valuesRubrique.type &&
                valuesRubrique.active &&
                valuesRubrique.fiscal &&
                valuesRubrique.valeur

          if (validate) {
            if(editId) {
                const response = await RubriqueService.editRubrique(editId, valuesRubrique);
                if(response?.status?.code === 200) {
                    let dataResponse = response.data;
                    setRows(
                        rows.map((row) => {
                            const updateData = {
                                ...row,
                                compte: dataResponse.compte,
                                variableName: dataResponse.variableName,
                                valeur: dataResponse.valeur,
                                type: dataResponse.type,
                                divider: dataResponse.divider,
                                fiscal: dataResponse.fiscal === 1 ? true : false,
                                active: dataResponse.active === 1 ? true : false,
                                taux: dataResponse.taux ? percentageToInt(dataResponse.taux) + "%" : "",
                            }
                          return row.id === response.data.id ? updateData : row;
                        })
                      );
                    closeModal();
                    Toast.success("Rubrique modifier avec succès.");
                }
            } else {
                const response = await RubriqueService.addRubrique(valuesRubrique);
                if(response?.status?.code === 200) {
                    let dataResponse = response.data;
                    const addData = {
                        id: dataResponse.id,
                        compte: dataResponse.compte,
                        variableName: dataResponse.variableName,
                        valeur: dataResponse.valeur,
                        type: dataResponse.type,
                        divider: dataResponse.divider,
                        fiscal: dataResponse.fiscal === 1 ? true : false,
                        active: dataResponse.active === 1 ? true : false,
                        taux: dataResponse.taux ? percentageToInt(dataResponse.taux) + "%" : "",
                    }
                    setRows([...rows, addData])
                    closeModal();
                    Toast.success("Rubrique ajouter avec succès.");
                } else {
                    Toast.error(response.status.message);
                }
            }
          }
        }
    };

    const confirmDelete = async () => {
        const response = await RubriqueService.deleteRubrique(deletedId);
        if(response?.status?.code === 200) {
            setDeletedId(null);
            setRows(rows.filter((el) => el.id !== deletedId));
            setOpenDeleteConfirm(false);
            Toast.success("Le rubrique a été supprimé avec succès.")
        }
    }

    return (
        <>
            <div style={{ padding: 20 }}>
                <div className={classes.headContent}>
                    <div className={classes.iconTitle}>
                        <GrDocumentConfig style={{ fontSize: '1.1rem' }} />
                    </div>
                    <div>
                        <h4>Gestion des Rubriques</h4>
                        <ButtonComponent style={{marginLeft: '20px', textTransform: 'none'}} text="Nouveau" variant="contained" onClick={() => setOpenAddEdit(true)}/>
                    </div>
                </div>
                <Table columns={columns} rows={rows}/>
            </div>
            <ModalComponent
                openModal={openAddEdit}
                close={() => closeModal()}
                title={!editId ? 'Ajout' : 'Modifier'}
                submit={() => onSaveNewRubrique()}
                actionTitle={'Enregistrer'}
                modalContentBody={<ModalContentBody editId={editId} setValuesRubrique={setValuesRubrique} valuesRubrique={valuesRubrique} isSubmitted={isSubmitted} />}
            />
            <ModalComponent
                openModal={openDeleteConfirm}
                close={() => {
                    setOpenDeleteConfirm(false)
                    setDeletedId(null);
                }}
                title={'Suppression'}
                submit={() => confirmDelete()}
                actionTitle={'Confirmer'}
                modalContentBody={'Est-ce que vous voulez vraiment supprimer cet Rubrique?'}
            />
        </>
    )
}

export default Rubrique;